import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getEventDetail = createAsyncThunk("event", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let eventdetail =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-event-details`,
            data: {
                "event_id": obj?.eventid
            }
        })
            .then(res => res.data.data)
    return eventdetail
})

const listSongs = createSlice(({
    name: "event",
    initialState: {
        eventDetail: [],
        loading: false
    },
    extraReducers: {
        [getEventDetail.pending]: (state, action) => {
            state.loading = true;
        },
        [getEventDetail.fulfilled]: (state, action) => {
            state.eventDetail = action.payload
        },
        [getEventDetail.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listSongs.reducer;