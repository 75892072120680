import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { getAbsentData } from '../../app_redux/slice/absentData'


const InfoModal = ({ show, handleShow, info }) => {


    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" centered >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-4 text-center">
                                <h6>{info}</h6>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default InfoModal