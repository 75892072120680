import * as Yup from "yup";

export const signUpSchema = Yup.object().shape({
    firstName: Yup.string().min(2).max(15).required("*Required"),
    lastName: Yup.string().min(2).max(15).required("*Required"),
    email: Yup.string().email("Invalid Email").required("*Required"),
    // phone: Yup.number().min(10).required("please enter phone number"),
    // dob: Yup.date().required("*Required"),
    // country: Yup.string().min(3).max(15).required("please fill"),
    // city: Yup.string().min(3).max(15).required("please fill"),
    password: Yup.string().matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ).min(8).max(15).required("*Required"),
    confirmPassword: Yup.string().required("*Required").oneOf([Yup.ref("password"), null], "Password must match"),
    // street: Yup.string().min(1).max(45).required("please enter"),
    // town: Yup.string().min(1).max(45).required("Please enter"),
    // housenumber: Yup.string().min(1).max(45).required("Please enter"),
    // zipcode: Yup.string().min(1).max(45).required("Please required"),
    // country:Yup.string().required("Please enter").matches(/Germany/,"For legal reasons, we are currently only able to offer German users")
    tandc: Yup.boolean().oneOf([true], 'You need to accept the terms and conditions'),
    mobile:Yup.string().required("*Required")

});