import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getAbsentData = createAsyncThunk("absent", async () => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let absent =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/musician-marked-absents`,
            data:{
                "page_no":1
            }
        })
            .then(res => res.data.data)
    return absent
})

const absentData = createSlice(({
    name: "all absent",
    initialState: {
        absentDataList: [],
        loading: false
    },
    extraReducers: {
        [getAbsentData.pending]: (state, action) => {
            state.loading = true;
        },
        [getAbsentData.fulfilled]: (state, action) => {
            state.absentDataList = action.payload
        },
        [getAbsentData.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default absentData.reducer;