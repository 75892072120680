import React, { useState } from 'react'
import ChatListItems from './chatListItems'
import AllChats from './allChats'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getBandChatsBandleader } from '../../app_redux/slice/bandsChat'
import { useEffect } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'




const ChatList = () => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const navigate = useNavigate()
    const [eventId, setEventId] = useState("")
    const [band, setBand] = useState(false)
    const [event, setEvent] = useState(false)
    const [task, setTask] = useState(false)
    const [taskId, setTaskid] = useState("")
    const [message3, setMessage3] = useState()
    const { allBandChats } = useSelector((state) => state.bandchat)
    const token = cookee.get('bandleader_token')

    useEffect(() => {
        dispatch(getBandChatsBandleader())
    }, [])
    const handleBand = () => {

        navigate("/chat-list")
    }
    const handleEvent = () => {

        navigate("/chatlistEvent")
    }
    const handleTask = () => {

        navigate("/chatlistTask")
    }
   
    const handleBandSendMessage = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-send-message`,
            data:
            {
                "user_type": 2,
                "message": message3,
                "message_type": "T",
                "media_url": ""
            }
        })
            .then(res => {
                //  console.log("res", res.data)
                if (res.data.message === "Message Sent Successfully!.") {
                    dispatch(getBandChatsBandleader())
                    setMessage3("")
                }
            })
    }

    return (

        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <div className="bg-white">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 ledtBorder pe-0">
                            <h6 className="d-flex align-items-center justify-content-between px-2 pt-2 mb-0">
                                <div className="">
                                    <ul className="nav nav-pills group-nav bandUserTab chatBtnTab mt-0" id="pills-tab" role="tablist">
                                        <li className="nav-item me-3">
                                            <button className="nav-link notranslate active" onClick={handleBand}>Band <img src="assets/images/Infowhite.png" alt="" className="mx-2" title="All band chat comes here." /></button>
                                        </li>
                                        <li className="nav-item me-3">
                                            <button className="nav-link notranslate" onClick={handleEvent}>Event <img src="assets/images/Infowhite.png" alt="" className="mx-2" title="All events chat comes here." /></button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="nav-link notranslate" onClick={handleTask}>Task <img src="assets/images/Infowhite.png" alt="" className="mx-2" title="All events chat comes here." /></button>
                                        </li>
                                    </ul>
                                </div>
                            </h6>
                            <hr />
                            <ChatListItems setEventId={setEventId} band={setBand} event={event} task={task} setTaskid={setTaskid} />
                        </div>
                        <AllChats allBandChats={allBandChats}  />
                     
                    </div>
                </div>
            </div>

        </article>

    )
}

export default ChatList