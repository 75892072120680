import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import OTPInput from "otp-input-react";
import Cookies from 'universal-cookie'


const initialValues = {
    otp: ""
}
const OtpVerification = () => {
    const cookie = new Cookies();
    const navigate = useNavigate();
    const [OTP, setOTP] = useState("");
    const [counter, setCounter] = useState(60)
    const phone = JSON.parse(localStorage.getItem("phone"))
    // console.log("Phone", phone)
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer)
    }, [counter])

    const handleSignin = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: 'http://3.131.5.153:4050/v1/sign-in-with-phone-number',
            data:
            {
                "country_code": "+91",
                "phone_number": phone,
                "device_token": "Web",
                "device_type": "W"
            }
        })
            .then(res => {
                // console.log("RESPONSE", res.data)
                if (res.data.message === "User sign-in succesfully!") {
                    cookie.set('token', res.data.data.auth_token)
                    cookie.set('email', res.data.data.email)
                    cookie.set('firstname', res.data.data.first_name)
                    cookie.set('lastname', res.data.data.last_name)
                    cookie.set('profilepic', res.data.data.profile_pic)
                    cookie.set('phonenumber', res.data.data.phone_number)
                    cookie.set('userboth', res.data.data.is_user_both)
                    toast.success("User sign-in succesfully!", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/appointments')
                }
            })
            .catch((error) => {
                if (error.response.data.message === "Account doesn't Exists!") {
                    toast.error("Account doesn't Exists!", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    const handleOtpVerify = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: 'http://3.131.5.153:4050/v1/verify-musician-otp-with-mobile-number',
            data:
            {

                "phone_number": phone,
                "verify_otp": OTP
            }
        })
            .then(res => {
                handleSignin()
                // console.log("RESPONSE", res.data)
                if (res.data.message === "OTP Verified Successfully!") {
                    // handleSignin()
                    toast.success('OTP Verified Successfully!', {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                }
            })
            .catch((error) => {
                if (error.response.data.message === 'OTP Incorrect') {
                    toast.error("Enter valid otp", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }

    const handleSendOtp = (e) => {
        e.preventDefault()
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: 'http://3.131.5.153:4050/v1/send-musician-otp-with-mobile-number',
            data:
            {

                "phone_number": phone,
            }
        })
            .then(res => {
                if (res.data.message === "OTP Sent on Registered Phone Number") {
                    // navigate('/otp-verification')
                    toast.success("OTP sent successfully.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
            .catch((error) => {
                if (error.response.data.message === 'Invalid Phone Number! Retry.') {
                    toast.error("Invalid Phone Number.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    return (
        <section className="bg-image">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                        <img src="assets/images/musicianBanner.png" className="img-fluid w-100" alt="" />
                        <img src="assets/images/logo.png" className="musiLogo" alt="" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                        <div className="d-table h-100">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <h3 className="theme-color">OTP Verification</h3>
                                        <p>Please enter 6 digits code</p>
                                    </div>
                                    <div className="form-group col-sm-12">
                                        <div className="passcode-wrapper">
                                            {/* <input id="codeBox1" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox2" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox3" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox4" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox5" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox6" type="text" maxLength="1" placeholder="" /> */}
                                            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} placeholder="000000" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-3">
                                        <span className="fw-bold">00:{counter}</span>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        <button className="btn btn-save w-75" onClick={handleOtpVerify}>VERIFY</button>
                                        {/* <Link to="/signup" className="btn btn-save w-75"><b>VERIFY</b></Link> */}

                                    </div>
                                    {counter === 0 ?
                                        <div className="form-group mb-4 text-center">
                                            <div><a href="#" className="forgot-pass p-0" onClick={handleSendOtp}>Resend Code</a></div>
                                        </div>
                                        : ""}
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        Don't have an account? <Link to="/signup" className="text-dark"><b>Sign Up!</b></Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </section>
    )
}

export default OtpVerification