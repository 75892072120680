import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import CalendarView from "../Calendar/calendar";
import AbsentDetailModal from "./absentDetails";
import { getEventnotification } from "../../app_redux/slice/eventnotification";
import { useDispatch, useSelector } from "react-redux";
import { getAllnotification } from "../../app_redux/slice/allnotificationcount";
import AppointmentGalleryView from "./appointmentGalleryView";
import { useTranslation } from "react-i18next";

const Appointments = ({ switchtoken }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [confirmEvents, setConfirmEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showAbsentDetailModal, setShowAbsentDetailModal] = useState(false);
  const [absentFrom, setAbsentFrom] = useState("");
  const [absentTo, setAbsentTo] = useState("");
  const [showGallery, setShowGallery] = useState(true);
  const [showList, setShowList] = useState(false);
  const token = cookee.get("tokenmusician");
  const firstname = cookee.get("firstnamemusician");
  const lng = cookee.get("googtrans");
  const { tab1, tab2, tab3, tab4, tab5, tab6 } = t("appointments");

  useEffect(() => {
    if (switchtoken) {
    }
    getAllEvents();
    getConfirmEvents();
    getPastEvents();
    updatetasknotification();
  }, []);
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const handleGalleryView = () => {
    setShowGallery(true);
    setShowList(false);
  };
  const handleListView = () => {
    setShowList(true);
    setShowGallery(false);
  };
  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  const handleShowAbsentDetail = () => {
    setShowAbsentDetailModal(!showAbsentDetailModal);
  };
  const updatetasknotification = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-musician-notification-status`,
      data: {
        notification_id: "",
        user_type: 1,
        is_read: 1,
        notification_type: 1,
      },
    }).then((res) => {
      if (res.data.message === "Notification Status Updated Successfully.") {
        dispatch(getEventnotification());
        dispatch(getAllnotification());
      }
    });
  };

  const showAbsentDetail = (e) => {
    e.preventDefault();
    handleShowAbsentDetail();
  };
  // const getMusicianInfo = () => {
  //     axios({
  //         method: 'get',
  //         headers: {
  //             "Accept": "/",
  //             "Content-Type": "application/json",
  //             "auth_token": switchtoken ? switchtoken : token
  //         },
  //         url: `${process.env.REACT_APP_URL}/get-musician-profile`,
  //     })
  //         .then(res => {
  //             // console.log("RESPONSE", res.data.data)
  //             cookee.set('emailmusician', res.data.data.email)
  //             cookee.set('profilepicmusician', res.data.data.profile_pic)
  //             cookee.set('firstnamemusician', res.data.data.first_name)
  //             cookee.set('lastnamemusician', res.data.data.last_name)
  //             cookee.set('phonenumbermusician', res.data.data.phone_number)

  //         })
  // }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var setting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const getAllEvents = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: switchtoken ? switchtoken : token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-invited-events`,
      data: {
        page_no: 1,
        band_id: "",
      },
    }).then((res) => {
      setEvents(res.data.data);
    });
  };

  const getConfirmEvents = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: switchtoken ? switchtoken : token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-confirmed-events`,
      data: {
        page_no: 1,
        band_id: "",
      },
    }).then((res) => {
      setConfirmEvents(res.data.data);
    });
  };
  const getPastEvents = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: switchtoken ? switchtoken : token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-past-events`,
      data: {
        page_no: 1,
        band_id: "",
      },
    }).then((res) => {
      setPastEvents(res.data.data);
    });
  };
  const handleEventClick = (id) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-event-details`,
      data: {
        event_id: id,
      },
    }).then((res) => {
      //console.log("RESPONSE", res.data)
      if (res.data.message === "Event Details!.") {
        localStorage.setItem("eventdata", JSON.stringify(res.data.data));
        navigate("/appointment-detail");
      }
    });
  };

  const handleEvent = (id) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-event-details`,
      data: {
        event_id: id,
      },
    }).then((res) => {
      //console.log("RESPONSE", res.data)
      if (res.data.message === "Event Details!.") {
        localStorage.setItem("eventdatadetail", JSON.stringify(res.data.data));
        localStorage.setItem(
          "eventdatadetail2",
          JSON.stringify(res.data.data2)
        );

        navigate("/event-detail");
      }
    });
  };

  const handleChat = (id) => {
    navigate("/chat-event", { state: { eventid: id } });
  };
  return (
    <>
      <article className="col-lg-9 mt-4">
        <div className="article-body store-bd-gy mt-5">
          <div className="row align-items-center">
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-4">
              <h5 className="mb-0">{tab1}</h5>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
              {/* <ul className="nav nav-pills group-nav mt-0" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <Link to="#" className="nav-link" href="appointments.html">all</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link" href="appointments-band.html">band</Link>
                                </li>

                            </ul> */}
            </div>
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-4">
              <div className="d-flex align-items-center lg-justify-content-end">
                <button
                  className="btn notiIcon filterIcon"
                  onClick={handleCalendar}
                >
                  {!showCalendar ? (
                    <img src="assets/images/calendarGrey.png" alt="" />
                  ) : (
                    <img src="assets/images/calendar.png" alt="" />
                  )}
                </button>
                <img
                  src="assets/images/absence.png"
                  className="mt-2"
                  alt=""
                  onClick={showAbsentDetail}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src="assets/images/InfoBlack.png"
                  alt=""
                  className="mx-1 mb-5 mt-2"
                  title={
                    lng === "/auto/de" || "/en/de"
                      ? "Hier können Sie Ihre Abwesenheiten eintragen, welche and alle Bands übermittelt werden, in welchen Sie Mitglied sind."
                      : "Here you can enter your absences, which will be sent to all bands in which you are a member."
                  }
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="listGridList d-flex">
                <span
                  className={showList ? "active" : ""}
                  onClick={handleListView}
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </span>
                <span
                  className={showGallery ? "active" : ""}
                  onClick={handleGalleryView}
                >
                  <i className="fa fa-th-large" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            {showCalendar ? <CalendarView /> : ""}
            {!showCalendar && showList ? (
              <div>
                <div className="col-sm-12">
                  <div className="my-3">
                    <h3>
                      {tab2}{" "}
                      <img
                        src="assets/images/Infowhite.png"
                        alt=""
                        className="mx-1 mb-4"
                        title={
                          lng === "/auto/de" || "/en/de"
                            ? "Hier finden Sie alle Einladungen zu Events von Ihren Bands, welche noch nicht bestätigt oder abgelehnt wurden. Klicken Sie auf das Event, um weitere Informationen einsehen zu können."
                            : "Here you will find all invitations to events from your bands that have not yet been confirmed or declined. Click on the event to see more information."
                        }
                      />
                    </h3>
                  </div>
                  <div className="col-sm-12">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable setlistTabla">
                          <thead className="">
                            <tr>
                              <th scope="col" className="notranslate">
                                {tab5}
                              </th>
                              <th scope="col" className="notranslate">
                                get in
                              </th>
                              <th scope="col" className="">
                                role
                              </th>

                              <th scope="col" className="notranslate">
                                name
                              </th>
                              <th scope="col" className="notranslate">
                                bandname
                              </th>
                              <th scope="col">town</th>
                              <th scope="col" className="notranslate">
                                {tab6}
                              </th>
                              <th scope="col" className="notranslate"></th>
                              <th scope="col" className="notranslate"></th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {events?.length !== 0 ? (
                              events.map((res, i) => {
                                return (
                                  <tr key={i} style={{ cursor: "pointer" }}>
                                    <td
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {" "}
                                      {formatDate(res.start_date.substr(0, 10))}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.start_time?.substr(0, 5)}
                                    </td>
                                    <td>
                                      {res.band_users_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? res.role
                                              : ""}
                                          </div>
                                        );
                                      })}
                                      {res.band_staff_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? res.role
                                              : ""}
                                          </div>
                                        );
                                      })}
                                    </td>

                                    <td
                                      className="notranslate"
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.event_name}
                                    </td>
                                    <td
                                      className="notranslate"
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.band_name}
                                    </td>
                                    <td
                                      className="notranslate"
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.town}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.band_users_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? `${res.amount} Euro`
                                              : ""}
                                          </div>
                                        );
                                      })}
                                      {res.band_staff_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? `${res.amount} Euro`
                                              : ""}
                                          </div>
                                        );
                                      })}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {" "}
                                      {res.is_confirmed === 1 ? (
                                        <img
                                          src="assets/images/greendot.png"
                                          className=""
                                          alt=""
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      <img
                                        src="assets/images/chatGrey.png"
                                        className=""
                                        alt=""
                                        onClick={() => handleChat(res.event_id)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <p className="mt-3">{tab3}</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mb-4">
                  <div className="my-3">
                    <h3>
                      Events{" "}
                      <img
                        src="assets/images/Infowhite.png"
                        alt=""
                        className="mx-1 mb-4"
                        title={
                          lng === "/auto/de" || "/en/de"
                            ? "Hier finden Sie alle von Ihnen bestätigten Events. Klicken Sie auf das Event, um weitere Informationen einsehen zu können. Alle von der Band ergänzten Informationen, werden Ihnen in Real-Time angezeigt."
                            : "Here you will find all the events you have confirmed. Click on the event to see more information. All information added by the band is shown to you in real time."
                        }
                      />
                    </h3>
                  </div>

                  <div className="col-sm-12">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable setlistTabla">
                          <thead className="">
                            <tr>
                              <th scope="col" className="notranslate">
                                {tab5}
                              </th>
                              <th scope="col" className="notranslate">
                                get in
                              </th>
                              <th scope="col">role</th>
                              <th scope="col" className="notranslate">
                                name
                              </th>
                              <th scope="col" className="notranslate">
                                bandname
                              </th>
                              <th scope="col">town</th>
                              <th scope="col" className="notranslate">
                                {tab6}
                              </th>
                              <th scope="col" className="notranslate"></th>
                              <th scope="col" className="notranslate"></th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {confirmEvents?.length !== 0 ? (
                              confirmEvents.map((res, i) => {
                                return (
                                  <tr key={i} style={{ cursor: "pointer" }}>
                                    <td
                                      onClick={() => handleEvent(res.event_id)}
                                    >
                                      {" "}
                                      {formatDate(res.start_date.substr(0, 10))}
                                    </td>
                                    <td
                                      onClick={() => handleEvent(res.event_id)}
                                    >
                                      {res.start_time?.substr(0, 5)}
                                    </td>
                                    <td>
                                      {res.band_users_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? res.role
                                              : ""}
                                          </div>
                                        );
                                      })}
                                      {res.band_staff_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? res.role
                                              : ""}
                                          </div>
                                        );
                                      })}
                                    </td>

                                    <td
                                      className="notranslate"
                                      onClick={() => handleEvent(res.event_id)}
                                    >
                                      {res.event_name}
                                    </td>
                                    <td
                                      className="notranslate"
                                      onClick={() => handleEvent(res.event_id)}
                                    >
                                      {res.band_name}
                                    </td>
                                    <td
                                      className="notranslate"
                                      onClick={() => handleEvent(res.event_id)}
                                    >
                                      {res.town}
                                    </td>
                                    <td
                                      onClick={() => handleEvent(res.event_id)}
                                    >
                                      {res.band_users_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? `${res.amount} Euro`
                                              : ""}
                                          </div>
                                        );
                                      })}
                                      {res.band_staff_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? `${res.amount} Euro`
                                              : ""}
                                          </div>
                                        );
                                      })}
                                    </td>
                                    <td
                                      onClick={() => handleEvent(res.event_id)}
                                    >
                                      {" "}
                                      {res.is_confirmed === 1 ? (
                                        <img
                                          src="assets/images/greendot.png"
                                          className=""
                                          alt=""
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      <img
                                        src="assets/images/chatGrey.png"
                                        className=""
                                        alt=""
                                        onClick={() => handleChat(res.event_id)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <p className="mt-3">{tab3}</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mb-4">
                  <div className="my-3">
                    <h3>
                      {tab4}{" "}
                      <img
                        src="assets/images/Infowhite.png"
                        alt=""
                        className="mx-1 mb-4"
                        title={
                          lng === "/auto/de" || "/en/de"
                            ? "Hier finden Sie alle vergangenen Veranstaltungen."
                            : "You can find all past events here"
                        }
                      />
                    </h3>
                  </div>

                  <div className="col-sm-12">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable setlistTabla">
                          <thead className="">
                            <tr>
                              <th scope="col" className="notranslate">
                                {tab5}
                              </th>
                              <th scope="col" className="notranslate">
                                get in
                              </th>
                              <th scope="col">role</th>
                              <th scope="col" className="notranslate">
                                name
                              </th>
                              <th scope="col" className="notranslate">
                                bandname
                              </th>
                              <th scope="col">town</th>
                              <th scope="col" className="notranslate">
                                {tab6}
                              </th>
                              <th scope="col" className="">
                                Status
                              </th>
                              <th scope="col" className="notranslate"></th>
                              <th scope="col" className="notranslate"></th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {pastEvents?.length !== 0 ? (
                              pastEvents.map((res, i) => {
                                return (
                                  <tr
                                    key={i}
                                    onClick={() => handleEvent(res.event_id)}
                                  >
                                    <td
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {" "}
                                      {formatDate(res.start_date.substr(0, 10))}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.start_time?.substr(0, 5)}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.band_users_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? res.role
                                              : ""}
                                          </div>
                                        );
                                      })}
                                      {res.band_staff_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? res.role
                                              : ""}
                                          </div>
                                        );
                                      })}
                                    </td>

                                    <td
                                      className="notranslate"
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.event_name}
                                    </td>
                                    <td
                                      className="notranslate"
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.band_name}
                                    </td>
                                    <td
                                      className="notranslate"
                                      onClick={() =>
                                        handleEventClick(res.event_id)
                                      }
                                    >
                                      {res.town}
                                    </td>
                                    <td>
                                      {res.band_users_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? `${res.amount} Euro`
                                              : ""}
                                          </div>
                                        );
                                      })}
                                      {res.band_staff_details.map((res) => {
                                        return (
                                          <div className="notranslate">
                                            {res.first_name === firstname
                                              ? `${res.amount} Euro`
                                              : ""}
                                          </div>
                                        );
                                      })}
                                    </td>
                                    <td>
                                      {res?.is_cancelled === 1 ? (
                                        <h6 style={{ color: "red" }}>
                                          Cancelled
                                        </h6>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {res.is_confirmed === 1 ? (
                                        <img
                                          src="assets/images/greendot.png"
                                          className=""
                                          alt=""
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      <img
                                        src="assets/images/chatGrey.png"
                                        className=""
                                        alt=""
                                        onClick={() => handleChat(res.event_id)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <p className=" mt-3">{tab3}</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {!showCalendar && showGallery ? <AppointmentGalleryView /> : ""}
          </div>
        </div>
      </article>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showAbsentDetailModal ? (
        <AbsentDetailModal
          show={showAbsentDetailModal}
          handleShow={handleShowAbsentDetail}
          date={absentFrom}
          date2={absentTo}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Appointments;
