import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import OTPInput from "otp-input-react";


const initialValues = {
    otp: ""
}
const ForgotOtpVerification = () => {
    const navigate = useNavigate();
    const [OTP, setOTP] = useState("");
    const [counter, setCounter] = useState(60)
    const email = localStorage.getItem("email")
    // console.log("Phone", email)
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer)
    }, [counter])

    const handleOtpVerify = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/verify-musician-otp-with-email`,
            data:
            {

                "email": email,
                "verify_otp": OTP
            }
        })
            .then(res => {
                if (res.status === 200) {

                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/reset-password')
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    const handleSendOtp = (e) => {
        e.preventDefault()
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-mobile-number`,
            data:
            {

                "email": email,
            }
        })
            .then(res => {
                if (res.status === 200) {
                    // navigate('/otp-verification')
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    return (
        <section className="bg-image">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                        <img src="assets/images/musicianBanner.png" className="img-fluid w-100" alt="" />
                       <Link to="/"> <img src="assets/images/logo.png" className="musiLogo" alt="" /></Link>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                        <div className="d-table h-100">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <h3 className="theme-color">OTP Verification</h3>
                                        <p>Please enter 6 digits code</p>
                                    </div>
                                    <div className="form-group col-sm-12">
                                        <div className="passcode-wrapper">
                                            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} placeholder="000000" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-3">
                                        <span className="fw-bold">00:{counter}</span>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        <button className="btn btn-save w-75" onClick={handleOtpVerify}>VERIFY</button>
                                        {/* <Link to="/signup" className="btn btn-save w-75"><b>VERIFY</b></Link> */}

                                    </div>
                                    {counter === 0 ?
                                        <div className="form-group mb-4 text-center">
                                            <div><a href="#" className="forgot-pass p-0" onClick={handleSendOtp}>Resend Code</a></div>
                                        </div>
                                        : ""}
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        Don't have an account? <Link to="/signup" className="text-dark"><b>Sign Up!</b></Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </section>
    )
}

export default ForgotOtpVerification