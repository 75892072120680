import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getTaskChats = createAsyncThunk("taskchats", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let tchat =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-messages`,
            data: {
                "task_id": obj?.taskid,
                "event_id":-1,
                "band_id":-1,
            }
        })
            .then(res => res.data.data)
    return tchat
})

const listTaskChats = createSlice(({
    name: "all event chats",
    initialState: {
        allTaskChats: [],
        loading: false
    },
    extraReducers: {
        [getTaskChats.pending]: (state, action) => {
            state.loading = true;
        },
        [getTaskChats.fulfilled]: (state, action) => {
            state.allTaskChats = action.payload
        },
        [getTaskChats.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listTaskChats.reducer;