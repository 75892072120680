import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { taskSchema } from "../Schemas/taskSchema";
import { BlockPicker } from "react-color";
import CheckList from "./checklistModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const initialValues = {
  name: "",
  description: "",
  checklist: "",
  labels: "",
  lables_color: "",
  deadline: "",
};
const AddNewTask = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  let navigate = useNavigate();
  const token = cookee.get("tokenmusician");
  const [showChecklistModal, setShowChecklistModal] = useState(false);
  const [checklist, setCheckList] = useState([
    {
      check_list_item_name: "",
      checklist_item_information: "",
      status: "1",
      check_list_item_dead_line: "",
    },
  ]);
  const { tab4, tab5, tab7, tab8, tab9 } = t("tasks");

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: taskSchema,
      onSubmit: () => {
        addNewTasks();
      },
    });
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  // destructuring rgba from state
  const { r, g, b, a } = sketchPickerColor;
  const [blockPickerColor, setBlockPickerColor] = useState("");

  const handleShowCancel = () => {
    setShowChecklistModal(!showChecklistModal);
  };
  const handleChecklist = (e) => {
    e.preventDefault();
    handleShowCancel();
  };

  const addNewTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-create-task`,
      data: {
        task_name: values.name,
        description_info: values.description,
        attachment_url:
          "https://s3.us-east-2.amazonaws.com/media-appsinvo/IMG_20220723_125516_002.jpg",
        check_list: checklist[0]?.check_list_item_name === "" ? [] : checklist,
        labels: values.labels,
        label_color: blockPickerColor,
        dead_line: values.deadline,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/tasklist");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center">
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-4">
              <h5 className="mb-0">{tab4}</h5>
            </div>
            <hr />
            <div className="col-sm-12">
              <form className="row mt-5 addNAme mx-width">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Name"
                    />
                    <img
                      src="assets/images/edit.png"
                      alt=""
                      className="inputImgRight"
                    />
                    {errors.name && touched.name ? (
                      <p style={{ color: "red" }}>{errors.name}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Information"
                    />
                    <img
                      src="assets/images/edit.png"
                      alt=""
                      className="inputImgRight"
                    />
                    {errors.description && touched.description ? (
                      <p style={{ color: "red" }}>{errors.description}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <button className="btn add-new" onClick={handleChecklist}>
                    {tab8}
                  </button>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      name="labels"
                      value={values.labels}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Label"
                    />
                    <img
                      src="assets/images/edit.png"
                      alt=""
                      className="inputImgRight"
                    />
                    {errors.labels && touched.labels ? (
                      <p style={{ color: "red" }}>{errors.labels}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="upload-btn-wrapper Attachment">
                    <button className="btn">{tab9}</button>
                    <input type="file" name="myfile" />
                    <img
                      src="assets/images/attachment.png"
                      alt=""
                      className="inputImgRight"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="position-relative">
                    <input
                      type="date"
                      className="form-control"
                      name="deadline"
                      value={values.deadline}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Deathline"
                    />
                    {/* <img src="assets/images/calendarGrey.png" alt="" className="inputImgRight" /> */}
                    {errors.deadline && touched.deadline ? (
                      <p style={{ color: "red" }}>{errors.deadline}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" placeholder="Copy Task" />
                                    <img src="assets/images/copy.png" alt="" className="inputImgRight" />
                                </div>
                            </div> */}
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="position-relative">
                    {/* <SketchPicker onChange={(color) => {
                                        setSketchPickerColor(color.rgb);
                                    }}
                                        color={sketchPickerColor} /> */}
                    <BlockPicker
                      color={blockPickerColor}
                      onChange={(color) => {
                        setBlockPickerColor(color.hex);
                      }}
                    />

                    <input
                      type="text"
                      className="form-control"
                      value={blockPickerColor}
                      placeholder={tab5}
                    />
                    <img
                      src="assets/images/CreateLabel.png"
                      alt=""
                      className="inputImgRight"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="d-flex justify-content-center my-3">
                    <button className="btn add-new" onClick={handleSubmit}>
                      {tab7}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
      {showChecklistModal ? (
        <CheckList
          show={showChecklistModal}
          handleShow={handleShowCancel}
          setCheckList={setCheckList}
          checklist={checklist}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AddNewTask;
