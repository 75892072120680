import React from 'react'
import { Link } from 'react-router-dom'


const Gema = () => {
    return (
        <div>
            <div id="hero" className="">

                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="./assets/images/gemaImageBaner.png" className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 className="text-uppercase position-relative"><img src="./assets/images/bannerLogo.png" className="bannrLogo" alt="" /><span className="h5Font"><span>clever</span> <span className="fw-bold">bandassist-</span></span> </h5>
                                <p className="fw-bold deiFont">die band - und konzertmanagement software</p>
                                <Link to="/signup" className="signupbtn text-uppercase">sign up now for free</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="d-block d-md-none mt-3">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner textSlider">
                        <div className="carousel-item active">
                            <div className="carousel-caption">
                                <h5 className="text-uppercase position-relative"><img src="./assets/images/bannerLogoblack.png" className="bannrLogo" alt="" /><span className="h5Font"><span>clever</span> <span className="fw-bold">bandassist-</span></span> </h5>
                                <p className="fw-bold deiFont">die band - und konzertmanagement software</p>
                                <a className="signupbtn text-uppercase" href="#">sign up now for free</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <main id="main">
                <section className="">
                    <div className="container">
                        <div className="row mt5">
                            <div className="col-sm-12 col-md-12 col-lg-12 text-center mb-5">
                                <h2 className="text-theme"> Weil Ihre Musik viel Wert ist</h2>
                                <h6 className="fw-bold text-black">Are you a member of GEMA or would you like to become aone?</h6>
                                <p> Mit Clever BandAssist haben Sie einen kompetenten Partner an
                                    Ihrer Seite, welcher sich um Ihre Abwicklung kümmert</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt-3 pb-5">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-sm-6 col-md-4 col-lg-3 colBottom">
                                <div className="imageRound">
                                    <img src="./assets/images/task.png" className="imgrd" alt="" />
                                </div>
                                <p> Per Knopfdruck die Setlisten an die GEMA übertragen</p>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <div className="imageRound">
                                    <img src="./assets/images/to-do-list12.png" className="imgrd" alt="" />
                                </div>
                                <p>Übersichtliche Auflistung mit Status zu den Ausschüttungen</p>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3 colBottom">
                                <div className="imageRound">
                                    <img src="./assets/images/money.png" className="imgrd" alt="" />
                                </div>
                                <p>Wir optimieren Ihre Auszahlungssummen, prüfen Ihre
                                    Ausschüttungen und reklamieren die fehlenden Beträge bei der
                                    GEMA</p>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <div className="imageRound">
                                    <img src="./assets/images/user-profile.png" className="imgrd" alt="" />
                                </div>
                                <p>Persönlicher Ansprechpartner.
                                    <br />
                                    Du hast Fragen zur GEMA oder deinem Urheberecht?
                                    Wir beraten dich kostenlos und stehen dir zur Seite</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row mt5">
                            <div className="col-sm-12 col-md-12 col-lg-12 text-center mb-5">
                                <Link to="/contactus" className="signupbtn text-uppercase" href="consultation-now.html"> <p>JETZT KOSTENLOSES ERSTGESPRÄCH VEREINBAREN</p></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt5 WegetmoreBG">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-6 col-lg-7 px-5">
                                <h5 className="titleSec text-white"> Wir holen mehr raus !</h5>
                                <h5 className="titleSec text-white">Rund <span className="h5Font">40%</span> der Tantiemen werden bei der Erstverrechnung nicht ausgeschüttet! </h5>
                                <p className="mb-5 text-white">Wir optimieren deine Auszahlungssumme, prüfen deine Ausschüttung und reklamieren die
                                    fehlenden Beträge bei der GEMA - solange bis deine Tantiemen zu 100% ausbezahlt wurden!</p>
                                <Link to="/contactus" className="signupbtn blackBG text-uppercase " href="consultation-now.html"> <p>JETZT KOSTENLOSES ERSTGESPRÄCH VEREINBAREN </p> </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-5 ps-5">
                                <img src="./assets/images/GEMAlogo.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>


                <section className="mt5">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-6 col-lg-6 ps-0">
                                <img src="./assets/images/wastingtime.png" className="img-fluid" alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pe-5">
                                <h5 className="titleSec">Wasting to much time in the office?</h5>
                                <p className="mb-5 fw-bold">Damit ist jetzt Schluss! Wir erledigen Ihren Papierkram mit der GEMA! </p>

                                <p className="mb-5"> Wir optimieren Ihre Auszahlungssumme, prüfen Ihre Ausschüttung und reklamieren die
                                    fehlenden Beträge bei der GEMA - solange bis Ihre Tantiemen zu 100% ausbezahlt wurden!
                                    Und Sie haben wieder Zeit für das was Sie lieben - Musik machen!</p>
                                <Link to="/contactus" className="signupbtn text-uppercase" href="consultation-now.html"><p>JETZT KOSTENLOSES ERSTGESPRÄCH VEREINBAREN </p></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt5 absoluteTransparencyBG">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-5 ps-5">
                                <h5 className="titleSec">We work with absolute transparency!</h5>
                                <p>Sie haben jederzeit Einblick in den aktuellen Stand der Abwicklung!
                                    Wir arbeiten transparent und fair! SIE behalten die volle Kontrolle, wir erledigen die Arbeit</p>
                                <a className="signupbtn text-uppercase" href="#">
                                    <p>JETZT KOSTENLOSES ERSTGESPRÄCH VEREINBAREN </p> </a>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-7">
                                <img src="./assets/images/workAbsolute.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-6 col-lg-6 ps-0">
                                <img src="./assets/images/benefirFrom.png" className="img-fluid" alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pe-5">
                                <h5 className="titleSec">Benefit from our experience!</h5>
                                <p className="mb-5">Unsere langjährige Erfahrung in der Abwicklung mit der GEMA bringt Ihnen bares Geld</p>
                                <p className="mb-5">Wir arbeiten seit vielen Jahren mit Verlagen sowie Künstler, welche noch am Anfang Ihrer Kariere
                                    stehen, bis hin zu international bekannten und agierenden Musikern und Bands. Dadurch konnten wir
                                    bereits vielschichtiges Praxis- und Fachwissen aufbauen.“</p>
                                <Link to="/contactus" className="signupbtn text-uppercase" href="consultation-now.html">  <p>JETZT KOSTENLOSES ERSTGESPRÄCH VEREINBAREN</p></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt5 YourpersonalcontactBG">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-12 col-lg-10 col-xl-7 px-2 px-sm-5">
                                <h5 className="titleSec text-white">Your personal contact!</h5>
                                <p className='mb-5 text-white'>Bei uns sind Sie keine Nummer! Sie haben einen persönlichen Ansprechpartner, welcher auf Ihre individuelle Situation eingeht, Sie betreut und für SIE da ist! Bei Fragen steht er Ihnen jederzeit zur Verfügung! Ein gesundes und solides Wachstum der Künstler ist uns wichtig und wir unterstützen Sie mit viel persönlichem Engagement.</p>

                            </div>
                        </div>
                    </div>
                </section>


                <div className="container">
                    <section className="mt5 YourpersonalcontactBox">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-6 col-lg-5">
                                <img src="./assets/images/Gabriel.png" className="img-fluid" alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-7 px-2 px-sm-5">
                                <h5 className="titleSec">Your personal contact  <span className="text-black notranslate">GABRIEL</span></h5>
                                <p className="mb-3">Mein Name ist Gabriel Spateneder, ich wohne in Bayern und betreue
                                    über 60 Künstler, sowie mehrere Verlage und deren Bands, in Sachen
                                    GEMA Abwicklung und Beratung. Ich arbeite mit Künstlern, welche
                                    noch am Anfang Ihrer Karriere stehen bis hin zu international bekannten
                                    und agierenden Musikern.</p>
                                <p>Durch die Zusammenarbeiten mit den Künstlern und dem Einblick in
                                    die Verlagsarbeit, konnte ich bereits vielschichtiges Praxis- und
                                    Fachwissen aufbauen und bin durch regelmäßige Lehrgänge,
                                    Workshops, Veranstaltungen der GEMA und Kontakt zu meinem
                                    Netzwerk in der Musikbranche, stets auf dem aktuellen Stand über
                                    Entwicklungen und Änderungen.</p>
                                <p className="mb-3">Ein gesundes und solides Wachstum der Künstler ist mir wichtig und
                                    ich unterstütze diese mit viel persönlichem Einsatz sowie transparenter
                                    und offener Arbeitsweise, auf Ihrem Weg in der Musikbranche.
                                    Da ich ebenfalls als Musiker in einer Band aktiv bin, kenne ich die
                                    Probleme im Bandalltag und wir finden gemeinsam einen Weg, an
                                    dieser Stelle anzusetzen, damit Du Dich wieder auf das Wichtigste
                                    konzentrieren kannst, Deine Musik.</p>

                                <p>-Geschäftsführer, CleverMusik - GEMA Beratung Abwicklung
                                </p>
                                <p>-Geschäftsführer, Clever BandAssist
                                </p>
                                <p>-Dozent, Fachbereich GEMA - Bundesakademie für Kulturelle Bildung
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="mt5">
                    <div className="container">
                        <div className="row ">
                            <div className="col-sm-12 col-md-12 col-lg-12 text-center mb-5">
                                <h2 className="text-theme fw-bold"> Das sagen Künstler über die Arbeit von Gabriel</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt-3 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-4 mb-3">
                                <div className="workBoxSha">
                                    <div className="position-relative">

                                        <video src="./assets/video/david.mp4" poster="./assets/images/daniel.png" className="workBoxImage" width="480" controls />

                                    </div>
                                    <div className="ps-4">
                                        <p className="mb-3 notranslate">David Gramberg</p>
                                        <a href="https://www.facebook.com/davidgrambergmusic" target="_blank" className="mb-5">facebook</a>
                                        <p className="mb-3 notranslate">Zusammenarbeit seit 2019</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 mb-3">
                                <div className="workBoxSha">
                                    <div className="position-relative">
                                        {/* <img src="./assets/images/work2.png" className="workBoxImage" alt="" /> */}

                                        <video src="./assets/video/Snowgoons.mov" poster="./assets/images/img123.png" className="workBoxImage" width="480" controls />

                                    </div>
                                    <div className="ps-4">
                                        <p className="mb-3 notranslate">Snowgoons</p>
                                        <a href="https://www.facebook.com/SnowgoonsMusic" target="_blank" className="mb-5">facebook</a>                                        <p className="mb-3 notranslate">Zusammenarbeit seit 2019</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 mb-3">
                                <div className="workBoxSha">
                                    <div className="position-relative">
                                        {/* <img src="./assets/images/work3.png" className="workBoxImage" alt="" /> */}
                                        <video src="./assets/video/sara.mp4" poster="./assets/images/sara.png" className="workBoxImage" width="480" controls />


                                    </div>
                                    <div className="ps-4">
                                        <p className="mb-3 notranslate">Sara Brandhuber</p>
                                        <a href="https://www.facebook.com/sarabrandhuber" target="_blank" className="mb-5">facebook</a>                                        <p className="mb-3 notranslate">Zusammenarbeit seit 2018</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 text-center mb-5">
                                <Link to="/contactUs" className="signupbtn text-uppercase">
                                    <p>JETZT KOSTENLOSES ERSTGESPRÄCH VEREINBAREN </p> </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt5">
                    <div className="container">
                        <div className="row ">
                            <div className="col-sm-12 col-md-12 col-lg-12 text-center mb-5">
                                <h2 className="text-theme fw-bold">Frequently asked questions</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-5">
                    <div className="container">
                        <div className="row ">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button text-theme" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Wie viel kostet mich Ihre Betreuung?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body"> Wenden Sie sich mit ihrem Anliegen gerne an uns. Wir erstellen Ihnen ein individuelles Angebot. Wir
                                                sind prozentual an den Ausschüttungen der GEMA beteiligt.
                                                Wir können behaupten, für unsere Dienstleistung der günstigste Anbieter zu sein!
                                                Du hast bereits ein Angebot? Lass es uns gerne wissen, wir machen dir ein Besseres!</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button text-theme collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <p>Muss ich etwas von meinen Rechten abtreten?</p>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">  <span style={{ color: "#a92421" }}> NEIN! </span> Im Gegensatz zu Verlagen bleibt Ihr Rechten dort, wo es hingehört –
                                                zu   <span style={{ color: "#a92421" }}>100 % bei Ihnen! </span> Wir arbeiten auf Rechnungsbasis, welche nach Ausschüttung
                                                Ihrer Tantiemen erstellt wird</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button text-theme collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                When do I have to pay you?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                We only issue our invoice once your royalties have been paid out by GEMA. So you have no monthly costs and do not have to pay in advance!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button text-theme collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                Is there a contract term?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <span style={{ color: "#a92421" }}> NEIN!</span>  Sie können den Vertrag jederzeit beenden. Abgerechnet werden auch nur die Ausschüttungen für
                                                Nutzungen, welche während der Vertragslaufzeit erfolgt sind.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Gema