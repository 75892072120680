import React, { useState } from 'react'
import { useFormik } from 'formik'
import { contactUsSchema } from './contactUsSchema'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'

const initialValues = {
    firstname: "",
    lastname: "",
    projectname: "",
    email: "",
    mobile: "",
    news: "",
    countryCode: "+49"
}
const ContactUs = () => {
    const navigate = useNavigate();
    const [cbaMember, setCbaMember] = useState("")
    const [gemaMember, setGemaMember] = useState("")
    const [appointment, setAppointment] = useState("")
    const [countryData, setCountryData] = useState([])
    const [time, setTime] = useState("")
    const [showTime, setShowTime] = useState(false)

    const { values, touched, errors, handleChange, handleBlur, setFieldValue, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: contactUsSchema,
        onSubmit: (values, action) => {
            handleSignUp()
        }
    })

    const handleCbaMember = (e) => {
        setCbaMember(e.target.value)
    }
    const handleGemaMember = (e) => {
        setGemaMember(e.target.value)
    }
    const handleAppointment = (e) => {
        setShowTime(true)
        setAppointment(e.target.value)
    }
    const handleAppointmentTime = (e) => {
        setTime(e.target.value)
    }
    const valid = () => {
        if (gemaMember === "") {
            toast.error("Please Select Gema Member Details", {
                theme: 'colored',
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return false
        }
        if (cbaMember === "") {
            toast.error("Please Fill Clever Band Assist User Details", {
                theme: 'colored',
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return false
        }
        if (appointment === "") {
            toast.error("Please Fill Appointment Details", {
                theme: 'colored',
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return false
        }
        if (time === "") {
            toast.error("Please Select Time", {
                theme: 'colored',
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return false
        }
        else {
            return true
        }
    }
    const handleSignUp = () => {
        if (valid()) {
            axios({
                method: 'post',
                headers: {
                    "Accept": "/",
                    "Content-Type": "application/json",
                },
                url: `${process.env.REACT_APP_URL}/contact-us-enquiry`,
                data:
                {
                    "first_name": values.firstname,
                    "last_name": values.lastname,
                    "is_gema_member": gemaMember,
                    "band_or_project_name": values.projectname,
                    "email": values.email,
                    "phone_number": `${values.countryCode}${values.mobile}`,
                    "text_information": values.news,
                    "enquiry_appointment_slot_type": appointment,
                    "is_using_platform_already": cbaMember,
                    "is_appointment_at_weekend": time


                }
            })
                .then(res => {
                    if (res.status === 200) {

                        toast.success(res.data.message, {
                            theme: "colored",
                            position: "top-center",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        navigate('/')
                    }

                })
                .catch((error) => {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                })
        }
    }
    return (
        <div>
            <div id="hero" className="d-none">

                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="assets/img/gemaBanner.png" className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 className="text-uppercase position-relative"><img src="assets/img/bannerLogo.png" className="bannrLogo" alt="" /><span className="h5Font"><span>clever</span> <span className="fw-bold">bandassist-</span></span> </h5>
                                <p className="fw-bold deiFont">die band - und konzertmanagement software</p>
                                <a className="signupbtn text-uppercase" href="#">sign up now for free</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <section className="d-none d-none mt-3">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner textSlider">
                        <div className="carousel-item active">
                            <div className="carousel-caption">
                                <h5 className="text-uppercase position-relative"><img src="assets/img/bannerLogoblack.png" className="bannrLogo" alt="" /><span className="h5Font"><span>clever</span> <span className="fw-bold">bandassist-</span></span> </h5>
                                <p className="fw-bold deiFont">die band - und konzertmanagement software</p>
                                <a className="signupbtn text-uppercase" href="#">sign up now for free</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <main id="main" className="contetTop">
                <section className="gemaBg">
                    <div className="container">
                        <div className="row mt5">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <form className="row gemaFormStyle mb-5">
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
                                        <div className="position-relative">
                                            <input type="text" className="form-control" name="firstname" value={values.firstname} placeholder="Mandatory field" onChange={handleChange} onBlur={handleBlur} />
                                            <span className="inputTitle">first name</span>
                                        </div>
                                        {errors.firstname && touched.firstname ? <p style={{ color: 'red' }}>{errors.firstname}</p> : ""}
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
                                        <div className="position-relative">
                                            <input type="text" className="form-control" placeholder="Mandatory field" name="lastname" value={values.lastname} onChange={handleChange} onBlur={handleBlur} />
                                            <span className="inputTitle">surname</span>
                                        </div>
                                        {errors.lastname && touched.lastname ? <p style={{ color: 'red' }}>{errors.lastname}</p> : ""}
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
                                        <div className="position-relative">
                                            <div className="gemamemberRadio form-control border-0 bg-transparent">
                                                <span>
                                                    <input type="radio" id="No" name="member" onChange={handleGemaMember} value="0" />
                                                    <label htmlFor="No">Nein</label>
                                                </span>
                                                <span>
                                                    <input type="radio" id="yes" name="member" onChange={handleGemaMember} value="1" />

                                                    <label htmlFor="yes" className='notranslate'>Ja</label>

                                                </span>
                                            </div>
                                            <span className="inputTitle">GEMA member</span>
                                            {/* <span className="MandatoryText">Mandatory field</span> */}
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
                                        <div className="position-relative">
                                            <input type="text" className="form-control" name="projectname" value={values.projectname} placeholder="Mandatory field" onChange={handleChange} onBlur={handleBlur} />
                                            <span className="inputTitle">band/project name</span>
                                        </div>
                                        {errors.projectname && touched.projectname ? <p style={{ color: 'red' }}>{errors.projectname}</p> : ""}
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
                                        <div className="position-relative notranslate">
                                            <PhoneInput defaultCountry="DE"
                                                inputProps={{
                                                    name: "mobile",
                                                }}
                                                enableSearch
                                                value={
                                                    values.countryCode + values.mobile
                                                }
                                                onChange={(phone, country, e, formattedValue) => {
                                                    setCountryData({ formattedValue, country });
                                                    if (phone.length > 0) {
                                                        const raw = phone.slice(
                                                            country?.dialCode?.length
                                                        );
                                                        setFieldValue("mobile", raw);
                                                        setFieldValue(
                                                            "countryCode",
                                                            formattedValue?.split(" ")[0]
                                                        );
                                                    } else {
                                                        setFieldValue("mobile", "");
                                                        setFieldValue("countryCode", "");
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                placeholder="Contact number"
                                                containerclassName="gemaFormStyle form-control"
                                            />


                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
                                        <div className="position-relative">
                                            <input type="email" className="form-control" name="email" value={values.email} placeholder="Mandatory field" onChange={handleChange} onBlur={handleBlur} />
                                            <span className="inputTitle">email</span>
                                        </div>
                                        {errors.email && touched.email ? <p style={{ color: 'red' }}>{errors.email}</p> : ""}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                                        <div className="position-relative form-control border-0 bg-transparent">
                                            <h5 className="text-black">I use Clever BandAssist already</h5>
                                            <div className="gemamemberRadio">
                                                <span>
                                                    <input type="radio" id="no" name="alreadyUse" value="0" onChange={handleCbaMember} />
                                                    <label htmlFor="alreadyUseNo">Nein</label>
                                                </span>
                                                <span>
                                                    <input type="radio" id="yes" name="alreadyUse" value="1" onChange={handleCbaMember} />
                                                    <label className='notranslate'>Ja</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                                        <div className="position-relative">
                                            <textarea className="textarea-control form-control" rows="7" placeholder="News..." name="news" value={values.news} onChange={handleChange} onBlur={handleBlur}></textarea>
                                        </div>
                                        {errors.news && touched.news ? <p style={{ color: 'red' }}>{errors.news}</p> : ""}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                        <div className="position-relative ps-4">
                                            <h5 className="text-black">Would you like to make an appointment?</h5>
                                            <p>Sagen Sie uns, wann es Ihnen am besten passt- wir melden uns schnellst möglich bei Ihnen</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                                        <div className="position-relative ps-4">
                                            <div className="nutritionRadio pt-0">
                                                <span>
                                                    <input type="radio" id="workingdays" name="appointment1" value="1" onChange={handleAppointment} />
                                                    <label htmlFor="workingdays">Working days</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {showTime ?
                                        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                                            <div className="position-relative ps-4">
                                                <div className="nutritionRadio pt-0 radTome">
                                                    <span>
                                                        <input type="radio" id="workingdays1" name="appointment12" value="1" onChange={handleAppointmentTime} />
                                                        <label htmlFor="workingdays1"><img src="assets/img/timeIcon.png" alt="" width="40px" /> 8:00 - 12:00</label>
                                                    </span>
                                                </div>

                                            </div>
                                        </div> : <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                                            <div className="position-relative ps-4">
                                                <div className="nutritionRadio pt-0 radTome">

                                                </div>
                                            </div>
                                        </div>}

                                    {showTime ?
                                        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                                            <div className="position-relative ps-4">
                                                <div className="nutritionRadio pt-0 radTome">
                                                    <span>
                                                        <input type="radio" id="workingdays2" name="appointment12" value="2" onChange={handleAppointmentTime} />
                                                        <label htmlFor="workingdays2"><img src="assets/img/timeIcon.png" alt="" width="40px" /> 18:00 - 22:00</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> : <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                                            <div className="position-relative ps-4">
                                                <div className="nutritionRadio pt-0 radTome">

                                                </div>
                                            </div>
                                        </div>}

                                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                                        <div className="position-relative ps-4">
                                            <div className="nutritionRadio pt-0">
                                                <span>
                                                    <input type="radio" id="Weekend" name="appointment1" value="4" onChange={handleAppointment} />
                                                    <label htmlFor="Weekend">Weekend</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {showTime ?
                                        <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                                            <div className="position-relative ps-4">
                                                <div className="nutritionRadio pt-0 radTome">
                                                    <span>
                                                        <input type="radio" id="Weekend1" name="appointment12" value="3" onChange={handleAppointmentTime} />
                                                        <label htmlFor="Weekend1"><img src="assets/img/timeIcon.png" alt="" width="40px" /> 13:00 - 18:00</label>
                                                    </span>
                                                </div>

                                            </div>
                                        </div> : <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                                            <div className="position-relative ps-4">
                                                <div className="nutritionRadio pt-0 radTome">

                                                </div>
                                            </div>
                                        </div>}
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                                        <button type="submit" className="btn signupbtn text-uppercase notranslate" onClick={handleSubmit}>senden</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
            <ToastContainer position="top-center"
                theme='colored'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </div>
    )
}

export default ContactUs