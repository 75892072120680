import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getFiles } from "../../app_redux/slice/songFiles";
import S3FileUpload from "react-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const Textfile = ({ show, handleShow, id, name, tab2, tab6, tab7 }) => {
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");
  const [setList, setSetList] = useState([]);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const { allFiles, loading } = useSelector((state) => state.file);
  var filesize = "";

  useEffect(() => {
    dispatch(getFiles({ songid: id }));
  }, []);
  const getSetList = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: "http://3.131.5.153:4050/v1/get-band-leader-set-list-by-band-id",
      data: {
        page_no: 1,
      },
    }).then((res) => {
      setSetList(res.data.data);
    });
  };
  function bytesToSize(bytes) {
    var sizes = ["B", "K", "M", "G", "T", "P"];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes <= 1024) {
        return bytes + " " + sizes[i];
      } else {
        bytes = parseFloat(bytes / 1024).toFixed(2);
        filesize = `${bytes} kb`;
      }
    }
    return bytes + " P";
  }
  const handleFile = (e) => {
    setLoader(true);
    bytesToSize(e.target.files[0].size);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log("RES", data)
        setImage(data.location);
        setLoader(false);
        uploadTextfile(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };
  const uploadTextfile = (i) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/upload-musician-song-files`,
      data: {
        m_song_id: id,
        musician_song_url: "",
        musician_text_sheet_url: i,
        musician_music_sheet_url: "",
        musician_text_sheet_size: filesize ? filesize : "",
        musician_music_sheet_size: "",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getFiles({ songid: id }));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const deleteTextfile = (i) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-musician-song-files`,
      data: {
        attachement_id: allFiles?.attachement_id,
        is_music_sheet: 0,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getFiles({ songid: id }));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} size="lg">
        <Modal.Body className="p-0 mb-5">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <h4 className=" text-center">{name}</h4>
                <p className=" text-center">{tab2}</p>
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable text-center">
                      <thead className="">
                        <tr>
                          <th scope="col">upload</th>
                          <th scope="col">download</th>
                          <th scope="col">{tab6}</th>
                          <th scope="col">{tab7}</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="upload-btn-wrapper Attachment">
                              {loader ? (
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  <img
                                    src="assets/images/gallery.png"
                                    width="25px"
                                    alt=""
                                  />
                                  <input type="file" onChange={handleFile} />
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="d-flex gap-3 justify-content-center">
                              {allFiles?.musician_text_sheet_url ? (
                                <a
                                  href={allFiles?.musician_text_sheet_url}
                                  target="_blank"
                                  download={allFiles?.musician_text_sheet_url}
                                >
                                  <img
                                    src="assets/images/live-file.png"
                                    width="25px"
                                    alt=""
                                  />{" "}
                                </a>
                              ) : (
                                <p className="notranslate">NA</p>
                              )}
                            </div>
                          </td>
                          <td>
                            {allFiles?.musician_text_sheet_size ? (
                              allFiles?.musician_text_sheet_size
                            ) : (
                              <p className="notranslate">NA</p>
                            )}
                          </td>
                          <td>
                            <div className="d-flex gap-3 justify-content-center">
                              <img
                                src="assets/images/delete.png"
                                width="25px"
                                alt=""
                                onClick={deleteTextfile}
                              />{" "}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Textfile;
