import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer/footer'
import Header from '../Components/Header/header'
import Sidebar from '../Components/Sidebar/sidebar'

const Main = ({ switchtoken }) => {
    return (
        <>
            <div className="admin-panel">
                <Header switchtoken={switchtoken} />
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <Sidebar />
                        <Outlet />
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default Main