import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getChats = createAsyncThunk("chats", async () => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let chats =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-messages`,
            data:{
                "band_id": 57
            }
        })
            .then(res => res.data.data)
    return chats
})

const listChats = createSlice(({
    name: "all chats",
    initialState: {
        allChats: [],
        loading: false
    },
    extraReducers: {
        [getChats.pending]: (state, action) => {
            state.loading = true;
        },
        [getChats.fulfilled]: (state, action) => {
            state.allChats = action.payload
        },
        [getChats.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listChats.reducer;