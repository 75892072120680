import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getMusicianDetail = createAsyncThunk("musician", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let musiciandetail =
        await axios({
            method: 'get',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-profile`,

        })
            .then(res => res.data.data)
    return musiciandetail
})

const listMusician = createSlice(({
    name: "musician",
    initialState: {
        musicianDetail: "",
        loading: false
    },
    extraReducers: {
        [getMusicianDetail.pending]: (state, action) => {
            state.loading = true;
        },
        [getMusicianDetail.fulfilled]: (state, action) => {
            state.musicianDetail = action.payload
        },
        [getMusicianDetail.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listMusician.reducer;