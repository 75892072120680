import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getAllChatNotification = createAsyncThunk("count", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let files =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-musician-unread-notification-count`,
            data: {
                "user_type": 1,
                "notification_type": "7",
                "is_chat": 1
            }
        })
            .then(res => res.data.data.count)
    return files
})

const taskCount = createSlice(({
    name: "task count",
    initialState: {
        allchatcount: "",
        loading: false,
        chatId: ""
    },
    reducer: {
        chatId: (state, action) => {
            state.chatId = action.payload;
            console.log("AC",action)
        }
    
    },
    extraReducers: {
        [getAllChatNotification.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllChatNotification.fulfilled]: (state, action) => {
            state.allchatcount = action.payload
        },
        [getAllChatNotification.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))
export const {
    chatId
} = taskCount.actions

export default taskCount.reducer;