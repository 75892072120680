import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../../app_redux/slice/notification';



const AllNotification = () => {
    const navigate = useNavigate()
    const cookee = new Cookies();
    const dispatch = useDispatch()
    const token = cookee.get('tokenmusician')
    const [newTask, setNewTask] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const { notification, loading } = useSelector((state) => state.notification)


    useEffect(() => {
        dispatch(getNotifications(({ pageno: pageNo })))
    }, [])

    const handleNext = (e) => {
        e.preventDefault()
        setPageNo(pageNo + 1)
        dispatch(getNotifications({ pageno: pageNo + 1 }))
    }
    const handlePrevious = (e) => {
        e.preventDefault()
        setPageNo(pageNo - 1)
        dispatch(getNotifications({ pageno: pageNo - 1 }))
    }

    return (
        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <div className="row mt-3">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <h3>All Notifications</h3>
                    </div>

                </div>

                <div className="row mt-3">
                    <div className="col-sm-12 mb-5">
                        {notification.map((item, i) => {
                            let col = item.label_color
                            return <div className="mt-4" >

                                <div className="d-flex justify-content-around ">
                                    <h5 >{item.title} </h5>
                                    <p className='mx-5'> {item?.created_at
                                        ? new Date(
                                            item?.created_at
                                        ).toLocaleString()
                                        : "-"}</p>
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                        <div className="d-flex align-items-center lg-justify-content-end">
                            <nav aria-label="...">
                                <ul className="pagination">
                                    <li className={pageNo === 1 ? "page-item disabled" : "page-item"}>
                                        <a className="page-link" href="#" onClick={handlePrevious}>Previous</a>
                                    </li>
                                    <li class="page-item active">
                                        <a className="page-link" href="#">{pageNo} <span className="sr-only">(current)</span></a>
                                    </li>
                                    <li class={notification?.length <= 9 ? "page-item disabled" : "page-item"}>
                                        <a className="page-link" href="#" onClick={handleNext}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>

        </article>
    )
}

export default AllNotification