import React, { useState } from 'react'
import ChatListItems from './chatListItems'
import AllChats from './allChats'
import { useSelector } from 'react-redux'
import { getEventChatList } from '../../app_redux/slice/eventChatlist'
import { getTaskChatList } from '../../app_redux/slice/taskChatlist'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ChatListItemsEvent from './eventList'
import EventChats from './eventAllChats'


const EventChatList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [eventId, setEventId] = useState("")
    const [band, setBand] = useState(false)
    const [event, setEvent] = useState(false)
    const [task, setTask] = useState(false)
    const [taskId, setTaskid] = useState("")
    const [chatName, setChatName] = useState("")

    const { allEventChats } = useSelector((state) => state.eventchat)
    const { allBandChats } = useSelector((state) => state.bandchat)
    const { allTaskChats } = useSelector((state) => state.taskchat)
    const handleBand = () => {
        navigate("/chat-list")
    }
    const handleEvent = () => {
        navigate("/chatlistEvent")
    }
    const handleTask = () => {
        navigate("/chatlistTask")
    }

    return (

        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <div className="bg-white">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 ledtBorder pe-0">
                            <h6 className="d-flex align-items-center justify-content-between px-2 pt-2 mb-0">
                                <div className="">
                                    <ul className="nav nav-pills group-nav bandUserTab chatBtnTab mt-0" id="pills-tab" role="tablist">
                                        <li className="nav-item me-3">
                                            <button className="nav-link notranslate" onClick={handleBand}>Band <img src="assets/images/Infowhite.png" alt="" className="mx-2" title="All band chat comes here." /></button>
                                        </li>
                                        <li className="nav-item me-3">
                                            <button className="nav-link notranslate active" onClick={handleEvent}>Event <img src="assets/images/Infowhite.png" alt="" className="mx-2" title="All events chat comes here." /></button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="nav-link notranslate" onClick={handleTask}>Task <img src="assets/images/Infowhite.png" alt="" className="mx-2" title="All tasks chat comes here." /></button>
                                        </li>
                                    </ul>
                                </div>
                            </h6>
                            <hr />
                            <ChatListItemsEvent setEventId={setEventId} band={band} event={event} task={task} setTaskid={setTaskid} setChatName={setChatName} />
                        </div>
                        <EventChats eventId={eventId} taskId={taskId} band={band} event={event} task={task} allEventChats={allEventChats} allBandChats={allBandChats} allTaskChats={allTaskChats} chatName={chatName} />
                    </div>
                </div>
            </div>

        </article>

    )
}

export default EventChatList