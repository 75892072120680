import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getSelfTask } from "../../app_redux/slice/selfTask";
import { useTranslation } from "react-i18next";

const DeleteTask = ({ show, handleShow, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");
  const { tab15, tab16, tab17 } = t("tasks");

  const handleDelete = () => {
    axios({
      method: "delete",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-delete-task/${id}`,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        window?.location?.reload();
        dispatch(getSelfTask());
        handleShow();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="md" centered>
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="text-center mx-2">
                  <h5>{tab15}</h5>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                <div className=" text-center my-3 mt-5">
                  <button className="btn add-new-cancel" onClick={handleShow}>
                    {tab16}
                  </button>
                  <button className="btn add-new-del" onClick={handleDelete}>
                    {tab17}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default DeleteTask;
