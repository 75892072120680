import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getAllSetlist = createAsyncThunk("setlist", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let setup =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-set-list`,
            data: {
                "page_no": obj?.pageno
            }
        })
            .then(res => res.data.data)
    return setup
})

const listSetlist = createSlice(({
    name: "all setlist",
    initialState: {
        allSetlist: [],
        loading: false
    },
    extraReducers: {
        [getAllSetlist.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllSetlist.fulfilled]: (state, action) => {
            state.allSetlist = action.payload;
            state.loading = false;
        },
        [getAllSetlist.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listSetlist.reducer;