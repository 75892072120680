import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getAllnotification = createAsyncThunk("count", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let files =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-musician-unread-notification-count`,
            data: {
                "user_type": 1,
                "notification_type": "",
                "is_chat":1

            }
        })
            .then(res => res.data.data.count)
    return files
})

const eventCount = createSlice(({
    name: "all count",
    initialState: {
        allcount: "",
        loading: false
    },
    extraReducers: {
        [getAllnotification.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllnotification.fulfilled]: (state, action) => {
            state.allcount = action.payload
        },
        [getAllnotification.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default eventCount.reducer;