import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie'
import { bandLeaderLoginSchema } from '../../Schemas/bandleaderLoginSchema';


const initialValues = {
    email: "",
    password: ""
}
const LoginBandleader = () => {
    const cookie = new Cookies();
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: bandLeaderLoginSchema,
        onSubmit: (values, action) => {
            if (handleLogin()) {
                action.resetForm()
            }
        }
    })

    const handleShow = () => {
        setShow(!show)
    }
    const handleLogin = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: 'http://3.131.5.153:4050/v1/band-leader-sign-in-with-email',
            data:
            {
                "email": values.email,
                "password": values.password,
                "device_type": "W",
                "device_token": "web"
            }
        })
            .then(res => {
                if (res.data.message === "User sign-in succesfully!") {
                    cookie.set('token2', res.data.data.auth_token)
                    cookie.set('email2', res.data.data.email)
                    cookie.set('bandname', res.data.data.band_name)
                    cookie.set('bandid', res.data.data.band_id)
                    alert("Sign-in successfully!")
                    toast.success("User sign-in succesfully!", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('')

                }

            })
            .catch((error) => {
                if (error.response.data.message === "Icorrect Password! Please Retry") {
                    toast.error('Incorrect Password.', {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "Account doesn't Exists!") {
                    toast.error("Account doesn't Exists!", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
    }

    // const handleLogin = () => {
    //     axios({
    //         method: 'post',
    //         headers: {
    //             "Accept": "/",
    //             "Content-Type": "application/json",
    //         },
    //         url: 'http://3.131.5.153:4050/v1/sign-in-with-email',
    //         data:
    //         {

    //             "email": values.email,
    //             "password": values.password,
    //             "device_token": "AS",
    //             "device_type": "A"
    //         }
    //     })
    //         .then(res => {
    //             console.log("RESPONSE", res.data)
    //             if (res.data.message === "User sign-in succesfully!") {
    //                 cookie.set('token', res.data.data.auth_token)
    //                 cookie.set('email', res.data.data.email)
    //                 cookie.set('firstname', res.data.data.first_name)
    //                 cookie.set('lastname', res.data.data.last_name)
    //                 cookie.set('profilepic', res.data.data.profile_pic)


    //                 alert("User sign-in succesfully")
    //                 toast.success("User sign-in succesfully !", {
    //                     position: "top-right",
    //                     autoClose: 4000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 })
    //                 navigate('/appointments')

    //             }

    //         })
    //         .catch((error) => {
    //             console.log("Error", error.response)
    //             if (error.response.data.message === 'Icorrect Password! Please Retry') {
    //                 toast.error("Incorrect password", {
    //                     position: "top-right",
    //                     autoClose: 4000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 })
    //             }
    //             if (error.response.data.message === "Account doesn't Exists !") {
    //                 toast.error("Account doesn't Exists!", {
    //                     position: "top-right",
    //                     autoClose: 4000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 })
    //             }
    //             if (error.response.data.message === "Account doesn't Exists!") {
    //                 toast.error("Account doesn't Exists!", {
    //                     position: "top-right",
    //                     autoClose: 4000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 })
    //             }
    //         })
    // }
    return (
        <>
            <section className="">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                            <img src="assets/images/image12.png" className="img-fluid w-100" alt="" />
                            <Link to="/"> <img src="assets/images/logoWhite.png" className="musiLogo" alt="" /></Link>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                            <div className="d-table h-100">
                                <form className="form-style">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            <h3 className="theme-color">LOGIN</h3>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" value={values.email} name="email" onChange={handleChange} onBlur={handleBlur} placeholder="Email address" />
                                                    <img src="assets/images/arroba.png" className="input-img" alt="" />
                                                </div>
                                                {errors.email && touched.email ? <p style={{ color: 'red' }}>{errors.email}</p> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-5">
                                            <div className="form-group">
                                                <div className="input-container">
                                                    <input type={show ? "text" : "password"} id="password-field" value={values.password} name="password" onChange={handleChange} onBlur={handleBlur} className="form-control p-0" placeholder="Password" />
                                                    <span toggle="#password-field" className="pass-view toggle-password input-img" onClick={handleShow}></span>
                                                </div>
                                                {errors.password && touched.password ? <p style={{ color: 'red' }}>{errors.password}</p> : ""}
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-12 col-md-12 text-end mb-5">
                                            <Link to="/forgot-password" className="text-dark">Forgot password</Link>
                                        </div> */}
                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            <button className="btn btn-save w-75" onClick={handleSubmit}>LOGIN</button>
                                        </div>
                                        {/* <div className="col-sm-12 col-md-12 text-center mb-2">
                                            <Link to="/login-phone" className="text-dark">or Login with Phone number</Link>
                                        </div> */}
                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            Don't have an account? <Link to="/signup-bandleader" className="text-dark"><b>Sign Up!</b></Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default LoginBandleader