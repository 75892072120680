import React, { useState, useEffect } from 'react'

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import axios from 'axios';
import Cookies from 'universal-cookie'
import moment from 'moment'
require('moment/locale/de.js')
const localizer = momentLocalizer(moment)



const CalendarView = () => {
    const cookee = new Cookies();
    const [confirmEvents, setConfirmEvents] = useState([])
    const token = cookee.get('tokenmusician')


    useEffect(() => {
        getConfirmEvents()
    }, [])
    const getConfirmEvents = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-confirmed-events`,
            data:
            {
                "page_no": 1,
                "band_id": ""
            }
        })
            .then(res => {
                setConfirmEvents(res.data.data.map((res, i) => {
                    return { id: res.event_id, title: res.event_name, start: res.start_date, end: res.start_date }
                }))
            })
    }

    return (
        <div className='notranslate'>
            <Calendar
                localizer={localizer}
                events={confirmEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                messages={{
                    next: "nachste",
                    previous: "zuruck",
                    today: "Heute",
                    month: "monat",
                    week: "woche",
                    day: "tag"
                }}
            />
        </div>
    )
}

export default CalendarView