import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEventChatList } from '../../app_redux/slice/eventChatlist'
import { getTaskChatList } from '../../app_redux/slice/taskChatlist'
import { getTaskChats } from '../../app_redux/slice/taskChat'

const ChatListItemsTask = ({ setEventId, band, event, task, setTaskid,setTaskName }) => {
    const dispatch = useDispatch()
    const { allTaskChatList } = useSelector((state) => state.taskchatlist)

    useEffect(() => {
        dispatch(getEventChatList())
        dispatch(getTaskChatList())

    }, [])

    const handleChatTaskClick = (taskid, name) => {
        setTaskid(taskid)
        setTaskName(name)
        dispatch(getTaskChats({ taskid: taskid }))
        // setInterval(function () { dispatch(getTaskChats({ taskid: taskid })) }, 3000)
    }

    

    return (
        <>

            {
                allTaskChatList?.length > 0 ? allTaskChatList?.map((res, i) => {
                    return <div className="chatList px-2 " key={i}>
                        <div className="d-flex align-items-center gap-2" onClick={() => handleChatTaskClick(res.task_id, res.task_chat_name)}>
                            <div className="position-relative">
                                {/* <img src="assets/images/profile.png" alt="" className="chatProfile" /> */}
                                {/* <span className="activeUser"></span> */}
                            </div>
                            <div className="">
                                <h6 className="text-capitalize mb-0 notranslate">{res?.task_chat_name}</h6>
                                <p className="ellip-2 sortMsg notranslate">{res?.last_message}</p>
                            </div>
                        </div>
                    </div>


                }) : <p>No Chatlist Found</p>
            }
        </>


    )



}

export default ChatListItemsTask