import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookee = new Cookies();

export const getEventChatList = createAsyncThunk("eventchatslist", async (obj) => {
    let token = cookee.get('bandleader_token');
    // console.log("RED", obj?.page)
    let chats = await axios({
        method: 'post',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/get-band-leader-event-chat-list`,
        data: {
            "page_no": 1,
        }
    })
        .then(res => res.data.data)
    return chats
})

const listChats = createSlice(({
    name: "all event chats list",
    initialState: {
        allEventChatList: [],
        loading: false
    },
    extraReducers: {
        [getEventChatList.pending]: (state, action) => {
            state.loading = true;
        },
        [getEventChatList.fulfilled]: (state, action) => {
            state.allEventChatList = action.payload
        },
        [getEventChatList.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default listChats.reducer;