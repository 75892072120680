import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useTranslation } from "react-i18next";

const SetlistPrint = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");
  const [setlistSongs, setSetlistSongs] = useState([]);
  const [setlistName, setSetlistName] = useState([]);
  const { tab8, tab9, tab10, tab11 } = t("setlist");

  useEffect(() => {
    getSetlistDetail();
  }, []);
  const getSetlistDetail = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-set-list-details`,
      data: {
        set_list_id: localStorage.getItem("setlistid"),
      },
    }).then((res) => {
      setSetlistSongs(res.data.data2);
      setSetlistName(res.data.data[0].set_list_name);
    });
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#content", styles: { fontSize: 22 } });
    doc.save("setlist.pdf");
  };

  return (
    <article className="col-lg-9">
      <div className="position-relative mt-56">
        {/* <img src="assets/images/banner444.png" className="img-fluid w-100" alt="" /> */}
        <div className="bannerCaption px-4">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h3 className="text-capitalize mb-0 text-white">
                marlenw t.lang band
              </h3>
              <h5 className="text-capitalize mb-0 text-white">
                musician artist
              </h5>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center gap-2 text-white">
                <img src="assets/images/gpsBanner.png" className="" alt="" />
                <span>Mistelgau, free state of bavaria(BY), 95490</span>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3 text-end">
              <p className="mb-0 text-white">15 June at 9:00 PM</p>
            </div>
          </div>
        </div>
      </div>
      <div className="article-body store-bd-gy mt-0">
        <div className="row align-items-center">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
            <h4 className="mb-0">{tab8}</h4>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
            <ul
              className="nav nav-pills group-nav ms-auto bandUserTab chatBtnTab mt-0"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <button
                  className="btn nav-link"
                  onClick={generatePDF}
                  style={{ cursor: "pointer" }}
                >
                  {tab8}
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12">
            <div className="pt-3 px-1 bg-white">
              <h5 className="notranslate">{setlistName}</h5>
              <div className="table-responsive">
                <table className="table storetable setlistTabla" id="content">
                  <thead className="">
                    <tr>
                      <th scope="col">{tab11}</th>
                      <th scope="col">{tab9}</th>
                      <th scope="col">Bpm</th>
                      <th scope="col">{tab10}</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {setlistSongs?.map((res, i) => {
                      return (
                        <tr>
                          <td className="notranslate">{res?.display_seq}</td>
                          <td className="notranslate">
                            <h6>{res?.song_name}</h6>
                          </td>
                          <td className="notranslate">
                            <p className="mb-0">{res?.bpm}</p>
                          </td>
                          <td className="notranslate">
                            {res?.time_length?.substr(3, 6)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default SetlistPrint;
