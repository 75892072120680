import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { loginSchema } from '../Schemas/loginSchema'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie'
import { requestForToken } from '../../firebase'
import ConfirmationModal from './ConfirmationModal'
import ButtonLoader from '../Loader/loader'
import { useTranslation } from 'react-i18next'


const initialValues = {
    email: "",
    password: ""
}
const Login = () => {
    const { i18n, t } = useTranslation()

    const cookie = new Cookies();
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const [load, setLoad] = useState(false)
    const [band, setBand] = useState()
    const [confirmation, setConfirmation] = useState(false)
    const [bandName, setBandName] = useState()
    const devicetoken = cookie.get("devicetokenmusician")
    const lng = cookie.get("googtrans")
    const path = window.location.pathname
    const queryParams = new URLSearchParams(window.location.search)
    const bandId = queryParams.get("url")
    const { tab1, tab2, tab3, tab4 } = t("login")


    useEffect(() => {
        if (bandId) {
            handleLinkGenerate()
        }
    })

    const handleChangeLanguage = () => {
        i18n.changeLanguage(i18n.language === "en" ? "de" : "en")
    }

    const handleLinkGenerate = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "link": bandId
            },
            url: `${process.env.REACT_APP_URL}/verify-referral-link`,
        })
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    setBand(res.data.data.band_id)
                    setBandName(res.data.data2)
                }
            })
            .catch((error) => {
                if (error) {
                    navigate("/error")
                }
            })
    }

    const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, action) => {
            if (handleLogin()) {
                action.resetForm()
            }
        }
    })
    const handleConfirmation = (e) => {
        e.preventDefault()
        setConfirmation(!confirmation)
    }

    useEffect(() => {
        SubscribeUser()
        if (bandId) {
            cookie.set("loginband", bandId)
        }
    }, [])

    const SubscribeUser = () => {
        try {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === "granted") {
                        registerServiceWorker()
                        console.log("call")
                    }
                    else if (permission !== "denied") {
                        Notification.requestPermission().then((permission) => {
                            if (permission === "granted") {
                                registerServiceWorker();
                            }
                        })
                    }
                })
        } catch (error) {
            if (error instanceof TypeError) {
                console.log("Not supported")
            } else {
                throw error;
            }
        }
    };

    const registerServiceWorker = () => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("firebase-messaging-sw.js", {
                    scope: `${process.env.PUBLIC_URL}/`,
                    updateViaCache: "none",
                })
                .then(function (registration) {
                    requestForToken(registration);
                })
                .catch(function (err) {
                    console.log("Service worker registration failed, error:", err);
                });
        }
    };
    const handleShow = () => {
        setShow(!show)
    }

    const handleOtp = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
            data:
            {
                "email": values.email,
            }
        })
            .then(res => {
                if (res.data.message === "OTP Sent on Registered Email") {
                    // cookie.set('token', res.data.data.auth_token)
                    // cookie.set('email', res.data.data.email)
                    // cookie.set('firstname', res.data.data.first_name)
                    // cookie.set('lastname', res.data.data.last_name)
                    // cookie.set('profilepic', res.data.data.profile_pic)
                    // cookie.set('phonenumber', res.data.data.phone_number)
                    // cookie.set('userboth', res.data.data.is_user_both)

                    navigate('/verify-otp', { state: { email: values.email, password: values.password } })
                }
            })
            .catch((error) => {
                if (error.response.data.message === 'Icorrect Password! Please Retry') {
                    toast.error("Incorrect password", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "Account doesn't Exists !") {
                    toast.error("Account doesn't Exists!", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
    }

    const handleLogin = () => {
        setLoad(true)
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/sign-in-with-email`,
            data:
            {
                "email": values.email,
                "password": values.password,
                "device_token": devicetoken ? devicetoken : "AS",
                "device_type": "W",
                "band_id": band ? band : "",
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setLoad(false)
                    cookie.set('permission_musician', res.data.data.permission_type)
                    cookie.set('tokenmusician', res.data.data.auth_token)
                    cookie.set('emailmusician', res.data.data.email)
                    cookie.set('firstnamemusician', res.data.data.first_name)
                    cookie.set('lastnamemusician', res.data.data.last_name)
                    cookie.set('profilepicmusician', res.data.data.profile_pic)
                    cookie.set('phonenumbermusician', res.data.data.phone_number)
                    cookie.set('userbothmusician', res.data.data.is_user_both)
                    cookie.set('country_codemusician', res.data.data.country_code)

                    toast.success(res.data.message, {
                        theme: "colored",
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/appointments')
                }

            })
            .catch((error) => {
                setLoad(false)
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    return (
        <>
            <section className="">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                            <img src="assets/images/musicianBanner.png" className="img-fluid w-100" alt="" />
                            <Link to="/"> <img src="assets/images/logo.png" className="musiLogo" alt="" /></Link>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                            <div className="d-flex justify-content-end mt-2">
                                <img src={i18n.language === "en" ? "assets/images/englishMobile.png" : "assets/images/german.png"} style={{ width: "50px", height: "40px" }} alt="" className="img-fluid" onClick={handleChangeLanguage} />
                            </div>
                            <div className="d-table h-100">

                                <form className="form-style">

                                    <div className="row">

                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            <h3 className="theme-color notranslate">LOGIN</h3>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0 notranslate" value={values.email} name="email" onChange={handleChange} onBlur={handleBlur} placeholder="Email" />
                                                    <img src="./assets/images/arroba.png" className="input-img" alt="" />
                                                </div>
                                                {errors.email && touched.email ? <p style={{ color: 'red' }}>{errors.email}</p> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-5">
                                            <div className="form-group">
                                                <div className="input-container">
                                                    <input type={show ? "text" : "password"} id="password-field" value={values.password} name="password" onChange={handleChange} onBlur={handleBlur} className="form-control p-0" placeholder={tab1} />
                                                    <span toggle="#password-field" className="pass-view toggle-password input-img" onClick={handleShow}></span>
                                                </div>
                                                {errors.password && touched.password ? <p style={{ color: 'red' }}>{errors.password}</p> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 text-end mb-5">
                                            <Link to="/forgot-password" className="text-dark">{tab2}</Link>
                                        </div>

                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            {bandId ? <button className="btn btn-save w-75 notranslate" type="submit" onClick={handleConfirmation}>LOGIN</button> :
                                                load ? <ButtonLoader view="btn btn-save w-75 notranslate" /> :
                                                    <button className="btn btn-save w-75 notranslate" onClick={handleSubmit}>LOGIN</button>
                                            }
                                        </div>
                                        {!bandId ?
                                            <div className="col-sm-12 col-md-12 text-center mb-2">
                                                <Link to="/login-phone" className="text-dark"><b>{tab3}</b></Link>
                                            </div> : ""}
                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            <Link to="/signup" state={{ band: band, path: path, bandId: bandId }} className="text-dark notranslate"><b>{tab4}</b></Link>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            {confirmation ? <ConfirmationModal show={confirmation} handleShow={handleConfirmation} handleSubmit={handleSubmit} bandName={bandName} /> : ""}
        </>
    )
}

export default Login