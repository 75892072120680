import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import ReactAudioPlayer from "react-audio-player";
import Textfile from "./textfiles";
import Musicfile from "./musicfiles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllSongs } from "../../app_redux/slice/allSongs";
import { useSelector, useDispatch } from "react-redux";
import { getBandSongs } from "../../app_redux/slice/bandSong";
import ScrollToTop from "../../scrollToTop";
import { useTranslation } from "react-i18next";
import DataLoader from "../Loader/dataLoader";

const Songs = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const token = cookee.get("tokenmusician");
  const lng = cookee.get("googtrans");
  const [membershipSongs, setMembershipSongs] = useState([]);
  const [allSongShow, setAllSongShow] = useState(true);
  const [membershipSongShow, setMembershipSongShow] = useState(false);
  const [allBandleader, setAllBandleader] = useState([]);
  const [showTextfiles, setShowTextfiles] = useState(false);
  const [showMusicfiles, setShowMusicfiles] = useState(false);
  const [songId, setSongId] = useState("");
  const [songName, setSongName] = useState("");
  const { allSongs, loading } = useSelector((state) => state.allsongs);
  const { allBandSongs } = useSelector((state) => state.bandsongs);
  const [pageNo, setPageNo] = useState(1);
  const [pageNo1, setPageNo1] = useState(1);
  const [bandId, setBandid] = useState("");
  const [staffId, setStaffid] = useState("");
  const [creator, setCreator] = useState("");
  const { tab1, tab2, tab3, tab4, tab5, tab6, tab7 } = t("songs");

  useEffect(() => {
    dispatch(getAllSongs({ pageno: pageNo }));
    getAllBandleader();
  }, []);

  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getAllSongs({ pageno: pageNo + 1 }));
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getAllSongs({ pageno: pageNo - 1 }));
  };
  const handleNextBand = (e) => {
    e.preventDefault();
    setPageNo1(pageNo1 + 1);
    dispatch(
      getBandSongs({ pageno: pageNo1 + 1, bandid: bandId, staffid: staffId })
    );
  };
  const handlePreviousBand = (e) => {
    e.preventDefault();
    setPageNo1(pageNo1 - 1);
    dispatch(
      getBandSongs({ pageno: pageNo1 - 1, bandid: bandId, staffid: staffId })
    );
  };
  const handleShowTextfile = () => {
    setShowTextfiles(!showTextfiles);
  };
  const handleShowMusicfile = () => {
    setShowMusicfiles(!showMusicfiles);
  };
  const handleTextfiles = (e, id, name) => {
    e.preventDefault();
    setSongId(id);
    setSongName(name);
    handleShowTextfile();
  };
  const handleMusicfiles = (e, id, name) => {
    e.preventDefault();
    setSongId(id);
    setSongName(name);
    handleShowMusicfile();
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  function onDocumentLoadSuccess({ numPages }) {
    // setNumPages(numPages);
    // console.log(numPages)
  }

  // const getAllSongs = () => {
  //     axios({
  //         method: 'post',
  //         headers: {
  //             "Accept": "/",
  //             "Content-Type": "application/json",
  //             "auth_token": token
  //         },
  //         url: `${process.env.REACT_APP_URL}/musician-all-song-list`,
  //         data:
  //         {
  //             "page_no": 1,
  //         }
  //     })
  //         .then(res => {
  //             // console.log("RESPONSE", res.data)
  //             //setAllSongs(res.data.data)
  //         })
  // }
  const getAllBandleader = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-band-membership-list`,
      data: {
        is_all: 0,
      },
    })
      .then((res) => {
        // console.log("res", res.data)
        setAllBandleader(res.data.data);
        setCreator(res.data.data2);
      })
      .catch((error) => {
        if (error.response.data.message === "Please Select Any Band First!.") {
          toast.error("Please Select Any Band First!.", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  const handleBandId = (id, ids) => {
    setAllSongShow(false);
    setMembershipSongShow(true);
    dispatch(getBandSongs({ pageno: pageNo1, bandid: id, staffid: ids }));
    setBandid(id);
    setStaffid(ids);
  };
  return (
    <>
      <ScrollToTop />

      <article className="col-lg-9">
        {loading ? (
          <DataLoader />
        ) : (
          <div className="article-body store-bd-gy">
            <div className="row align-items-center">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
                <h5 className="mb-0 notranslate">Songs</h5>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
                <ul
                  className="nav nav-pills group-nav mt-0"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <button className="btn nav-link">{tab5}</button>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <button
                        className="btn nav-link dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Band
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <p
                          className="dropdown-item notranslate"
                          onClick={() =>
                            handleBandId(creator.band_id, creator.staff_id)
                          }
                        >
                          {creator.band_name}
                        </p>
                        {allBandleader.map((res, i) => {
                          return (
                            <p
                              className="dropdown-item notranslate"
                              onClick={() =>
                                handleBandId(res.band_id, res.staff_id)
                              }
                            >
                              {res.band_name}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-sm-12 ">
                <div className="d-flex"></div>
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable setlistTabla">
                      <thead className="">
                        <tr>
                          <th scope="col">name</th>
                          {/* <th scope="col" className="text-center">length</th> */}
                          <th scope="col" className="text-center">
                            {tab1}
                            <img
                              src="assets/images/Infowhite.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Hier wird Ihnen signalisiert, ob Urheberrechte bei Bandmitglieder liegen. Ist das Icon grau hinterlegt, liegen keine Rechte bei Bandmitgliedern. Diese Information wird von der Band verwaltet."
                                  : "Here it is signaled to you whether copyrights belong to band members. If the icon has a gray background, the band members have no rights. This information is managed by the band."
                              }
                            />
                          </th>

                          <th scope="col" className="text-center">
                            {tab2}
                            <img
                              src="assets/images/Infowhite.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Hier können Sie eine PDF-Datei uploaden, bearbeiten, downloaden. Diese Datei wird Ihnen somit angezeigt, wenn Sie die Setlisten Ansicht nutzen und auf den jeweiligen Icon klicken"
                                  : "Here you can upload, edit or download a PDF file. This file is displayed to you when you use the set list view and click on the respective icon"
                              }
                            />
                          </th>

                          <th scope="col" className="text-center">
                            {tab3}{" "}
                            <img
                              src="assets/images/Infowhite.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Hier können Sie eine PDF-Datei uploaden, bearbeiten, downloaden. Diese Datei wird Ihnen somit angezeigt, wenn Sie die Setlisten Ansicht nutzen und auf den jeweiligen Icon klicken"
                                  : "Here you can upload, edit or download a PDF file. This file is displayed to you when you use the set list view and click on the respective icon"
                              }
                            />
                          </th>

                          <th scope="col" className="text-center">
                            link{" "}
                            <img
                              src="assets/images/Infowhite.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Hier können Sie einen von der Band hinterlegten Link kopieren und öffnen"
                                  : "Here you can copy and open a link provided by the band"
                              }
                            />
                          </th>
                          <th scope="col" className="text-center">
                            audio{" "}
                            <img
                              src="assets/images/Infowhite.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Hier können Sie die Audiodatei anhören, welche von der Band hinterlegt wurde"
                                  : "Here you can listen to the audio file provided by the band"
                              }
                            />
                          </th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {allSongShow
                          ? allSongs.map((res, i) => {
                              return (
                                <tr>
                                  <td>
                                    <h6 className="notranslate">
                                      {res?.song_name}
                                    </h6>
                                  </td>
                                  {/* <td className="text-center fw-bold">{res?.time_length?.toString()?.substr(3, 9)}</td> */}
                                  <td
                                    className="text-center"
                                    style={{ cursor: "none" }}
                                  >
                                    <img
                                      src={
                                        res?.is_copyright === 1
                                          ? "assets/images/copyRed.png"
                                          : "assets/images/copyBlack.png"
                                      }
                                      width="25px"
                                      alt=""
                                    />
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    <div className="d-flex gap-3 align-items-center justify-content-center">
                                      {/* <img src="assets/images/moreblck.png" width="25px" alt="" /> */}
                                      <a
                                        href={res?.text_sheet_url}
                                        target="_blank"
                                        download={res?.text_sheet_url}
                                      ></a>
                                      <img
                                        src="assets/images/invoice.png"
                                        width="25px"
                                        alt=""
                                        onClick={(e) =>
                                          handleTextfiles(
                                            e,
                                            res?.m_song_id,
                                            res?.song_name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    <div className="d-flex gap-3 align-items-center justify-content-center">
                                      <a
                                        href={res?.music_sheet_url}
                                        target="_blank"
                                        download={res?.music_sheet_url}
                                      ></a>
                                      <img
                                        src="assets/images/live-music-sheet.png"
                                        width="25px"
                                        alt=""
                                        onClick={(e) =>
                                          handleMusicfiles(
                                            e,
                                            res?.m_song_id,
                                            res?.song_name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    <div className="d-flex gap-3 align-items-center justify-content-center">
                                      {res.external_url ? (
                                        <a
                                          href={res?.external_url}
                                          target="_blank"
                                          download={res?.external_url}
                                        >
                                          Link
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3 align-items-center justify-content-center">
                                      {/* <img src="assets/images/live-mic.png" width="20px" alt="" /> */}
                                      <ReactAudioPlayer
                                        src={res.song_url}
                                        controls
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}

                        {membershipSongShow
                          ? allBandSongs.map((res, i) => {
                              return (
                                <tr>
                                  <td>
                                    <h6 className="notranslate">
                                      {res?.song_name}
                                    </h6>
                                  </td>
                                  {/* <td className="text-center fw-bold">{res.time_length.toString()?.substr(3, 9)}</td> */}
                                  <td className="text-center">
                                    <img
                                      src={
                                        res?.is_copyright === 1
                                          ? "assets/images/copyRed.png"
                                          : "assets/images/copyBlack.png"
                                      }
                                      width="25px"
                                      alt=""
                                    />
                                  </td>

                                  <td>
                                    <div className="d-flex gap-3 align-items-center justify-content-center">
                                      {/* <img src="assets/images/moreblck.png" width="25px" alt="" /> */}
                                      <a
                                        href={res?.text_sheet_url}
                                        target="_blank"
                                        download={res?.text_sheet_url}
                                      ></a>
                                      <img
                                        src="assets/images/invoice.png"
                                        width="25px"
                                        alt=""
                                        onClick={(e) =>
                                          handleTextfiles(
                                            e,
                                            res.m_song_id,
                                            res.song_name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3 align-items-center justify-content-center">
                                      <a
                                        href={res?.music_sheet_url}
                                        target="_blank"
                                        download={res?.music_sheet_url}
                                      >
                                        {" "}
                                      </a>
                                      <img
                                        src="assets/images/live-music-sheet.png"
                                        width="25px"
                                        alt=""
                                        onClick={(e) =>
                                          handleMusicfiles(
                                            e,
                                            res?.m_song_id,
                                            res?.song_name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3 align-items-center justify-content-center">
                                      {res.external_url ? (
                                        <a
                                          href={res?.external_url}
                                          target="_blank"
                                          download={res?.external_url}
                                        >
                                          Link
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3 align-items-center justify-content-center">
                                      {/* <img src="assets/images/live-mic.png" width="20px" alt="" /> */}
                                      <ReactAudioPlayer
                                        src={res.song_url}
                                        controls
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}

                        {allSongs?.length === 0 &&
                        membershipSongs?.length === 0 ? (
                          <p className="mt-4">{tab4}</p>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {allSongShow ? (
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <nav aria-label="...">
                      <ul className="pagination">
                        <li
                          className={
                            pageNo === 1 ? "page-item disabled" : "page-item"
                          }
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        <li class="page-item active">
                          <a className="page-link" href="#">
                            {pageNo} <span className="sr-only">(current)</span>
                          </a>
                        </li>
                        <li
                          class={
                            allSongs?.length <= 9
                              ? "page-item disabled"
                              : "page-item"
                          }
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              ) : (
                ""
              )}

              {membershipSongShow ? (
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <nav aria-label="...">
                      <ul className="pagination">
                        <li
                          className={
                            pageNo1 === 1 ? "page-item disabled" : "page-item"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={handlePreviousBand}
                          >
                            Previous
                          </a>
                        </li>
                        <li class="page-item active">
                          <a className="page-link" href="#">
                            {pageNo1} <span className="sr-only">(current)</span>
                          </a>
                        </li>
                        <li
                          class={
                            allBandSongs?.length <= 9
                              ? "page-item disabled"
                              : "page-item"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={handleNextBand}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </article>
      {showTextfiles ? (
        <Textfile
          show={showTextfiles}
          handleShow={handleShowTextfile}
          id={songId}
          name={songName}
          tab2={tab2}
          tab6={tab6}
          tab7={tab7}
        />
      ) : (
        ""
      )}
      {showMusicfiles ? (
        <Musicfile
          show={showMusicfiles}
          handleShow={handleShowMusicfile}
          id={songId}
          name={songName}
          tab3={tab3}
          tab7={tab7}
          tab6={tab6}
        />
      ) : (
        ""
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Songs;
