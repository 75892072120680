import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Cookies from "universal-cookie";
import { SketchPicker, BlockPicker } from "react-color";
import { useNavigate } from "react-router-dom";
import CheckList from "./checklistModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTaskDetail } from "../../app_redux/slice/taskDetail";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TaskOverview = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const token = cookee.get("tokenmusician");
  const [checklistStatus, setChecklistStatus] = useState("");
  const { taskDetail, loading } = useSelector((state) => state.taskdetail);
  const lng = cookee.get("googtrans");
  const { tab10, tab11, tab12, tab13, tab14 } = t("tasks");

  useEffect(() => {
    dispatch(getTaskDetail());
  }, []);

  function formatDate(input) {
    var datePart = input?.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }

  const updateInprogressTask = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-update-task`,
      data: {
        musician_task_id: taskDetail?.users_task_id,
        task_name: taskDetail?.task_name,
        description_info: taskDetail?.description_info,
        attachment_url: "",
        check_list: taskDetail?.task_checklist_details,
        labels: taskDetail?.labels,
        label_color: taskDetail?.label_color,
        dead_line: taskDetail?.dead_line,
        status: 2,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const updateCompletedTask = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-update-task`,
      data: {
        musician_task_id: taskDetail?.users_task_id,
        task_name: taskDetail?.task_name,
        description_info: taskDetail?.description_info,
        attachment_url: "",
        check_list: taskDetail?.task_checklist_details,
        labels: taskDetail?.labels,
        label_color: taskDetail?.label_color,
        dead_line: taskDetail?.dead_line,
        status: 3,
      },
    }).then((res) => {
      //console.log(res.data)
      if (res.status === 200) {
        toast.success(res.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };
  const handleChecklistStatus = (e, id, s) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-musician-tasks-checklist`,
      data: {
        users_task_id: taskDetail?.users_task_id,
        is_self: 1,
        cheklist_item_id: id,
        status: s,
      },
    })
      .then((res) => {
        //console.log(res.data)
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getTaskDetail());
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <div className="col-sm-12">
              <form className="row mt-5 addNAme mx-width">
                <div className="col-sm-9 col-md-9 col-lg-9 mb-5">
                  <h4 className="notranslate">
                    {taskDetail?.task_name?.toUpperCase()}
                  </h4>
                  <p className="mt-5 notranslate">
                    {taskDetail?.description_info}
                  </p>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3 mb-5">
                  <h5>Deadline </h5>
                  <p className="notranslate">
                    {taskDetail?.dead_line
                      ? formatDate(taskDetail?.dead_line?.substr(0, 10))
                      : ""}
                  </p>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="upload-btn-wrapper Attachment">
                    {/* <input type="file" name="myfile" /> */}
                    <p>{tab10}</p>
                    {taskDetail?.attachment_url ? (
                      <a
                        href={taskDetail?.attachment_url}
                        target="_blank"
                        download={taskDetail?.attachment_url}
                      >
                        <img
                          src="assets/images/live-file.png"
                          width="25px"
                          alt=""
                        />{" "}
                      </a>
                    ) : (
                      <p className="notranslate">NA</p>
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5"></div>
                <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-5">
                  <div className="table-responsive">
                    <table className="table storetable text-center">
                      <thead className="">
                        <tr>
                          <th scope="col">Checklist</th>
                          <th scope="col">{tab13}</th>
                          <th scope="col">{tab12}</th>
                          <th scope="col">Information</th>
                          <th scope="col">{tab11}</th>
                          <th scope="col">Deadline</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {taskDetail?.task_checklist_details?.length !== 0 ? (
                          taskDetail?.task_checklist_details?.map((res, i) => {
                            return (
                              <tr>
                                <td className="notranslate">
                                  {res.check_list_item_name}
                                </td>
                                <td>
                                  <button
                                    className="btn add-new"
                                    onClick={(e) =>
                                      handleChecklistStatus(
                                        e,
                                        res.cheklist_item_id,
                                        "2"
                                      )
                                    }
                                    style={{ width: "150px" }}
                                  >
                                    {tab13}
                                  </button>
                                </td>
                                <td>
                                  {" "}
                                  <button
                                    className="btn add-new"
                                    onClick={(e) =>
                                      handleChecklistStatus(
                                        e,
                                        res.cheklist_item_id,
                                        "3"
                                      )
                                    }
                                    style={{
                                      background: "#30ab21",
                                      border: "none",
                                      width: "100px",
                                    }}
                                  >
                                    {tab12}
                                  </button>
                                </td>
                                <td>
                                  {" "}
                                  <input
                                    type="text"
                                    placeholder="information"
                                    value={res.checklist_item_information}
                                    style={{ border: "none" }}
                                  />
                                </td>
                                <td>
                                  {res.status === "3" ? (
                                    <button
                                      className="btn add-new"
                                      style={{
                                        background: "#30ab21",
                                        border: "none",
                                        width: "150px",
                                      }}
                                    >
                                      completed
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {res.status === "2" ? (
                                    <button
                                      className="btn add-new"
                                      style={{ width: "150px" }}
                                    >
                                      {tab13}
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  {res?.check_list_item_dead_line?.substr(
                                    0,
                                    10
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p className="mt-5">{tab14}</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="d-flex justify-content-end my-3">
                    <button
                      className="btn add-new"
                      onClick={updateInprogressTask}
                    >
                      {tab13}
                    </button>
                    <button
                      className="btn add-new"
                      style={{ background: "#30ab21", border: "none" }}
                      onClick={updateCompletedTask}
                    >
                      {tab12}
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="d-flex justify-content-end my-3">
                    <Link to="/tasklist">
                      <button className="btn add-new">
                        {lng === "/auto/de" || "/en/de" ? "Speichern" : "Safe"}
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
    </>
  );
};

export default TaskOverview;
