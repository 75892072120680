import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getMembershipList = createAsyncThunk("membership", async () => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let membership =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-musician-band-membership-list`,
            data:{
                "page_no":1
            }
        })
            .then(res => res.data.data)
    return membership
})

const listMembership = createSlice(({
    name: "all membership",
    initialState: {
        allMembership: [],
        loading: false
    },
    extraReducers: {
        [getMembershipList.pending]: (state, action) => {
            state.loading = true;
        },
        [getMembershipList.fulfilled]: (state, action) => {
            state.allMembership = action.payload
        },
        [getMembershipList.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listMembership.reducer;