import React from 'react'
import { Link } from 'react-router-dom'


const Price = () => {
    return (
        <div>
            <div id="hero" className="">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="assets/images/PricesBanner.png" className="d-block w-100" alt="..." />
                            <div className="carousel-caption priceCaption d-none d-md-block">
                                <p className="pfont">You read correctly</p>
                                <h5 className="Font4"><span className="fw-bolder">You can use</span> Clever <span className="fw-bolder">BandAssist for free!</span> </h5>
                                <h5 className="Font4"><span className="fw-bold text-uppercase">no catch, no tricks</span></h5>

                                <Link to="/signup" className="signupbtn text-uppercase">sign up now for free</Link>
                            </div>
                        </div>
                    </div>
                   

                </div>
            </div>

            <section className="d-block d-md-none mt-3">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner textSlider">
                        <div className="carousel-item active">
                            <div className="carousel-caption">
                                <p className="pfont">You read correctly</p>
                                <h5 className="Font4"><span className="fw-bolder">You can use</span> Clever <span className="fw-bolder">BandAssist for free!</span> </h5>
                                <h5 className="Font4"><span className="fw-bold text-uppercase">no catch, no tricks</span></h5>
                                <p className="pfont">data storage up to <span className="fw-bolder">XXX MB</span></p>
                                <a className="signupbtn text-uppercase" href="#">sign up now for free</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <main id="main" className="bgPriceBdy">
                <section className="">
                    <div className="container">
                        <div className="row mt5">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-5 position-relative">
                                <div className="pricemosiclist">
                                    <div className="">
                                        <p className="position-relative ps6">
                                            <img src="assets/images/group-chat.png" alt="" className="listIcons" /> Unlimited members
                                        </p>
                                        <p className="position-relative ps6">
                                            <img src="assets/images/project.png" alt="" className="listIcons" /> <p>Pro Nutzer kann derzeit eine Band erstellt werden</p>
                                        </p>
                                        <p className="position-relative ps6">
                                            <img src="assets/images/paper-plane.png" alt="" className="listIcons" /> Bind your partners like Booker, Label, Manager, Publisher, technician etc with a
                                        </p>
                                        <p className="position-relative ps6">
                                            <img src="assets/images/calendar.png" alt="" className="listIcons" />
                                            <p>Verwalten Sie Ihre Termine, Musiker und Mitarbeiter </p>
                                        </p>
                                        <p className="position-relative ps6">
                                            <img src="assets/images/to-do-list.png" alt="" className="listIcons" /> Create tasks for your band and keep an overview
                                        </p>
                                        <p className="position-relative ps6">
                                            <img src="assets/images/appointment.png" alt="" className="listIcons" /> Exchange in the assigned chats to your appointments and tasks
                                        </p>
                                    </div>
                                    <div className="">
                                        <img src="assets/images/image12.png" alt="" className="img-fluid wMd100" />
                                    </div>
                                </div>

                              

                                <div className="pricemosiclist">
                                    <div className="">
                                        <img src="assets/images/image2.png" alt="" className="img-fluid wMd100" />
                                    </div>
                                    <div className="">
                                        <p className="position-relative ps6">
                                            <img src="assets/images/drag.png" alt="" className="listIcons" />
                                            <p>Erstelle Setlisten per Drag&Drop</p>
                                        </p>
                                        <p className="position-relative ps6">
                                            <img src="assets/images/transfer1245.png" alt="" className="listIcons" /> <p> Übertragen Sie Ihre Setlisten per Knopfdruck an die GEMA
                                            </p>
                                        </p>
                                      
                                        <p className="position-relative ps6">
                                            <img src="assets/images/resume.png" alt="" className="listIcons" /> Web application - access your from anywhere account to
                                        </p>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt5 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="joinForFreeBgUnder">
                                    <div className="joinForFreeBg">
                                        <h2 className="text-uppercase text-white">  <p>Die Nutzung von Clever BandAssist ist kostenlos</p> </h2>
                                        <p className='text-white'>Nutzung der GEMA-Schnittstelle bei bestehendem GEMA-Abwicklungsvertrag</p>
                                        <div className="etcPrice">
                                            0.00 <i className="bi-currency-euro" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

              

                <section className="mt5">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-6 col-lg-6 ps-md-5 mb-3">
                                <h5 className="titleSec">GEMA - advice and processing</h5>
                                <p className='mb-5'> Wir können behaupten, dass wir der<span style={{ color: "#cf0e0b" }}> günstigste Anbieter </span> für unseren Service sind!
                                    Sie haben bereits ein Angebot? Lassen Sie es uns wissen, wir machen Ihnen ein Besseres!
                                    Für unseren Arbeit im Bereich der Beratung und Abwicklung sind wir prozentual an den Ausschüttungen
                                    für die von uns abgewickelten Konzerte beteiligt.
                                    Alle anderen Einnahmen, genauso wie Ihre
                                    Rechte, bleiben zu 100% bei Ihnen.</p>
                                <a className="signupbtn text-uppercase" href="consultation-now.html">contact us for an offer</a>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pe-0">
                                <img src="assets/images/adviceproces.png" className="img-fluid w-100" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt5">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-6 col-lg-6 ps-0 mb-3">
                                <img src="assets/images/musicworth.png" className="img-fluid w-100" alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pe-5">
                                <h5 className="titleSec">Weil Ihre Musik viel Wert ist! </h5>
                                <p className="mb-5 fw-bold">Are you a member of GEMA or would you like to become one?</p>
                                <p className="mb-5">With Clever BandAssist you have a competent partner at your side who takes care of your processing</p>
                                <Link to="/gema" className="signupbtn text-uppercase">more information</Link>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="mt5 pb-5">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-sm-6 col-md-4 col-lg-3 colBottom">
                                <div className="imageRound">
                                    <img src="assets/images/task.png" className="imgrd" alt="" />
                                </div>
                                <p>Transfer the set list to GEMA and organizers at the push of a button</p>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <div className="imageRound">
                                    <img src="assets/images/to-do-list12.png" className="imgrd" alt="" />
                                </div>
                                <p>Übersichtliche Auflistung mit Status zu den Ausschüttungen</p>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3 colBottom">
                                <div className="imageRound">
                                    <img src="assets/images/money.png" className="imgrd" alt="" />
                                </div>
                                <p>Wir optimieren Ihre Auszahlungssummen, prüfen Ihre
                                    Ausschüttungen und reklamieren die fehlenden Beträge bei der
                                    GEMA</p> 
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <div className="imageRound">
                                    <img src="assets/images/user-profile.png" className="imgrd" alt="" />
                                </div>
                                <p>Persönlicher Ansprechpartner.
                                    <br />
                                    Du hast Fragen
                                    zur GEMA oder deinem Urheberecht?
                                    Wir beraten dich kostenlos und stehen dir zur Seite
                                </p> 
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </div>
    )
}

export default Price