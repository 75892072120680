import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getSelfTask = createAsyncThunk("selftask", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let setup =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-task-list`,
            data: {
                "page_no": 1,
                "is_self": 1,
                "status": 1
            }
        })
            .then(res => res.data.data)
    return setup
})

const listSelfTasks = createSlice(({
    name: "all tasks",
    initialState: {
        allSelfTasks: [],
        loading: false
    },
    extraReducers: {
        [getSelfTask.pending]: (state, action) => {
            state.loading = true;
        },
        [getSelfTask.fulfilled]: (state, action) => {
            state.allSelfTasks = action.payload
        },
        [getSelfTask.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default listSelfTasks.reducer;