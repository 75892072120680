import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getAllSongs = createAsyncThunk("songs", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let setup =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/musician-all-song-list`,
            data: {
                "page_no": obj?.pageno
            }
        })
            .then(res => res.data.data)
    return setup
})

const listSongs = createSlice(({
    name: "all songs",
    initialState: {
        allSongs: [],
        loading: false
    },
    extraReducers: {
        [getAllSongs.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllSongs.fulfilled]: (state, action) => {
            state.allSongs = action.payload;
            state.loading = false;
        },
        [getAllSongs.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listSongs.reducer;