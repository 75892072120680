import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getBandSongs = createAsyncThunk("songs", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let setup =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-band-leader-song-by-band-id`,
            data: {
                "page_no": obj?.pageno,
                "band_id": obj?.bandid,
                "staff_id": obj?.staffid
            }
        })
            .then(res => res.data.data)
    return setup
})

const listSongs = createSlice(({
    name: "all songs",
    initialState: {
        allBandSongs: [],
        loading: false
    },
    extraReducers: {
        [getBandSongs.pending]: (state, action) => {
            state.loading = true;
        },
        [getBandSongs.fulfilled]: (state, action) => {
            state.allBandSongs = action.payload
        },
        [getBandSongs.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listSongs.reducer;