import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'


const InfoModal = ({ show, handleShow, information }) => {
console.log("Show",show)
    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" centered>
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <div className="text-center mx-5">
                                    <h6>{information || "NA"}</h6>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            
        </>
    )
}

export default InfoModal