import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import CalendarView from "../Calendar/calendar";
import { useTranslation } from "react-i18next";
import DeleteTask from "./deleteTask";

const TaskListInprogress = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");
  const [newTask, setNewTask] = useState([]);
  const [assignTask, setAssignTask] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showOwnTask, setShowOwnTask] = useState(true);
  const [showBandTask, setShowBandTask] = useState(true);
  const { tab1, tab2, tab3, tab4, tab5 } = t("tasks");
  const [deleteTask, setDeleteTask] = useState(false);
  const [taskId, setTaskId] = useState("");

  useEffect(() => {
    getNewTasks();
    getAssignTasks();
  }, []);
  const handleBandTask = () => {
    setShowBandTask(true);
    setShowOwnTask(false);
  };
  const handleOwnTask = () => {
    setShowOwnTask(true);
    setShowBandTask(false);
  };
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  const handleChat = (id) => {
    navigate("/chat-task", { state: { task_id: id } });
  };

  const handleDeleteTask = () => {
    setDeleteTask(!deleteTask);
  };

  const handleDelete = (id) => {
    setTaskId(id);
    handleDeleteTask();
  };
  const getNewTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-task-list`,
      data: {
        page_no: 1,
        is_self: 1,
        status: 2,
      },
    }).then((res) => {
      //console.log("RESPONSE", res.data)
      setNewTask(res.data.data);
    });
  };
  const getAssignTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-task-list`,
      data: {
        page_no: 1,
        is_self: 0,
        status: 2,
      },
    }).then((res) => {
      //console.log("RESPONSE", res.data)
      setAssignTask(res.data.data);
    });
  };
  const handleClick = (id) => {
    localStorage.setItem("taskid", id);
    navigate("/task-overview");
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row mt-3">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <h3>Task</h3>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <div className="d-flex align-items-center lg-justify-content-end gap-3">
              <Link
                to="/add-new-task"
                className="btn add-new notiIcon filterIcon"
              >
                {tab4}
              </Link>
              <div className="position-relative filterDrop">
                <img src="assets/images/Checklist.png" alt="" width="" />
                <div className="filterDropSelect ChecklistDrop">
                  <p
                    className="text-uppercase fw-bold"
                    onClick={handleBandTask}
                  >
                    band task
                  </p>
                  <p className="text-uppercase fw-bold" onClick={handleOwnTask}>
                    own task
                  </p>
                  {/* <p className="text-uppercase fw-bold">all task</p> */}
                </div>
              </div>
              {!showCalendar ? (
                <img
                  src="assets/images/calendarGrey.png"
                  alt=""
                  width=""
                  onClick={handleCalendar}
                />
              ) : (
                <img
                  src="assets/images/calendar_red.png"
                  alt=""
                  width=""
                  onClick={handleCalendar}
                />
              )}
            </div>
          </div>
        </div>
        {showCalendar ? <CalendarView /> : ""}
        {!showCalendar ? (
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <ul
                className="nav nav-pills group-nav tabNabs taskListTab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link to="/tasklist" className="nav-link">
                    {tab1}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/task-list-inprogress" className="nav-link active">
                    {tab2}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/task-list-completed" className="nav-link">
                    {tab3}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 mb-5">
              {showOwnTask
                ? newTask.map((item, i) => {
                    let col = item.label_color;
                    return (
                      <div
                        className="taskListBox"
                        style={{ border: `2px solid ${col}` }}
                      >
                        <div className="d-flex align-items-center lg-justify-content-end">
                          {item.is_self === 0 ? (
                            <div className="d-flex align-items-center lg-justify-content-end">
                              <button className="btn notiIcon filterIcon ">
                                <img
                                  src="assets/images/chatBlackRed.png"
                                  alt=""
                                  onClick={() => handleChat(item.users_task_id)}
                                />
                              </button>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center lg-justify-content-end">
                              <button className="btn notiIcon filterIcon ">
                                <img
                                  src="assets/images/delete.png"
                                  alt=""
                                  onClick={() =>
                                    handleDelete(item.users_task_id)
                                  }
                                />
                              </button>
                            </div>
                          )}
                        </div>
                        <div
                          className="row align-items-center"
                          onClick={() => handleClick(item.users_task_id)}
                        >
                          <div className="col-sm-8 col-md-8 col-lg-9">
                            <h3 className="notranslate">{item.task_name}</h3>
                            <p className="notranslate">
                              {item.description_info}
                            </p>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-3">
                            <h6>{formatDate(item.dead_line.substr(0, 10))}</h6>
                            <span
                              className="StatusBtnAbsolate StatusBtn inProgressStatus notranslate"
                              style={{ backgroundColor: col }}
                            >
                              {item.labels}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}

              {showBandTask
                ? assignTask.map((item, i) => {
                    let col = item.label_color;
                    return (
                      <div
                        className="taskListBox"
                        style={{ border: `2px solid ${col}` }}
                        onClick={() => handleClick(item.users_task_id)}
                      >
                        <div className="d-flex align-items-center lg-justify-content-end">
                          <button className="btn notiIcon filterIcon ">
                            <img src="assets/images/chatBlackRed.png" alt="" />
                          </button>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-sm-8 col-md-8 col-lg-9">
                            <h3 className="notranslate">{item.task_name}</h3>
                            <p className="notranslate">
                              {item.description_info}
                            </p>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-3">
                            <h6>{formatDate(item.dead_line.substr(0, 10))}</h6>
                            <span
                              className="StatusBtnAbsolate StatusBtn inProgressStatus notranslate"
                              style={{ backgroundColor: col }}
                            >
                              {item.labels}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {deleteTask ? (
        <DeleteTask
          show={deleteTask}
          handleShow={handleDeleteTask}
          id={taskId}
        />
      ) : (
        ""
      )}
    </article>
  );
};

export default TaskListInprogress;
