import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getEventChats = createAsyncThunk("eventchats", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let chat =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-messages`,
            data: {
                "event_id": obj?.eventid,
                "band_id": -1,
                "task_id": -1
            }
        })
            .then(res => res.data.data)
    return chat
})

const listEventChats = createSlice(({
    name: "all event chats",
    initialState: {
        allEventChats: [],
        loading: false
    },
    extraReducers: {
        [getEventChats.pending]: (state, action) => {
            state.loading = true;
        },
        [getEventChats.fulfilled]: (state, action) => {
            state.allEventChats = action.payload
        },
        [getEventChats.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listEventChats.reducer;