import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "de",
    lng: "de",
    returnObjects: true,
    resources: {
      en: {
        translation: {
          sidebarMusician: {
            tab1: "Appointments",
            tab2: "Setlist",
            tab3: "Songs",
            tab4: "Tasks",
            tab5: "Chat",
            tab6: "Profile",
            tab7: "GEMA",
            tab8: "Create a Band",
            tab9: "Switch band",
          },
          login: {
            tab1: "Password",
            tab2: "Forgot Password ?",
            tab3: "or login with phone",
            tab4: "Don't have account ? Sign up",
          },
          forgotpassword: {
            tab1: "Forgot Password",
            tab2: "in order to retrieve your password, please enter registered email Id",
          },
          signup: {
            tab1: "First Name",
            tab2: "Last Name",
            tab3: "Password",
            tab4: "Confirm Password",
            tab5: "Upload profile picture",
            tab6: "I accept",
            tab7: "Terms and Conditions &",
            tab8: "Privacy Policy",
            tab9: "Signup",
            tab10: "have an account ?",
          },
          appointments: {
            tab1: "Upcoming events",
            tab2: "Invitations to event",
            tab3: "No Record found",
            tab4: "Past events",
            tab5: "Date",
            tab6: "Money",
            tab7: "Street",
            tab8: "Zip code",
            tab9: "City",
            tab10: "Country",
            tab11: "Other Information",
            tab12: "Parking",
            tab13: "Guestlist",
            tab14: "Accept",
            tab15: "Reject",
            tab16: "Accomodation",
            tab17: "Phone",
            tab18: "Town",
            tab19: "Attachment",
            tab20: "Street/House number",
            tab21: "Role",
            tab22: "Staff",
            tab23: "No Record Found",
            tab24: "Driver",
            tab25: "Vehicle",
            tab26: "Available Seats",
            tab27: "Passengers",
            tab28: "Action",
            tab29: "Add Vehicle",
            tab30: "Add Passenger",
            tab31: "Person Name",
            tab32: "Save",
            tab33: "Text File",
            tab34: "Music File",
            tab35: "Length",
            tab36: "Time of setlist",
            tab37: "In total",
            tab38: "Document Type",
            tab39: "Outgoing Invoice",
            tab40: "Incoming Invoice",
            tab41: "Incoming Contract",
            tab42: "Documents Booker",
            tab43: "Documents Sound Engineer",
            tab44: "Other Attachments",
            tab45: "Driver",
            tab46: "Vehicle",
            tab47: "Select",
            tab48: "Save",
          },
          absent: {
            tab1: "ABSENT",
            tab2: "Absense",
            tab3: "from",
            tab4: "to",
            tab5: "Action",
            tab6: "Date",
            tab7: "No Record found",
            tab8: "Are you sure, you want to mark absent?",
            tab9: "Cancel",
            tab10: "Confirm",
          },
          setlist: {
            tab1: "Length of setlist",
            tab2: "Print setlist",
            tab3: "Number of songs",
            tab4: "All",
            tab5: "No Record found",
            tab6: "Text Files",
            tab7: "Music Sheet",
            tab8: "Print",
            tab9: "Name of song",
            tab10: "Length",
            tab11: "Number",
          },
          songs: {
            tab1: "Copyrights",
            tab2: "Text sheet",
            tab3: "Music sheet",
            tab4: "No Record found",
            tab5: "All",
            tab6: "Size",
            tab7: "Action",
          },
          tasks: {
            tab1: "New",
            tab2: "Inprogress",
            tab3: "Completed",
            tab4: "Add new task",
            tab5: "Choose colour",
            tab6: "No Record found",
            tab7: "Done",
            tab8: "Check list",
            tab9: "Attachment",
            tab10: "Attachment Download",
            tab11: "Current Status",
            tab12: "Done",
            tab13: "Inprogress",
            tab14: "No Checklist Found",
            tab15: "Are you sure, you want to delete this task?",
            tab16: "Cancel",
            tab17: "Delete",
          },
          profile: {
            tab1: "Profile",
            tab2: "Street",
            tab3: "House Number",
            tab4: "Zip code",
            tab5: "Town",
            tab6: "Country",
            tab7: "Manage Notification",
            tab8: "Nutrition",
            tab9: "Vegetarian",
            tab10: "Gema membership number",
            tab11: "Permission",
            tab12: "Action",
            tab13: "Save changes",
            tab14: "Push Notification",
            tab15: "SMS Notification",
            tab16: "Email Notification",
            tab17: "Meat/Fish",
            tab18: "Manage Notification Setting",
          },
          createband: {
            tab1: "Register Band",
          },
          setting: {
            tab1: "Profile",
            tab2: "Terms & Conditions",
            tab3: "Privacy Policy",
            tab4: "About Us",
            tab5: "Change Password",
            tab6: "Delete Account",
          },
          forms: {
            tab1: "Email Verification",
            tab2: "Please enter 6 digits code",
            tab3: "Verify",
            tab4: "Resend Code",
            tab5: "Phone verification",
          },
          gema: {
            tab1: "Gema",
            tab2: "Because your music is worth a lot! Around 40% of the royalties are missing for our customers on the first payout. With Clever BandAssist you have a competent partner at your side who takes care of your GEMA processing.",
            tab3: "USE CLEVER BANDASSIST FOR YOUR PROCESSING!",
            tab4: "Get More Information",
          },
          chat: {
            tab1: "All message",
            tab2: "Switch to band chat",
          },
        },
      },
      de: {
        translation: {
          sidebarMusician: {
            tab1: "Events",
            tab2: "Setlists",
            tab3: "Songs",
            tab4: "Tasks",
            tab5: "Chat",
            tab6: "Profil",
            tab7: "GEMA",
            tab8: "Bandaccount erstellen",
            tab9: "Zu Bandaccount wechseln",
          },
          login: {
            tab1: "Passwort",
            tab2: "Passwort vergessen?",
            tab3: "mit Telefonnummer einloggen",
            tab4: "Noch keinen Account? Hier registrieren",
          },
          forgotpassword: {
            tab1: "Forgot Password",
            tab2: "Gib deine Emailadresse ein, um dein Passwort zurückzusetzen",
          },
          signup: {
            tab1: "Vorname",
            tab2: "Nachname",
            tab3: "Passwort",
            tab4: "Passwort bestätigen",
            tab5: "Profilbild hochladen",
            tab6: "Ich habe die",
            tab7: "AGB und den",
            tab8: "Datenschutz für die Nutzung von Clever BandAssist zur Kenntniss genommen",
            tab9: "Registrieren",
            tab10: "Du hast bereits einen Account ?",
          },
          appointments: {
            tab1: "Bevorstehende Events",
            tab2: "Einladungen zu Events",
            tab3: "Kein Eintrag gefunden",
            tab4: "Vergangene Events",
            tab5: "datum",
            tab6: "gage",
            tab7: "Straße",
            tab8: "Postleitzahl",
            tab9: "Stadt",
            tab10: "Land",
            tab11: "Weitere Informationen",
            tab12: "Parken",
            tab13: "Gästeliste",
            tab14: "Annehmen",
            tab15: "Ablehnen",
            tab16: "Unterkunft",
            tab17: "Telefon",
            tab18: "Stadt",
            tab19: "Anhang",
            tab20: "Straße, Hausnummer",
            tab21: "Rolle",
            tab22: "Personal",
            tab23: "Kein Eintrag gefunden",
            tab24: "Fahrer ",
            tab25: "Fahrzeug ",
            tab26: "Verfügbare Plätze",
            tab27: "Mitfahrer ",
            tab28: "Aktion",
            tab29: "Fahrzeug hinzufügen",
            tab30: "Mitfahrer hinzufügen",
            tab31: "Name der Person",
            tab32: "SPEICHERN",
            tab33: "Textdatei ",
            tab34: "Notenblatt ",
            tab35: "Länge",
            tab36: "Länge der Setliste",
            tab37: "Gesamt",
            tab38: "Dokumente",
            tab39: "Ausgehende Rechnung",
            tab40: "Eingehende Rechnung",
            tab41: "Dokumente Von Veranstaller",
            tab42: "Dokumente Booker",
            tab43: "Dokumente Techniker",
            tab44: "Zusatzliche Dokumente",
            tab45: "Fahrer",
            tab46: "Fahrzeug",
            tab47: "Auswählen",
            tab48: "SPEICHERN",
          },
          absent: {
            tab1: "Abwesenheit",
            tab2: "Abwesenheiten",
            tab3: "von",
            tab4: "bis",
            tab5: "Aktion",
            tab6: "Datum",
            tab7: "Kein Eintrag gefunden",
            tab8: "Abwesenheit eintragen ?",
            tab9: "ABBRECHEN",
            tab10: "BESTÄTIGEN",
          },
          setlist: {
            tab1: "Länge",
            tab2: "Setlisten drucken",
            tab3: "Anzahl Songs",
            tab4: "Alle",
            tab5: "Kein Eintrag gefunden",
            tab6: "Textdatei",
            tab7: "Notenblatt",
            tab8: "Drucken",
            tab9: "Name des Song ",
            tab10: "Länge",
            tab11: "Nummer",
          },
          songs: {
            tab1: "Urheberrecht",
            tab2: "Textdatei",
            tab3: "Notenblatt",
            tab4: "Kein Eintrag gefunden",
            tab5: "Alle",
            tab6: "Größe",
            tab7: "Aktion",
          },
          tasks: {
            tab1: "Neu",
            tab2: "In Bearbeitung",
            tab3: "Abgeschlossen",
            tab4: "Neuen Task erstellen",
            tab5: "Farbe auswählen",
            tab6: "Kein Eintrag gefunden",
            tab7: "Speichern",
            tab8: "CHECKLISTE",
            tab9: "Anhang",
            tab10: "Anhänge download",
            tab11: "Aktueller Status",
            tab12: "Erledigt",
            tab13: "IN BEARBEITUNG",
            tab14: "Keine Checkliste gefunden",
            tab15: "Möchtest du den Task wirklich löschen?",
            tab16: "Abbrechen",
            tab17: "LÖSCHEN",
          },
          profile: {
            tab1: "Profil",
            tab2: "Straße",
            tab3: "Hausnummer",
            tab4: "Postleitzahl",
            tab5: "Stadt",
            tab6: "Land",
            tab7: "Benachrichtigungen einstellen",
            tab8: "Ernährung",
            tab9: "Vegetarisch",
            tab10: "GEMA Mitgliedsnummer",
            tab11: "Freigabe",
            tab12: "Aktion",
            tab13: "Speichern",
            tab14: "Push Benachrichtigungen",
            tab15: "SMS Benachrichtigungen",
            tab16: "Email Benachrichtigungen",
            tab17: "Fleisch/Fisch",
            tab18: "Einstellungen Benachrichtigungen",
          },
          createband: {
            tab1: "Bandaccount erstellen",
          },
          setting: {
            tab1: "Profil",
            tab2: "Allgemeine Geschäftsbedinungen",
            tab3: "Datenschutz",
            tab4: "Impressum",
            tab5: "Passwort ändern",
            tab6: "Account löschen",
          },
          forms: {
            tab1: "Email Verifikation",
            tab2: "Bitte Code eingeben",
            tab3: "BESTÄTIGEN",
            tab4: "Code erneut senden",
            tab5: "Telefon Verifikation",
          },
          gema: {
            tab1: "Weil Deine Musik viel Wert ist",
            tab2: "Wir verschaffen dir Uberblick uber deine GEMA-Ausschuttungen, nehmen dir die Lasr der Abwicklung ab und optimieren deine Auszahlungssumme, wodurch unsere Kunstler in Schnitt 40% mehr Tantiemen erhalten",
            tab3: "Nutze Clever Bandassist fur deine Abwicklung",
            tab4: "Mehr Informationen erhalten",
          },
          chat: {
            tab1: "Alle Nachrichten",
            tab2: "Zu deinem Bandchat wechseln",
          },
        },
      },
    },
  });
