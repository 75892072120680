import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEventChatList } from '../../app_redux/slice/eventChatlist'
import { getEventChats } from '../../app_redux/slice/eventChat'
import { getTaskChatList } from '../../app_redux/slice/taskChatlist'

const ChatListItemsEvent = ({ setEventId, band, event, task, setTaskid,setChatName }) => {
    const dispatch = useDispatch()
    const { allEventChatList } = useSelector((state) => state.eventchatlist)


    useEffect(() => {

        dispatch(getEventChatList())
        dispatch(getTaskChatList())

    }, [])


    const handleChatClick = (eventid, name) => {
        setEventId(eventid)
        setChatName(name)
        dispatch(getEventChats({ eventid: eventid }))
        // setInterval(function () { dispatch(getEventChats({ eventid: eventid })) }, 3000)
    }

    

    return (
        <>

            {
                allEventChatList?.length > 0 ? allEventChatList?.map((res, i) => {
                    return <div className="chatList px-2">
                        <div className="d-flex align-items-center gap-2" onClick={() => handleChatClick(res.event_id, res.chat_name)}>
                            <div className="position-relative">
                                {/* <img src="assets/images/profile.png" alt="" className="chatProfile" /> */}
                                {/* <span className="activeUser"></span> */}
                            </div>
                            <div className="">
                                <h6 className="text-capitalize mb-0 notranslate">{res?.chat_name}</h6>
                                <p className="ellip-2 sortMsg notranslate">{res?.last_message}</p>
                            </div>
                        </div>
                      
                    </div>


                }) :  <p className='text-center'>No Chatlist Found</p>}
        </>


    )



}

export default ChatListItemsEvent