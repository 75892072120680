import * as Yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0)
export const markabsentSchema = Yup.object({

    startdate: Yup.date().nullable().required('required*')
        .min(today, "Date cannot be in the past"),
    enddate: Yup.date().nullable().required('required*')
        .min(today, "Date cannot be in the past"),
});