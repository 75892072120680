import React, { useEffect } from 'react'
import Slider from "react-slick";
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AbsentDetailModal from './absentDetails';
import { getEventnotification } from '../../app_redux/slice/eventnotification';
import { useDispatch } from 'react-redux';
import { getAllnotification } from '../../app_redux/slice/allnotificationcount';
import { useTranslation } from 'react-i18next'



const AppointmentGalleryView = ({ switchtoken }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const cookee = new Cookies();
    const lng = cookee.get('googtrans')
    const dispatch = useDispatch()
    const [events, setEvents] = useState([])
    const [confirmEvents, setConfirmEvents] = useState([])
    const [pastEvents, setPastEvents] = useState([])
    const [showCalendar, setShowCalendar] = useState(false)
    const [showAbsentDetailModal, setShowAbsentDetailModal] = useState(false)
    const [absentFrom, setAbsentFrom] = useState("")
    const [absentTo, setAbsentTo] = useState("")
    const token = cookee.get('tokenmusician')
    const firstname = cookee.get('firstnamemusician')
    const { tab1, tab2, tab3, tab4, tab5, tab6 } = t("appointments")

    useEffect(() => {
        if (switchtoken) {
            cookee.set('tokenmusician', switchtoken)
            setTimeout(getMusicianInfo, 5000)
        }
        // getMusicianInfo()
        // setInterval(getAllEvents,2000)
        getAllEvents()
        getConfirmEvents()
        getPastEvents()
        updatetasknotification()
    }, [])
    function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0],
            month = datePart[1], day = datePart[2];

        return day + '/' + month + '/' + year;
    }
    const handleCalendar = () => {
        setShowCalendar(!showCalendar)
    }
    const handleShowAbsentDetail = () => {
        setShowAbsentDetailModal(!showAbsentDetailModal)
    }
    const updatetasknotification = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/update-musician-notification-status`,
            data:
            {
                "notification_id": "",
                "user_type": 1,
                "is_read": 1,
                "notification_type": 1
            }
        })
            .then(res => {
                if (res.data.message === "Notification Status Updated Successfully.") {
                    dispatch(getEventnotification())
                    dispatch(getAllnotification())
                }
            })

    }

    const getMusicianInfo = () => {
        axios({
            method: 'get',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": switchtoken ? switchtoken : token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-profile`,
        })
            .then(res => {
                // console.log("RESPONSE", res.data.data)
                cookee.set('emailmusician', res.data.data.email)
                cookee.set('profilepicmusician', res.data.data.profile_pic)
                cookee.set('firstnamemusician', res.data.data.first_name)
                cookee.set('lastnamemusician', res.data.data.last_name)
                cookee.set('phonenumbermusician', res.data.data.phone_number)

            })
    }

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    var setting = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1, responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const getAllEvents = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": switchtoken ? switchtoken : token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-invited-events`,
            data:
            {
                "page_no": 1,
                "band_id": ""
            }
        })
            .then(res => {
                setEvents(res.data.data)
            })
    }

    const getConfirmEvents = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": switchtoken ? switchtoken : token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-confirmed-events`,
            data:
            {
                "page_no": 1,
                "band_id": ""
            }
        })
            .then(res => {
                setConfirmEvents(res.data.data)
            })
    }
    const getPastEvents = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": switchtoken ? switchtoken : token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-past-events`,
            data:
            {
                "page_no": 1,
                "band_id": ""
            }
        })
            .then(res => {
                setPastEvents(res.data.data)
            })
    }
    const handleEventClick = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-event-details`,
            data:
            {
                "event_id": id
            }
        })
            .then(res => {
                //console.log("RESPONSE", res.data)
                if (res.data.message === "Event Details!.") {
                    localStorage.setItem("eventdata", JSON.stringify(res.data.data))
                    navigate("/appointment-detail")
                }
            })
    }

    const handleEvent = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-event-details`,
            data:
            {
                "event_id": id
            }
        })
            .then(res => {
                //console.log("RESPONSE", res.data)
                if (res.data.message === "Event Details!.") {
                    localStorage.setItem("eventdatadetail", JSON.stringify(res.data.data))
                    localStorage.setItem("eventdatadetail2", JSON.stringify(res.data.data2))

                    navigate("/event-detail")
                }
            })
    }

    const handleChat = (id) => {
        navigate("/chat-event", { state: { eventid: id } })

    }
    return (
        <>


            <div>

                <div className="col-sm-12">
                    <div className="my-3">
                        <h3>{tab2} <img src="assets/images/InfoBlack.png" alt="" className="mx-1 mb-4" title={lng === "/auto/de" || "/en/de" ? "Hier finden Sie alle Einladungen zu Events von Ihren Bands, welche noch nicht bestätigt oder abgelehnt wurden. Klicken Sie auf das Event, um weitere Informationen einsehen zu können." : "Here you will find all invitations to events from your bands that have not yet been confirmed or declined. Click on the event to see more information."} /></h3>
                    </div>
                    <Slider {...setting}>
                        {events?.length !== 0 ? events.map((item, key) => {
                            return <div>
                                <div className="element element-1">
                                    <div className="position-relative">

                                        <img src="assets/images/Group265.png" className="sldrImg" alt="" onClick={() => handleEventClick(item.event_id)} style={{ cursor: "pointer" }} />

                                        {item.band_users_details.map((res) => {
                                            return <><span className="priceIcon"><img src="assets/images/priceIcon.png" className="" alt="" />{res.first_name === firstname ? res.amount : ""} </span>
                                                <h5 className="notranslate">{res.first_name === firstname ? res.role : ""}</h5>                                            </>
                                        })}
                                        {item.band_staff_details.map((res) => {
                                            return <><span className="priceIcon notranslate"><img src="assets/images/priceIcon.png" className="" alt="" />{res.first_name === firstname ? res.amount : ""} </span>
                                                <h5 className="notranslate">{res.first_name === firstname ? res.role : ""}</h5>
                                            </>
                                        })}
                                        <span className="d-flex chatDeadline">
                                            <img src="assets/images/chatWhite.png" className="" alt="" onClick={() => handleChat(item.event_id)} />
                                        </span>
                                    </div>
                                    <div className="BoxContentBg">
                                        <div className="eventTitleFlex text-capitalize">
                                            <span>
                                                <h6 className="mb-0 notranslate" >{item.event_name}</h6>
                                                <p className="mb-0 notranslate">{item.band_name}</p>
                                            </span>
                                            <span className="dateBg">
                                                {formatDate(item.start_date.substr(0, 10))}
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 text-capitalize mt-2" >
                                            <img src="assets/images/gpsRed.png" width="18px" alt="" />
                                            <span>{item.town}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 text-capitalize mt-2">
                                            <img src="assets/images/wall-clock.png" width="18px" alt="" />
                                            <span className="fw-bold notranslate">Get In {item.start_time?.substr(0, 5)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }) : <p>{tab3}</p>}
                    </Slider>

                </div>
                <div className="col-sm-12 mb-4">
                    <div className="my-3">
                        <h3>Events <img src="assets/images/InfoBlack.png" alt="" className="mx-1 mb-4" title={lng === "/auto/de" || "/en/de" ? "Hier finden Sie alle von Ihnen bestätigten Events. Klicken Sie auf das Event, um weitere Informationen einsehen zu können. Alle von der Band ergänzten Informationen, werden Ihnen in Real-Time angezeigt." : "Here you will find all the events you have confirmed. Click on the event to see more information. All information added by the band is shown to you in real time."} /></h3>
                    </div>

                    <Slider {...settings}>
                        {confirmEvents?.length !== 0 ? confirmEvents.map((item, key) => {
                            return <div>
                                <div className="element element-1">
                                    <div className="position-relative" >

                                        <img src="assets/images/Group265.png" className="sldrImg" alt="" onClick={() => handleEvent(item.event_id)} style={{ cursor: "pointer" }} />


                                        {item.band_users_details.map((res) => {
                                            return <><span className="priceIcon"><img src="assets/images/priceIcon.png" className="" alt="" />{res.first_name === firstname ? res.amount : ""} </span>
                                                <h5 className="notranslate">{res.first_name === firstname ? res.role : ""}</h5>                                            </>
                                        })}
                                        {item.band_staff_details.map((res) => {
                                            return <><span className="priceIcon"><img src="assets/images/priceIcon.png" className="" alt="" />{res.first_name === firstname ? res.amount : ""} </span>
                                                <h5 className="notranslate">{res.first_name === firstname ? res.role : ""}</h5>
                                            </>
                                        })}

                                        <span className="d-flex chatDeadline">
                                            {/* <img src="assets/images/deadline.png" className="" alt="" /> */}
                                            {item.is_confirmed === 1 ?
                                                <img src="assets/images/greendot.png" className="" alt="" style={{ height: "13px" }} /> : ""}
                                            <img src="assets/images/chatWhite.png" className="" alt="" onClick={() => handleChat(item.event_id)} />
                                        </span>
                                    </div>
                                    <div className="BoxContentBg">
                                        <div className="eventTitleFlex text-capitalize">
                                            <span>
                                                <h6 className="mb-0 notranslate">{item.event_name}</h6>
                                                <p className="mb-0 notranslate">{item.band_name}</p>
                                            </span>
                                            <span className="dateBg">
                                                {formatDate(item.start_date.substr(0, 10))}
                                            </span>

                                        </div>
                                        <div className="d-flex align-items-center gap-2 text-capitalize mt-2" >
                                            <img src="assets/images/gpsRed.png" width="18px" alt="" />
                                            <span className="notranslate">{item.town}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 text-capitalize mt-2">
                                            <img src="assets/images/wall-clock.png" width="18px" alt="" />
                                            <span className="fw-bold notranslate">Get In {item.start_time?.substr(0, 5)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }) : <p>{tab3}</p>}
                    </Slider>
                </div>
                <div className="col-sm-12 mb-4">
                    <div className="my-3">
                        <h3>{tab4} <img src="assets/images/InfoBlack.png" alt="" className="mx-1 mb-4" title={lng === "/auto/de" || "/en/de" ? "Hier finden Sie alle vergangenen Veranstaltungen." : "You can find all past events here"} /></h3>
                    </div>
                    <Slider {...settings}>
                        {pastEvents?.length !== 0 ? pastEvents.map((item, key) => {
                            return <div>
                                <div className="element element-1">
                                    <div className="position-relative" onClick={() => handleEventClick(item.event_id)} style={{ cursor: "pointer" }}>

                                        <img src="assets/images/Group265.png" className="sldrImg" alt="" />

                                        {item.band_users_details.map((res) => {
                                            return <><span className="priceIcon"><img src="assets/images/priceIcon.png" className="" alt="" />{res.first_name === firstname ? res.amount : ""} </span>
                                                <h5 className="notranslate">{res.first_name === firstname ? res.role : ""}</h5>                                            </>
                                        })}
                                        {item.band_staff_details.map((res) => {
                                            return <><span className="priceIcon notranslate"><img src="assets/images/priceIcon.png" className="" alt="" />{res.first_name === firstname ? res.amount : ""} </span>
                                                <h5 className="notranslate">{res.first_name === firstname ? res.role : ""}</h5>
                                            </>
                                        })}

                                    </div>
                                    <div className="BoxContentBg">
                                        <div className="eventTitleFlex text-capitalize">
                                            <span>
                                                <h6 className="mb-0 notranslate">{item.event_name}</h6>
                                                <p className="mb-0 notranslate">{item.band_name}</p>
                                            </span>
                                            <span className="dateBg">
                                                {formatDate(item.start_date.substr(0, 10))}
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 text-capitalize mt-2" >
                                            <img src="assets/images/gpsRed.png" width="18px" alt="" />
                                            <span>{item.town}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 text-capitalize mt-2">
                                            <img src="assets/images/wall-clock.png" width="18px" alt="" />
                                            <span className="fw-bold notranslate">{item.start_time}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }) : <p>{tab3}</p>}
                    </Slider>
                </div>
            </div>

            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            {showAbsentDetailModal ? <AbsentDetailModal show={showAbsentDetailModal} handleShow={handleShowAbsentDetail} date={absentFrom} date2={absentTo} /> : ""}
        </>
    )
}

export default AppointmentGalleryView