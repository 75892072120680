import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getAbsentData } from "../../app_redux/slice/absentData";

const ConfirmAbsentModal = ({
  show,
  handleShow,
  date,
  date2,
  tab8,
  tab9,
  tab10,
}) => {
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");

  const handleConfirm = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-mark-absence`,
      data: {
        from_absence_date: date,
        to_absence_date: date2,
        is_absence: 1,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleShow();
          dispatch(getAbsentData());
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="md">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="text-center mx-2">
                  <p>{tab8}</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                <div className=" text-center my-3 mt-5">
                  <button className="btn add-new-cancel" onClick={handleShow}>
                    {tab9}
                  </button>
                  <button className="btn add-new-del" onClick={handleConfirm}>
                    {tab10}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ConfirmAbsentModal;
