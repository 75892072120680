import React from 'react'
import { Modal } from 'react-bootstrap'
import Cookies from 'universal-cookie'
import { ToastContainer, toast } from 'react-toastify';


const ConfirmationModal = ({ show, handleShow, handleSubmit, bandName }) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    const lng = cookee.get("googtrans")



    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" centered >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className='col-sm-12 col-md-12 col-lg-12 mt-4'>
                                <h4 className='theme-color text-center'>{lng === "/auto/de" || "/en/de" ? "Mitgliedsanfrage versenden" : "Membership Request"}</h4>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <div className="text-center mx-2">
                                    <h5> {lng === "/auto/de" || "/en/de" ? `Möchtest du eine Mitgliedsanfrage an die Band ${bandName} versenden ?` : "info event"}</h5>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <div className=" text-center my-3 mt-5">
                                    <button className="btn add-new-cancel" onClick={handleShow}>{lng === "/auto/de" || "/en/de" ? "Nein" : "No"}</button>
                                    <button className="btn add-new-del" onClick={handleSubmit}>{lng === "/auto/de" || "/en/de" ? "Ja" : "Yes"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default ConfirmationModal