import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { getPassengers } from '../../app_redux/slice/passengers'
import DeletePassenger from './deletePassenger'
//import DeletePassenger from './deletePassenger'



const TransportDetail = ({ show, handleShow, transportId, setLoadsetupPersonList }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [passengerId, setPassengerId] = useState("")
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { passengers } = useSelector((state) => state.passenger)
    const userid = cookee.get("musicianuserid")


    useEffect(() => {
        dispatch(getPassengers({ id: transportId }));
    }, [])
    const handleShowDelete = () => {
        setShowDeleteModal(!showDeleteModal)
    }
    const handleDelete = (id) => {
        setPassengerId(id)
        handleShowDelete()

    }


    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <h4 className='mt-2 text-center'>Passengers</h4>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">name</th>
                                                    {/* <th scope="col">Action</th> */}
                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            <tbody>
                                                {passengers !== undefined && passengers.length !== 0 ? passengers?.map((res, key) => {
                                                    return <tr>
                                                        <td>{res.passenger_name}</td>
                                                        {/* <td>
                                                            {res.is_added_by_band === 0 && res.added_by == userid ?
                                                                <div className="d-flex gap-3 justify-content-center">
                                                                    <img src="assets/images/delete.png" alt="" onClick={() => handleDelete(res.transport_det_id)} />
                                                                </div> : ""}
                                                        </td> */}
                                                    </tr>
                                                }) : <p className='mt-5'>No Record Found!!</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            {showDeleteModal ? <DeletePassenger show={showDeleteModal} handleShow={handleShowDelete} passengerId={passengerId} transportId={transportId} /> : ""}
        </>
    )
}

export default TransportDetail;