import React from 'react'
import { Modal } from 'react-bootstrap'


const CheckList = ({ show, handleShow, id, setCheckList, checklist }) => {

    const handleAddChecklist = () => {
        setCheckList([...checklist, { check_list_item_name: "", checklist_item_information: "", status: "1", check_list_item_dead_line: "" }])
    }
    const handleInput = (e, i) => {
        const { name, value } = e.target
        const list = [...checklist]
        list[i][name] = value;
        setCheckList(list)
    }
    const handleRemove = (i, index) => {
        const list = [...checklist]
        list.splice(i, 1)
        setCheckList(list);
    }
    const handleSafe = () => {
        handleShow();
    }

    return (
        <Modal show={show} onHide={handleShow} size="lg" >
            <Modal.Body className="p-0">
                <div className="modal-body">
                    <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                    <h4 className='mt-2'>CheckList</h4>
                    {checklist.map((res, i) => {
                        return <div className="row">
                            <div className="col-sm-4 col-md-4 col-lg-4 mt-4">
                                <div className="d-flex align-items-center lg-justify-content-end mx-3">
                                    <input type="text" className="form-control p-0 ml-3" name="check_list_item_name" value={res.check_list_item_name} onChange={(e) => handleInput(e, i)} placeholder='Name' style={{ height: '40px', borderRadius: "10px" }} />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4 col-lg-4 mt-4">
                                <div className="d-flex align-items-center lg-justify-content-end mx-3">
                                    <input type="text" className="form-control p-0 ml-3" name="checklist_item_information" value={res.checklist_item_information} onChange={(e) => handleInput(e, i)} placeholder='Information' style={{ height: '40px', borderRadius: "10px" }} />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3 mt-4">
                                <div className="d-flex align-items-center lg-justify-content-end mx-3">
                                    <input type="date" className="form-control p-0 ml-3" name="check_list_item_dead_line" value={res.check_list_item_dead_line} placeholder='date' onChange={(e) => handleInput(e, i)} style={{ height: '40px', borderRadius: "10px" }} />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-1 col-lg-1 mt-4">
                                <div className="d-flex align-items-center lg-justify-content-end mx-3">
                                    <p onClick={() => handleRemove(i)}> <img src="assets/images/delete.png" width="20px" alt="" /></p>

                                </div>
                            </div>
                        </div>
                    })}

                </div>
                <div className="align-items-center my-3">
                    <button className="btn add-new" style={{ marginLeft: "160px",width:"150px",height:"40px",background:"green",border:"none" }} onClick={handleAddChecklist} >Add</button>
                </div>
                <div className="d-flex align-items-center lg-justify-content-end mx-3 mb-4">
                    <button className="btn add-new" style={{ marginLeft: "160px" }} onClick={handleSafe} >Safe</button>
                </div>


            </Modal.Body>
        </Modal>
    )
}

export default CheckList;