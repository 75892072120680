import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  var currentTime = new Date()
  var year = currentTime.getFullYear()

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 footer-links">
              {/* <h4><img src="assets/images/footerLogo.png" className="footerLogo" alt="" /></h4>
            <h5 className="text-uppercase mt-5 text-white">follow with us</h5>
            <div className="social-links mt-3">
              <a href="#" className=""><img src="assets/images/facebook-circular-logo.png" className="socialIcon" alt="" /></a>
              <a href="#" className=""><img src="assets/images/twitter.png" className="socialIcon" alt="" /></a>
              <a href="#" className=""><img src="assets/images/instagram.png" className="socialIcon" alt="" /></a>
              <a href="#" className=""><img src="assets/images/youtube.png" className="socialIcon" alt="" /></a>
            </div> */}
            </div>


            <div className="col-lg-5 col-md-6 footer-links">
              <h4 className="text-uppercase mt-3 text-white">features</h4>
              <p className="text-white">How do i do what? tutorials</p>
              <div className="position-relative">
                <img src="assets/images/tutorailImg.jpg" className="turouImg" alt="" />
                <img src="assets/images/play-button.png" className="playIcon" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer-contact">
              <h4 className="text-uppercase mt-3 text-white">help</h4>
              <p className="text-white">Your question could in the tutorial not be answered?</p>
              <h4 className="text-uppercase mt-5 text-white">contact us:</h4>
              <div className="position-relative ps5 text-white mb-4">
                <img src="assets/images/gps.png" className="contactIcon" alt="" /> Gabriel Spateneder
              </div>
              <div className="position-relative ps5 text-white mb-4">
                <a href="mailto:kontakt@clevermusik.com" target="_blank" className="text-white"><img src="assets/images/email.png" className="contactIcon" alt="" /> kontakt@clevermusik.com</a>
              </div>
              {/* <div className="position-relative ps5 text-white mb-2">
                <a href="tel:+49015120518445" className="text-white"><img src="assets/images/telephone-call.png" className="contactIcon" alt="" /> +49 (0) 151 - 20518445</a>
                </div> */}
            </div>


          </div>
          <div className="hr"></div>


          <div className="">
            <div className="copyright text-center text-white">
              Copyright @{year}, CleverMusik - Gabriel Spateneder -
              <Link to="/privacyPolicy"><u style={{ color: "#fff" }}> Datenschutz</u></Link>  - <Link to="/aboutUs"><u style={{ color: "#fff" }}>Impressum</u></Link>  - <Link to="/term&Condition"><u style={{ color: "#fff" }}>Allgemeine Geschäftsbedingungen</u></Link>
            </div>
          </div>

        </div>
      </div>

    </footer>
  )
}

export default Footer