import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const GemaMusician = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [gemaData, setGemaData] = useState("");
  const { tab1, tab2, tab3, tab4 } = t("gema");

  useEffect(() => {
    getGemaData();
  }, []);

  const getGemaData = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-gema-info`,
    }).then((res) => {
      // console.log("res",res.data)
      setGemaData(res.data.data[0].musician_gema_info);
    });
  };
  return (
    <article className="col-lg-9">
      <div className="position-relative ">
        <img
          src="assets/images/gemaBanner.png"
          className="img-fluid w-100"
          alt=""
        />
      </div>
      <div className="article-body store-bd-gy mt-0">
        <div className="gemaBox">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center mb-4">
              <h5 className="mb-0 notranslate mb-3">{tab1}</h5>
              <p className="mb-3">{tab2}</p>
              <h4 className="text-uppercase mb-3">{tab3}</h4>
              <Link to="/gema" className="btn add-new">
                {tab4}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default GemaMusician;
