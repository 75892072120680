import React from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getSelfTask } from "../../app_redux/slice/selfTask";

const PushNotificationModal = ({
  show,
  handleShow,
  getMusicianInfo,
  notification,
  sms,
  emailNotify,
  tab14,
  tab15,
  tab16,
  tab18,
}) => {
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");
  const lng = cookee.get("googtrans");

  const handleUpdateNotification = () => {
    axios({
      method: "patch",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/user-notification-turn-on-off`,
      data: {
        is_notification: notification === 1 ? 0 : 1,
        is_email: emailNotify,
        is_text_message: sms,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        getMusicianInfo();
        // handleShow()
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleUpdateEmail = () => {
    axios({
      method: "patch",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/user-notification-turn-on-off`,
      data: {
        is_notification: notification,
        is_email: emailNotify === 1 ? 0 : 1,
        is_text_message: sms,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        getMusicianInfo();
        // handleShow()
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleUpdateSms = () => {
    axios({
      method: "patch",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/user-notification-turn-on-off`,
      data: {
        is_notification: notification,
        is_email: emailNotify,
        is_text_message: sms === 1 ? 0 : 1,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        getMusicianInfo();
        // handleShow()
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="md" centered>
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="text-center mx-2">
                  <h5>{tab18}</h5>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                <div className=" text-center my-3 mt-5">
                  <p>
                    {tab14}{" "}
                    <img
                      src={
                        notification === 1
                          ? "assets/images/Toggleon.png"
                          : "assets/images/Toggleoff.png"
                      }
                      className="profileImg mx-3"
                      alt=""
                      onClick={handleUpdateNotification}
                    />
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className=" text-center my-3">
                  <p className="notranslate">
                    {tab16}{" "}
                    <img
                      src={
                        emailNotify === 1
                          ? "assets/images/Toggleon.png"
                          : "assets/images/Toggleoff.png"
                      }
                      className="profileImg mx-3"
                      alt=""
                      onClick={handleUpdateEmail}
                    />
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className=" text-center my-3">
                  <p>
                    {tab15}
                    <img
                      src={
                        sms === 1
                          ? "assets/images/Toggleon.png"
                          : "assets/images/Toggleoff.png"
                      }
                      className="profileImg mx-3"
                      alt=""
                      onClick={handleUpdateSms}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default PushNotificationModal;
