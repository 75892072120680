import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getEventChats } from '../../app_redux/slice/chatsEvents';
import Cookies from 'universal-cookie'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import S3FileUpload from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;



const ChatEvent = () => {
    const cookie = new Cookies();
    const dispatch = useDispatch()
    const location = useLocation();
    const { allEventChats, loading } = useSelector((state) => state.chatevents)
    const token = cookie.get('tokenmusician')
    const [messageInfo, setMessageInfo] = useState("")
    const [chatlistEvent, setChatlistEvent] = useState([])
    const [eventId, setEventId] = useState(location.state.eventid)
    const [image, setImage] = useState("")
    const [loading1, setLoading1] = useState(false)

    useEffect(() => {
        dispatch(getEventChats({ eventid: eventId }))
        handleChatListEvent()
        // setInterval(handleAutoRefresh, 5000)
    }, [])

    const handleAutoRefresh = () => {
        dispatch(getEventChats({ eventid: eventId }))
    }
    const onKeyPress = (e) => {
        if (e.which === 13) {
            handleSendMessage();
        }
    }

    const handleFile = (e) => {
        setLoading1(true)
        let file = e.target.files[0];
        const config = {
            bucketName: 'clever-band-assist-s3',
            region: 'eu-west-3',
            accessKeyId: 'AKIASTLKHDND66NQZXFJ',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                // console.log("RES", data)
                setImage(data.location)
                setLoading1(false)
            })
            .catch(err => console.error("ERR", err))
    }
    const handleChatListEvent = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-event-chatlist`,
            data:
            {
                "page_no": 1,
                "search_text": ""
            }
        })
            .then(res => {
                // console.log("res", res.data)
                setChatlistEvent(res.data.data)
            })
    }
    const handleMessageInfo = (e) => {
        setImage("")
        setMessageInfo(e.target.value)
    }
    const handleEventChat = (id) => {
        setEventId(id)
        dispatch(getEventChats({ eventid: id }))
    }

    const valid = () => {
        if (messageInfo.length === 0) {
            return false
        }
        else {
            return true
        }
    }
    const handleSendMessage = () => {
        if (valid()) {
            axios({
                method: 'post',
                headers: {
                    "Accept": "/",
                    "Content-Type": "application/json",
                    "auth_token": token
                },
                url: `${process.env.REACT_APP_URL}/send-message`,
                data:
                {
                    "task_id": -1,
                    "band_id": -1,
                    "event_id": eventId,
                    "user_type": 1,
                    "message": image ? "" : messageInfo,
                    "message_type": image ? "I" : "T",
                    "media_url": image
                }
            })
                .then(res => {
                    if (res.status ===
                        200) {
                        setMessageInfo("")
                        dispatch(getEventChats({ eventid: eventId }))
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.message, {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
        }
    }
    return (
        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <div className="bg-white">
                    <div className="row">

                        <div className="col-sm-12 col-md-12 col-lg-4 ledtBorder pe-0">

                            <div className="">
                                <p>Events Chats</p>
                            </div>

                            <hr />

                            {chatlistEvent.map((res, i) => {
                                const input = res.chat_name
                                return <div className="chatList px-2" onClick={() => handleEventChat(res.event_id)}>
                                    <div className="d-flex align-items-center gap-2">
                                        <div className="position-relative">
                                            <img src={res?.profile_pic ? res.profile_pic : "https://media-appsinvo.s3.amazonaws.com/redbgLogo.png"} alt="" className="chatProfile headerImgProfile" />
                                            {/* <span className="activeUser"></span> */}
                                        </div>
                                        <div className="">
                                            <h6 className="text-capitalize mb-0">{res.chat_name.substr(12, 18)}</h6>
                                            <p className="ellip-2 sortMsg">{res.band_name}</p>

                                            <p className="ellip-2 sortMsg">{res.last_message}</p>
                                        </div>
                                    </div>
                                    {/* <div className="text-center">
                                        <small className="white-space">{res?.last_message_send_receive_at?.substr(11, 12) || ""}</small>
                                    </div> */}
                                </div>
                            })}
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-8 ps-0 position-relative">
                            <h5 className="d-flex align-items-center justify-content-center theme-color px-2 pt3 mb-0">All Message</h5>
                            <hr />
                            <div className="messageChat ps-3">

                                {allEventChats.map((res, i) => {
                                    return <div className="notranslate">
                                        {/* {res.user_type === 2 ? <><img src={userinfo[0]?.profile_pic ? userinfo[0].profile_pic : "https://media-appsinvo.s3.amazonaws.com/image2.png"} alt="" className="chatProfile headerImgProfile chatImg" /> <b>{userinfo[0].band_name}</b></> : ""} */}
                                        {res.user_type === 2 ? <div className="chatText chatTextWidthWhite">
                                            <b style={{ textTransform: "uppercase" }} className='notranslate'>{res.band_name} </b>
                                            <p className='notranslate'>{res.message}</p>
                                            {res.media_url ? <a href={res.media_url} target='_blank'> <img className="mb-0 d-flex align-items-center gap-3 justify-content-end" src={res.media_url} alt="" style={{ width: "300px" }} /></a> : ""}
                                            <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">{res?.created_at
                                                ? new Date(
                                                    res?.created_at
                                                ).toLocaleString()
                                                : "-"} <img src="assets/images/blackDots.png" alt="" className="" /></p>
                                        </div> : ""}
                                        {res.user_type === 1 ? <>
                                            <div className={res.message_type
                                                === "T" ? "chatText chatTextWidthRed" : "chatText chatTextWidthImage"}>
                                                <b style={{ textTransform: "uppercase" }}>{res.first_name} {res.last_name}</b>
                                                <p className='mt-2 notranslate'>{res.message}</p>
                                                {res.media_url ? <img className="mb-0 d-flex align-items-center gap-3 justify-content-end" src={res.media_url} alt="" style={{ width: "300px" }} /> : ""}
                                                <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">{res?.created_at
                                                    ? new Date(
                                                        res?.created_at
                                                    ).toLocaleString()
                                                    : "-"} <img src="assets/images/whiteDots.png" alt="" className="" /></p>
                                            </div> </> : ""}

                                    </div>
                                })}
                                {loading1 ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ""}

                                <div className="ChatSendInput">
                                    <div className="position-relative">
                                        <input type="text" value={messageInfo} onKeyPress={onKeyPress} onChange={handleMessageInfo} className="form-control" placeholder="Write a message" />
                                        <div className="InputIconRight d-flex">
                                            <div className="upload-btn-wrapper Attachment" >
                                                <img src="assets/images/gallery.png" width="25px" alt="" />
                                                <input type="file" onChange={handleFile} />
                                            </div>
                                            <img src="assets/images/send-message.png" alt="" className="iconImages mx-2" onClick={handleSendMessage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </article>
    )
}

export default ChatEvent