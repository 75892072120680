import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getBandChatnotification = createAsyncThunk("bandchatcount", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let files =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-musician-unread-notification-count`,
            data: {
                "user_type": 1,
                "notification_type": "16"
            }
        })
            .then(res => res.data.data.count)
    return files
})

const bandChatCount = createSlice(({
    name: "event chat count",
    initialState: {
        bandchatcount: "",
        loading: false
    },
    extraReducers: {
        [getBandChatnotification.pending]: (state, action) => {
            state.loading = true;
        },
        [getBandChatnotification.fulfilled]: (state, action) => {
            state.bandchatcount = action.payload
        },
        [getBandChatnotification.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default bandChatCount.reducer;