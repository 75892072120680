import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import S3FileUpload from "react-s3";
import ReactAudioPlayer from "react-audio-player";
import AddPassengers from "./addPassenger";
import TransportDetail from "./transportDetails";
import DeleteTransport from "./deleteTransport";
import { useTranslation } from "react-i18next";
import { getEventDetail } from "../../app_redux/slice/eventDetail";

window.Buffer = window.Buffer || require("buffer").Buffer;

const EventDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const lng = cookee.get("googtrans");
  const [info, setInfo] = useState(true);
  const [band, setBand] = useState(false);
  const localData = JSON.parse(localStorage.getItem("eventdatadetail"));
  const localData2 = JSON.parse(localStorage.getItem("eventdatadetail2"));

  const [eventDetails, setEventDetails] = useState([]);
  const [transport, setTransport] = useState(false);
  const [setlist, setSetList] = useState(false);
  const [attachment, setAttachemnt] = useState(false);
  const [eventName, setEventName] = useState(localData[0]?.event_name || "");
  const [eventLocation, setEventLocation] = useState(localData[0]?.town || "");
  const [guestList, setGuestList] = useState(localData[0]?.guest_list || "");
  const [parking, setParking] = useState(localData[0]?.parking || "");
  const [catering, setCatering] = useState(localData[0]?.catering || "");
  const [eventOtherInfo, setEventOtherInfo] = useState(
    localData[0]?.event_other_info
  );
  const [sonitageInfo, setSonitageInfo] = useState(
    localData[0]?.sonstige_other_info || ""
  );
  const [eventCountry, setEventCountry] = useState("India");
  const [infoOrganizer, setInfoOrganizer] = useState(
    eventDetails[0]?.info_organizer || ""
  );
  const [infoAccommodation, setInfoAccommodation] = useState(
    localData[0]?.info_accommodation || ""
  );
  const [addBandStaff, setAddBandStaff] = useState(false);
  const [date, setDate] = useState("");
  const [role1, setRole1] = useState("");
  const [role2, setRole2] = useState("");
  const [role3, setRole3] = useState("");
  const [role4, setRole4] = useState("");
  const [bandPerson, setBandPerson] = useState([]);
  const [personInputId, setPersonId] = useState("");

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");

  const [getInStart, setGetInStart] = useState(
    localData[0]?.start_time?.substr(0, 5) || ""
  );
  const [getInEnd, setGetInEnd] = useState(
    localData[0]?.end_time?.substr(0, 5) || ""
  );
  const [loadStart, setLoadStart] = useState(
    localData[0]?.load_start_time?.substr(0, 5) || ""
  );
  const [loadEnd, setLoadEnd] = useState(
    localData[0]?.load_end_time?.substr(0, 5) || ""
  );
  const [soundStart, setSoundStart] = useState(
    localData[0]?.sound_start_time?.substr(0, 5) || ""
  );
  const [soundEnd, setSoundEnd] = useState(
    localData[0]?.sound_end_time?.substr(0, 5) || ""
  );

  const [stageStart, setStageStart] = useState(
    localData[0]?.stage_start_time?.substr(0, 5) || ""
  );
  const [stageEnd, setStageEnd] = useState(
    localData[0]?.stage_end_time?.substr(0, 5) || ""
  );
  const [locationName, setLocationName] = useState(
    localData[0]?.location_name || ""
  );
  const [locationStreet, setLocationStreet] = useState(
    localData[0]?.location_street || ""
  );

  const [locationPostal, setLocationPostal] = useState("");
  const [locationTown, setLocationTown] = useState(localData[0]?.town || "");
  const [locationContactPerson, setLocationContactPerson] = useState(
    eventDetails[0]?.loc_name_of_person || ""
  );
  const [locationEmail, setLocationEmail] = useState(
    eventDetails[0]?.location_email || ""
  );
  const [locationPhone, setLocationPhone] = useState(
    eventDetails[0]?.location_phone_number || ""
  );
  const [locationOtherInfo, setLocationOtherInfo] = useState(
    localData[0]?.location_other_information || ""
  );
  const [passenger, setPassenger] = useState([{ passenger_name: "" }]);
  const [setListData, setSetListData] = useState([]);
  const [priorityCount, setPriorityCount] = useState(2);

  const [accomodationId, setAccomodationId] = useState(
    eventDetails[0]?.info_accommodation[0]?.event_accomodation_id || ""
  );

  const [infoAccommodationName, setInfoAccommodationName] = useState(
    localData[0]?.info_accommodation[0]?.accomodation_name || ""
  );
  const [infoAccommodationStreet, setInfoAccommodationStreet] = useState(
    localData[0]?.info_accommodation[0]?.accomodation_street || ""
  );
  const [infoAccommodationPostal, setInfoAccommodationPostal] = useState(
    localData[0]?.info_accommodation[0]?.accomodation_postal || ""
  );
  const [infoAccommodationTown, setInfoAccommodationTown] = useState(
    localData[0]?.info_accommodation[0]?.accomodation_town || ""
  );
  const [infoAccommodationEmail, setInfoAccommodationEmail] = useState(
    localData[0]?.info_accommodation[0]?.accomodation_email || ""
  );
  const [infoAccommodationPhone, setInfoAccommodationPhone] = useState(
    localData[0]?.info_accommodation[0]?.accomodation_phone_number || ""
  );
  const [infoAccommodationCountry, setInfoAccommodationCountry] = useState(
    localData[0]?.info_accommodation[0]?.accomodation_country || ""
  );
  const [infoAccommodationOther, setInfoAccommodationOther] = useState(
    localData[0]?.info_accommodation[0]?.accomodation_other_information || ""
  );

  const [priority1, setPriority1] = useState("2");
  const [priority2, setPriority2] = useState("1");
  const [priority3, setPriority3] = useState("3");
  const [priority4, setPriority4] = useState("1");
  const [payment1, setPayment1] = useState("150 EURO");
  const [payment2, setPayment2] = useState("150 EURO");
  const [payment3, setPayment3] = useState("150 EURO");
  const [payment4, setPayment4] = useState("150 EURO");
  const [description1, setDescription1] = useState("Description ");
  const [description2, setDescription2] = useState("Description ");
  const [description3, setDescription3] = useState("Description ");
  const [description4, setDescription4] = useState("Description ");
  const [driver, setDriver] = useState("Driver");
  const [driver2, setDriver2] = useState("Driver");
  const [driver3, setDriver3] = useState("Driver");
  const [vehicle1, setVehicle1] = useState("Vehicle");
  const [vehicle2, setVehicle2] = useState("Vehicle");
  const [vehicle3, setVehicle3] = useState("Vehicle");
  const [availableSeats1, setAvailableSeats1] = useState("5");
  const [availableSeats2, setAvailableSeats2] = useState("5");
  const [availableSeats3, setAvailableSeats3] = useState("5");
  const [reserveSeats1, setReserveSeats1] = useState("4");
  const [reserveSeats2, setReserveSeats2] = useState("5");
  const [reserveSeats3, setReserveSeats3] = useState("5");
  const [song1, setSong1] = useState(
    eventDetails[0]?.set_list_details[0]?.song_name || ""
  );
  const [song2, setSong2] = useState(
    eventDetails[0]?.set_list_details[1]?.song_name || ""
  );
  const [song3, setSong3] = useState(
    eventDetails[0]?.set_list_details[2]?.song_name || ""
  );
  const [songTime1, setSongTime1] = useState(
    eventDetails[0]?.set_list_details[0]?.song_time || ""
  );
  const [songTime2, setSongTime2] = useState(
    eventDetails[0]?.set_list_details[1]?.song_time || ""
  );
  const [songTime3, setSongTime3] = useState(
    eventDetails[0]?.set_list_details[2]?.song_time || ""
  );
  const [file, setFile] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [showPerson, setShowPerson] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [personList, setPersonList] = useState([]);
  // const [bandStaffList, setBandStaffList] = useState([{ role: "", staff: [{ user_id: "", priority: "", amount: "", additional_information: "" }] }])
  const [bandStaffList, setBandStaffList] = useState([]);
  const [localPerson, setLocalPerson] = useState(
    localData[0]?.band_users_details || ""
  );
  const [localBandPerson, setLocalBandPerson] = useState(
    localData[0]?.band_staff_details || ""
  );
  const [localTransport, setLocalTransport] = useState(
    localData[0]?.transport_details || ""
  );
  const [localSetlist, setLocalSetlist] = useState(
    localData[0]?.set_list_details || ""
  );
  const [localAttachment, setLocalAttachment] = useState(
    eventDetails[0]?.attachment_details
  );
  const [driverList, setDriverList] = useState([
    { driver: "", vehicle: "", available_seats: "", passengers: [] },
  ]);
  const [eventType, setEventType] = useState(
    eventDetails[0]?.type_of_event || ""
  );
  const [performanceType, setPerformanceType] = useState(
    eventDetails[0]?.type_of_performance || ""
  );
  const [editRole, setEditRole] = useState("");
  const [bandmember, setBandMember] = useState([]);
  const [cancelledInvitation, setCancelledInvitation] = useState([]);
  const [showPassengerModal, setShowPassengerModal] = useState(false);
  const [showPassengerDetailModal, setShowPassengerDetailModal] =
    useState(false);
  const [transportDelete, setTransportDelete] = useState(false);
  const [transportId, setTransportId] = useState("");
  const [vehicle, setVehicle] = useState(false);
  const token = cookee.get("tokenmusician");
  const [attachmentData, setAttachmentData] = useState([]);
  const [userId, setUserId] = useState("");
  const { eventDetail, loading } = useSelector((state) => state.eventdetail);
  const [showdownload2, setShowdownload2] = useState(false);
  const userid = cookee.get("musicianuserid");
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [loader6, setLoader6] = useState(false);
  const {
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab12,
    tab13,
    tab14,
    tab15,
    tab16,
    tab17,
    tab18,
    tab19,
    tab20,
    tab21,
    tab22,
    tab23,
    tab24,
    tab25,
    tab26,
    tab27,
    tab29,
    tab30,
    tab31,
    tab32,
    tab33,
    tab34,
    tab35,
    tab36,
    tab37,
    tab38,
    tab39,
    tab40,
    tab41,
    tab42,
    tab43,
    tab44,
    tab45,
    tab46,
    tab47,
    tab48,
  } = t("appointments");

  useEffect(() => {
    getEvents();
    getBandMembers();
    getCancelledInvitations();
    getMusicianInfor();
    getAttachmentDetails();
    dispatch(getEventDetail({ eventid: localData[0]?.event_id }));
  }, []);
  useEffect(() => {
    setDate(formatDate(localData[0]?.start_date.substr(0, 10)));
  }, []);

  const getMusicianInfor = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-profile`,
    }).then((res) => {
      cookee.set("musicianuserid", res.data.data.user_id);
    });
  };

  const handleDownloadClick = (id) => {
    getAttachmentDetails(id);
    setShowdownload2(true);
  };
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2),
      month = datePart[1],
      day = datePart[2];

    return day + "/" + month + "/" + year;
  }
  const getMusicianInfo = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-profile`,
    }).then((res) => {
      setUserId(res.data.data.user_id);
    });
  };

  const getAttachmentDetails = (id) => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${
        process.env.REACT_APP_URL
      }/get-musician-event-attachment-details?event_id=${
        localData[0]?.event_id
      }&event_type=${1}`,
    }).then((res) => {
      // console.log(res.data)
      setAttachmentData(res.data.data.attachment_url);
    });
  };

  const handleAddVehicle = () => {
    setVehicle(true);
  };

  const handleDelete = (index) => {
    const list = [...passenger];
    list.splice(index, 1);
    setPassenger(list);
  };
  const handleShowPassenger = () => {
    setShowPassengerModal(!showPassengerModal);
  };
  const handleShowPassengerDetail = () => {
    setShowPassengerDetailModal(!showPassengerDetailModal);
  };
  const handleShowTransportDelete = () => {
    setTransportDelete(!transportDelete);
  };
  const handlePassengers = (id) => {
    setTransportId(id);
    handleShowPassenger();
  };
  const handlePassengersDetail = (id) => {
    setTransportId(id);
    handleShowPassengerDetail();
  };
  const handleDeletePassenger = (id) => {
    setTransportId(id);
    handleShowTransportDelete();
  };
  const handleFileUpload1 = (e) => {
    setLoader(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log("RES", data)
        setFile1(data.location);
        setLoader(false);
        handleAddAttachment(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleFileUpload2 = (e) => {
    setLoader2(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setFile2(data.location);
        setLoader2(false);
        handleAddAttachment2(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleFileUpload3 = (e) => {
    setLoader3(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setFile3(data.location);
        setLoader3(false);
        handleAddAttachment3(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };

  const handleFileUpload4 = (e) => {
    setLoader4(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setFile4(data.location);
        setLoader4(false);
        handleAddAttachment4(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleFileUpload5 = (e) => {
    setLoader5(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setFile5(data.location);
        setLoader5(false);
        handleAddAttachment5(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };

  const handleFileUpload6 = (e) => {
    setLoader6(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setFile6(data.location);
        setLoader6(false);
        handleAddAttachment6(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleInputChangePassenger = (e, index) => {
    const { name, value } = e.target;
    const list = [...passenger];
    list[index][name] = value;
    setPassenger(list);
  };
  const handleRemove = (index) => {
    const list = [...driverList];
    list.splice(index, 1);
    setDriverList(list);
  };
  const getEvents = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      data: {
        page_no: 10,
      },
      url: `${process.env.REACT_APP_URL}/get-all-musicians-list`,
    }).then((res) => {
      // console.log(res.data)
      setBandPerson(res.data.data);
    });
  };
  const getBandMembers = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandMember(res.data.data);
    });
  };
  const getCancelledInvitations = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-get-all-event-invitations-by-event-id`,
      data: {
        event_id: localData[0]?.event_id || "",
        is_accepted: 0,
        is_rejected: 1,
      },
    }).then((res) => {
      // console.log(res.data)
      setCancelledInvitation(res.data.data);
    });
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...driverList];
    list[index][name] = value;
    setDriverList(list);
  };

  const handleAddAttachment = (data) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-add-event-attachment`,
      data: {
        event_id: localData[0]?.event_id || "",
        event_type: 1,
        event_attachments: [
          {
            attachment_type: 1,
            attachment_url: data,
            size: "110kb",
          },
        ],
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.status === 200) {
          alert("Added!!");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleAddAttachment2 = (data) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-add-event-attachment`,
      data: {
        event_id: localData[0]?.event_id || "",
        event_type: 1,
        event_attachments: [
          {
            attachment_type: 2,
            attachment_url: data,
            size: "110kb",
          },
        ],
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.status === 200) {
          alert("Added!!");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleAddAttachment3 = (data) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-add-event-attachment`,
      data: {
        event_id: localData[0]?.event_id || "",
        event_type: 1,
        event_attachments: [
          {
            attachment_type: 3,
            attachment_url: data,
            size: "110kb",
          },
        ],
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.status === 200) {
          alert("Added!!");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleAddAttachment4 = (data) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-add-event-attachment`,
      data: {
        event_id: localData[0]?.event_id || "",
        event_type: 1,
        event_attachments: [
          {
            attachment_type: 4,
            attachment_url: data,
            size: "110kb",
          },
        ],
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.status === 200) {
          alert("Added!!");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleAddAttachment5 = (data) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-add-event-attachment`,
      data: {
        event_id: localData[0]?.event_id || "",
        event_type: 1,
        event_attachments: [
          {
            attachment_type: 5,
            attachment_url: data,
            size: "110kb",
          },
        ],
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.status === 200) {
          alert("Added!!");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleAddAttachment6 = (data) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-add-event-attachment`,
      data: {
        event_id: localData[0]?.event_id || "",
        event_type: 1,
        event_attachments: [
          {
            attachment_type: 7,
            attachment_url: data,
            size: "110kb",
          },
        ],
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.status === 200) {
          alert("Added!!");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleInputChangeLocal = (e, index) => {
    const { name, value } = e.target;
    const list = [...localTransport];
    list[index][name] = value;
    setLocalTransport(list);
  };
  const handleInputBand = (e, i, index) => {
    const { name, value } = e.target;
    const list = [...bandStaffList];
    list[i][name] = value;
    setBandStaffList(list);
  };
  const handleInputLocalBand = (e, i) => {
    const { name, value } = e.target;
    const old = localBandPerson[i];
    const updated = { ...old, [name]: value };
    const list = [...localBandPerson];
    list[i] = updated;
    setLocalBandPerson(list);
  };
  const handleAddClick = () => {
    setDriverList([
      ...driverList,
      { driver: "", vehicle: "", available_seats: "", passengers: [] },
    ]);
  };

  const handleAddPassenger = (i) => {
    // alert(i)
    setPersonId(i);
    // setDriverList([...driverList, { driver: "", vehicle: "", available_seats: "", passenger: passenger.length }])
    setPassenger([...passenger, { passenger_name: "" }]);
  };

  const handleAddTransport = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-add-transport-to-event`,
      data: {
        event_id: localData[0]?.event_id || "",
        event_type: 1,
        transport: driverList,
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.status === 200) {
          toast.success("Transport Added", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTransport(false);
          setBand(false);
          setInfo(false);
          setSetList(true);
          setAttachemnt(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const changeHandler = (value) => {
    setValue1(value);
  };
  const changeHandler1 = (value) => {
    setValue2(value);
  };
  const changeHandler2 = (value) => {
    setValue3(value);
  };
  const handleAddBandStaff = () => {
    setAddBandStaff(true);
  };

  const handleInfo = () => {
    setInfo(true);
    setBand(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
  };
  const handleBand = () => {
    setBand(true);
    setInfo(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
    getCancelledInvitations();
  };

  const handleEventInfo = (e) => {
    e.preventDefault();
    setBand(true);
    setInfo(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
  };

  const handleTransport = () => {
    setTransport(true);
    setBand(false);
    setInfo(false);
    setSetList(false);
    setAttachemnt(false);
  };
  const handleSetlist = () => {
    setSetList(true);
    setBand(false);
    setInfo(false);
    setTransport(false);
    setAttachemnt(false);
  };
  const handleAttachment = () => {
    setAttachemnt(true);
    setBand(false);
    setInfo(false);
    setTransport(false);
    setSetList(false);
  };
  const handleChange = (e, setstate) => {
    setstate(e.target.value);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-band-leader-event`,
      data: {
        event_id: eventDetails[0]?.event_id,
        event_name: eventName,
        info_organizer: infoOrganizer,
        info_accommodation: infoAccommodation,
        start_time: "14:00:00",
        start_date: date,
        latitude: 15,
        longitude: 109.77,
        town: eventLocation,
        event_country: eventCountry,
        event_type: 1,
        person: [
          {
            user_id: 11,
            role: role1,
            priority: priority1,
            amount: payment1,
            additional_information: description1,
          },
          {
            user_id: 11,
            role: role1,
            priority: priority1,
            amount: payment1,
            additional_information: description1,
          },
          {
            user_id: 11,
            role: role2,
            priority: priority2,
            amount: payment2,
            additional_information: description2,
          },
          {
            user_id: 11,
            role: role3,
            priority: priority3,
            amount: payment3,
            additional_information: description3,
          },
        ],
        band_staff: [
          {
            name: "abc",
            country_code: "+91",
            phone_number: 9897836762,
            email: "bhuppijeena4@gmail.com",
            role: "tabla",
            description: "abv",
          },
        ],

        transport: [
          {
            driver: driver,
            vehicle: vehicle1,
            available_seats: availableSeats1,
            reserved_seats: reserveSeats1,
          },
          {
            driver: driver2,
            vehicle: vehicle2,
            available_seats: availableSeats2,
            reserved_seats: reserveSeats2,
          },
          {
            driver: driver3,
            vehicle: vehicle3,
            available_seats: availableSeats3,
            reserved_seats: reserveSeats3,
          },
        ],

        set_list: [
          {
            song_name: song1,
            song_time: songTime1,
            gema_number: "XX2345",
            is_copyright: 1,
          },
          {
            song_name: song2,
            song_time: songTime2,
            gema_number: "XX2345",
            is_copyright: 1,
          },
          {
            song_name: song3,
            song_time: songTime3,
            gema_number: "XX2345",
            is_copyright: 1,
          },
        ],
        event_attachments: [
          {
            attachment_type: 1,
            attachment_url:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          },
        ],
      },
    }).then((res) => {
      // console.log("RESPONSE", res.data)
      alert("Updated!!");
      navigate("/events");
    });
  };
  const handleChat = () => {
    navigate("/chat-event", {
      state: { eventid: eventDetails[0]?.event_id || "" },
    });
  };
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3 align-items-center">
            <div className="col-12 col-sm-9 col-md-9 col-lg-10">
              <ul
                className="nav nav-pills group-nav tabNabs"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to="#"
                    className={info ? "nav-link  active" : "nav-link"}
                    onClick={handleInfo}
                  >
                    information{" "}
                    <img
                      src="assets/images/InfoBlack.png"
                      alt=""
                      className="mx-1 mb-4"
                      title={
                        lng === "/auto/de" || "/en/de"
                          ? "Hier finden Sie alle Informationen zu den Zeiten, Adressen, Übernachtung und sonstigen Informationen zu einer Veranstaltung, welche der Bandleader bereits eingetragen hat."
                          : "Here you will find all the information about the times, addresses, accommodation and other information about an event that the band leader has already entered."
                      }
                    />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className={band ? "nav-link  active" : "nav-link"}
                    onClick={handleBand}
                  >
                    band{" "}
                    <img
                      src="assets/images/InfoBlack.png"
                      alt=""
                      className="mx-1 mb-4"
                      title={
                        lng === "/auto/de" || "/en/de"
                          ? "Hier finden Sie alle Mitglieder der Band, welche zu dem Termin zugesagt haben."
                          : "Here you will find all members of the band who have agreed to the date."
                      }
                    />
                  </Link>
                </li>
                {localData[0]?.event_type === 1 ? (
                  <>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={transport ? "nav-link  active" : "nav-link"}
                        onClick={handleTransport}
                      >
                        transport{" "}
                        <img
                          src="assets/images/InfoBlack.png"
                          alt=""
                          className="mx-1 mb-4"
                          title={
                            lng === "/auto/de" || "/en/de"
                              ? "Hier können Sie sich als Fahrer anbieten, als Mitfahrer eintragen und die bereits eingetragenen Fahrzeuge und Fahrgemeinschaften einsehen"
                              : "Here you can offer yourself as a driver, register as a passenger and view the vehicles and car pools that have already been registered"
                          }
                        />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={setlist ? "nav-link  active" : "nav-link"}
                        onClick={handleSetlist}
                      >
                        setlist{" "}
                        <img
                          src="assets/images/InfoBlack.png"
                          alt=""
                          className="mx-1 mb-4"
                          title={
                            lng === "/auto/de" || "/en/de"
                              ? "Hier können Sie die Setliste für dieses Event einsehen"
                              : "You can see the set list for this event here"
                          }
                        />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={attachment ? "nav-link  active" : "nav-link"}
                        onClick={handleAttachment}
                      >
                        {tab19}{" "}
                        <img
                          src="assets/images/InfoBlack.png"
                          alt=""
                          className="mx-1 mb-4"
                          title={
                            lng === "/auto/de" || "/en/de"
                              ? "Hier können Sie, soweit Sie hierfür von der Band die Freigabe erteilt bekommen haben, Anhänge hoch- und/oder runterladen."
                              : "Here you can upload and/or download attachments, as long as the band has given you permission to do so"
                          }
                        />
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>

            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
              <div class="d-flex align-items-center lg-justify-content-end">
                {/* <button class="btn notiIcon filterIcon"><img src="assets/images/calendarGrey.png" alt="" />
                                </button> */}
                {/* <button className="btn notiIcon filterIcon" onClick={handleChat}><img src="assets/images/chatBlackRed.png" alt="" />
                                </button> */}
              </div>
            </div>
            {info ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable infoEventTable">
                      <thead className="">
                        <tr>
                          <th scope="col">
                            {lng === "/auto/de" || "/en/de"
                              ? "Info-Veranstaltung"
                              : "info event"}{" "}
                          </th>
                          <th scope="col">info location</th>
                          {localData[0]?.event_type === 1 ? (
                            <th scope="col">info accommodation</th>
                          ) : (
                            ""
                          )}
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group mb-3">
                              <div className="input-container">
                                <input
                                  type="text"
                                  value={eventName}
                                  className="form-control p-0"
                                  placeholder="Name"
                                  name="confirmpassword"
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <div className="input-container">
                                <input
                                  type="text"
                                  value={date}
                                  className="form-control p-0"
                                  placeholder="Name"
                                  name="confirmpassword"
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="notranslate">
                                Get in :
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={getInStart}
                                  placeholder="start"
                                  style={{
                                    height: "40px",
                                    width: "100px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              {localData[0]?.event_type !== 1 ? (
                                <p>
                                  End:
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    value={getInEnd}
                                    placeholder="start"
                                    style={{
                                      height: "40px",
                                      width: "100px",
                                      cursor: " context-menu",
                                    }}
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                            </div>

                            {localData[0]?.event_type !== 1 ? (
                              ""
                            ) : (
                              <>
                                <p className="notranslate">
                                  Load :{" "}
                                  <div className="d-flex justify-content-between">
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      value={loadStart}
                                      placeholder="start"
                                      style={{
                                        height: "40px",
                                        width: "100px",
                                        cursor: " context-menu",
                                      }}
                                    />{" "}
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      value={loadEnd}
                                      placeholder="end"
                                      style={{
                                        height: "40px",
                                        width: "100px",
                                        cursor: " context-menu",
                                      }}
                                    />
                                  </div>
                                </p>

                                <p className="notranslate">
                                  Soundcheck:{" "}
                                  <div className="d-flex justify-content-between">
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      value={soundStart}
                                      placeholder="start"
                                      style={{
                                        height: "40px",
                                        width: "100px",
                                        cursor: " context-menu",
                                      }}
                                    />{" "}
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      value={soundEnd}
                                      placeholder="end"
                                      style={{
                                        height: "40px",
                                        width: "100px",
                                        cursor: " context-menu",
                                      }}
                                    />
                                  </div>
                                </p>

                                <p className="notranslate">
                                  Stagetime:{" "}
                                  <div className="d-flex justify-content-between">
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      value={stageStart}
                                      placeholder="start"
                                      style={{
                                        height: "40px",
                                        width: "100px",
                                        cursor: " context-menu",
                                      }}
                                    />{" "}
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      value={stageEnd}
                                      onChange={(e) =>
                                        handleChange(e, setStageEnd)
                                      }
                                      placeholder="end"
                                      style={{
                                        height: "40px",
                                        width: "100px",
                                        cursor: " context-menu",
                                      }}
                                    />
                                  </div>
                                </p>
                              </>
                            )}

                            <p>
                              <textarea
                                className="form-control p-0"
                                placeholder={tab11}
                                value={eventOtherInfo}
                                readOnly
                                style={{
                                  height: "100px",
                                  cursor: " context-menu",
                                }}
                              />
                            </p>
                          </td>
                          <td>
                            <p>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationName}
                                placeholder="Name"
                                style={{
                                  height: "40px",
                                  cursor: " context-menu",
                                }}
                              />
                            </p>
                            <p>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationStreet}
                                placeholder={tab20}
                                style={{
                                  height: "40px",
                                  cursor: " context-menu",
                                }}
                              />
                            </p>

                            <p>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={localData[0]?.event_zip_code}
                                placeholder={tab8}
                                style={{
                                  height: "40px",
                                  cursor: " context-menu",
                                }}
                              />{" "}
                            </p>
                            <p>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationTown}
                                placeholder={tab18}
                                style={{
                                  height: "40px",
                                  cursor: " context-menu",
                                }}
                              />
                            </p>

                            <p>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={localData[0]?.event_country}
                                placeholder={tab10}
                                style={{
                                  height: "40px",
                                  cursor: " context-menu",
                                }}
                              />
                            </p>
                            {localData[0]?.event_type !== 1 ? (
                              ""
                            ) : (
                              <p>
                                <textarea
                                  className="form-control p-0"
                                  value={locationOtherInfo}
                                  placeholder={tab11}
                                  style={{
                                    height: "150px",
                                    cursor: " context-menu",
                                  }}
                                  readOnly
                                />
                              </p>
                            )}
                          </td>
                          {localData[0]?.event_type === 1 ? (
                            <td>
                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={infoAccommodationName}
                                  placeholder="Name"
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={infoAccommodationStreet}
                                  placeholder={tab20}
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={infoAccommodationPostal}
                                  placeholder={tab8}
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />{" "}
                              </p>
                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={infoAccommodationTown}
                                  placeholder={tab18}
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={infoAccommodationCountry}
                                  placeholder={tab10}
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>

                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={infoAccommodationPhone}
                                  placeholder="Phone"
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={infoAccommodationEmail}
                                  placeholder="Email"
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p>
                                <textarea
                                  className="form-control p-0"
                                  value={infoAccommodationOther}
                                  placeholder={tab11}
                                  style={{
                                    height: "80px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p>
                                <b>sonstige info</b>
                              </p>
                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={parking}
                                  placeholder={tab12}
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p>
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={guestList}
                                  placeholder={tab13}
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p className="notranslate">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={catering}
                                  placeholder="Catering"
                                  style={{
                                    height: "40px",
                                    cursor: " context-menu",
                                  }}
                                />
                              </p>
                              <p>
                                <textarea
                                  className="form-control p-0"
                                  placeholder={tab11}
                                  value={sonitageInfo}
                                  style={{
                                    height: "80px",
                                    cursor: " context-menu",
                                  }}
                                  readOnly
                                />
                              </p>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <div className="d-flex justify-content-end my-3">
                                    <button className="btn add-new" onClick={handleEventInfo}>done</button>
                                </div> */}
              </div>
            ) : (
              ""
            )}

            {band ? (
              <>
                <div className="col-sm-12 col-lg-4 col-md-4">
                  <div className="pt-3 px-1">
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th>{tab21}</th>
                            <th>Person</th>
                          </tr>
                          {/* <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr> */}
                        </thead>
                        <tbody>
                          {localPerson?.length !== 0 ? (
                            localPerson.map((res, i) => {
                              return (
                                <tr>
                                  {res.is_accepted === "1" ||
                                  res.is_rejected === "1" ? (
                                    <>
                                      <td className="notranslate">
                                        {res.role}
                                      </td>
                                      <td className="notranslate">
                                        {res.first_name} {res.last_name}
                                      </td>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <p>{tab23}</p>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 align-items-center">
                  <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                    <ul
                      className="nav nav-pills group-nav tabNabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a className="nav-link active">{tab22}</a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-12 col-lg-4 col-md-4">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable">
                          <thead className="">
                            <tr>
                              <th>role</th>
                              <th>person</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {localBandPerson?.length !== 0 ? (
                              localBandPerson.map((res, i) => {
                                return (
                                  <tr>
                                    {res.is_accepted === "1" ||
                                    res.is_rejected === "1" ? (
                                      <>
                                        <td className="notranslate">
                                          {res.role}
                                        </td>
                                        <td className="notranslate">
                                          {res.first_name} {res.last_name}
                                        </td>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <p>{tab23}</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 align-items-center">
                  {/* <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                    <ul
                      className="nav nav-pills group-nav tabNabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a className="nav-link active">Cancelled Invitations</a>
                      </li>
                    </ul>
                  </div> */}

                  {/* <div className="col-sm-12 col-lg-4 col-md-4">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable">
                          <thead className="">
                            <tr>
                              <th scope="col">role</th>
                              <th scope="col">person</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {cancelledInvitation.map((res, i) => {
                              return (
                                <tr>
                                  <td className="white-space tdWidth notranslate">
                                    {res.role}
                                  </td>
                                  <td className="white-space tdWidth notranslate">
                                    {res.first_name} {res.last_name}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}
                </div>
              </>
            ) : (
              ""
            )}

            {transport ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable">
                      <thead className="">
                        <tr>
                          <th scope="col">{tab24}</th>
                          <th scope="col">{tab25}</th>
                          <th scope="col">{tab26}</th>
                          <th scope="col">{tab27}</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {eventDetail[0]?.transport_details?.length !== 0
                          ? eventDetail[0]?.transport_details.map((res, i) => {
                              return (
                                <tr>
                                  <td>
                                    {" "}
                                    <p>
                                      <input
                                        type="text"
                                        className="form-control p-0"
                                        name="driver"
                                        value={res.driver}
                                        onChange={(e) =>
                                          handleInputChangeLocal(e, i)
                                        }
                                        placeholder={tab45}
                                        style={{
                                          height: "40px",
                                          border: "none",
                                        }}
                                      />
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <input
                                        type="text"
                                        className="form-control p-0"
                                        name="vehicle"
                                        value={res.vehicle}
                                        placeholder={tab46}
                                        style={{
                                          height: "40px",
                                          border: "none",
                                        }}
                                      />
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <input
                                        type="text"
                                        className="form-control p-0"
                                        name="available_seats"
                                        value={res.available_seats}
                                        placeholder="Available seats"
                                        style={{
                                          height: "40px",
                                          border: "none",
                                        }}
                                      />
                                    </p>
                                  </td>

                                  <td>
                                    <img
                                      src="assets/images/user-profile.png"
                                      className="mx-2"
                                      alt=""
                                      onClick={() =>
                                        handlePassengersDetail(res.transport_id)
                                      }
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        cursor: "pointer",
                                      }}
                                    />
                                    {res.available_seats === "0" ? (
                                      ""
                                    ) : (
                                      <button
                                        className="btn add-new"
                                        onClick={() =>
                                          handlePassengers(res.transport_id)
                                        }
                                      >
                                        {" "}
                                        Add
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : ""}

                        {vehicle ? (
                          driverList.map((res, i) => {
                            return (
                              <tr>
                                {" "}
                                <td className="mx-4">
                                  {driverList.length !== 1 && (
                                    <img
                                      src="assets/images/delete.png"
                                      alt=""
                                      onClick={() => handleRemove(i)}
                                    />
                                  )}

                                  {driverList.length - 1 === i && (
                                    <img
                                      src="assets/images/moregray.png"
                                      alt=""
                                      onClick={handleAddClick}
                                    />
                                  )}
                                  <p>
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      name="driver"
                                      value={res.driver}
                                      onChange={(e) => handleInputChange(e, i)}
                                      placeholder={tab45}
                                      style={{ height: "40px" }}
                                    />
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    <input
                                      type="text"
                                      className="form-control p-0 mt-3"
                                      name="vehicle"
                                      value={res.vehicle}
                                      onChange={(e) => handleInputChange(e, i)}
                                      placeholder={tab46}
                                      style={{ height: "40px" }}
                                    />
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {/* <input type="text" className="form-control p-0 mt-3" name="available_seats" value={res.available_seats} onChange={(e) => handleInputChange(e, i)} placeholder="Available seats" style={{ height: "40px" }} /> */}

                                    <select
                                      className="form-control p-0 mt-2"
                                      name="available_seats"
                                      onChange={(e) => handleInputChange(e, i)}
                                      style={{ height: "40px" }}
                                    >
                                      <option>{tab47}</option>
                                      <option values="1">1</option>
                                      <option values="2">2</option>
                                      <option values="3">3</option>
                                      <option values="4">4</option>
                                      <option values="5">5</option>
                                      <option values="6">6</option>
                                      <option values="7">7</option>
                                      <option values="8">8</option>
                                      <option values="9">9</option>
                                      <option values="10">10</option>
                                      <option values="11">11</option>
                                      <option values="12">12</option>
                                      <option values="13">13</option>
                                      <option values="14">14</option>
                                      <option values="15">15</option>
                                      <option values="16">16</option>
                                    </select>
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <button
                            className="btn add-new mt-3"
                            onClick={handleAddVehicle}
                          >
                            {tab29}
                          </button>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-end my-3">
                  <button className="btn add-new" onClick={handleAddTransport}>
                    {tab48}
                  </button>
                  {/* <button className="btn add-new" onClick={handleUpdateTransport}>update</button> */}
                </div>
              </div>
            ) : (
              ""
            )}

            {setlist ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <h4>{localData[0]?.set_list_name}</h4>
                  <div className="table-responsive ">
                    <table className="table storetable">
                      <thead className="">
                        <tr>
                          <th scope="col">name</th>
                          <th scope="col">bpm</th>
                          <th scope="col">{tab33}</th>
                          <th scope="col">{tab34}</th>
                          <th scope="col">{tab35}</th>
                          <th scope="col">audio</th>
                        </tr>
                      </thead>
                      <tbody>
                        {localSetlist?.length !== 0 ? (
                          localSetlist.map((res, i) => {
                            return (
                              <tr>
                                <td>
                                  <h6>{res.song_name}</h6>
                                </td>
                                <td>{res.bpm}</td>
                                <td>
                                  <div className="d-flex gap-3 align-items-center justify-content-center">
                                    <a
                                      href={res.text_sheet_url}
                                      target="_blank"
                                      download={res.text_sheet_url}
                                    >
                                      <img
                                        src="assets/images/invoice.png"
                                        width="25px"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex gap-3 align-items-center justify-content-center">
                                    <a
                                      href={res.music_sheet_url}
                                      target="_blank"
                                      download={res.music_sheet_url}
                                    >
                                      <img
                                        src="assets/images/live-music-sheet.png"
                                        width="25px"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </td>
                                <td>{res.time_length}</td>
                                <td>
                                  <ReactAudioPlayer
                                    src={res.song_url}
                                    controls
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p>No Record Found!</p>
                        )}

                        <tr>
                          <td className="bg-td">{tab37}</td>
                          <td className="bg-td">
                            {tab36}: {localData2?.total_length || ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <div className="d-flex justify-content-end my-3">
                                <button className="btn"><img src="assets/images/printing.png" alt="" /></button>
                            </div> */}
              </div>
            ) : (
              ""
            )}

            {attachment ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="pt-3 px-1 bg-white">
                        <div className="table-responsive">
                          <table className="table storetable text-center">
                            <thead className="">
                              <tr>
                                <th scope="col">{tab38}</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                              <tr className="border-0 bg-none invisible">
                                <td className="border-0 bg-none"></td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{tab39}</td>
                                {localData[0]?.attachment_permission_details
                                  .length !== 0
                                  ? localData[0]?.attachment_permission_details.map(
                                      (res) => {
                                        return (
                                          <>
                                            {res.attachment_type === "1" &&
                                            res.attachment_permission_type_upload ===
                                              "1" &&
                                            res.user_id === userid ? (
                                              <td className="">
                                                {loader ? (
                                                  <div
                                                    className="spinner-border"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div className="upload-btn-wrapper Attachment">
                                                    <button className="btn">
                                                      upload file
                                                    </button>
                                                    <input
                                                      type="file"
                                                      onChange={
                                                        handleFileUpload1
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </td>
                                            ) : (
                                              ""
                                            )}

                                            {res.attachment_type === "1" &&
                                            res.attachment_permission_type_view ===
                                              "1" &&
                                            res.user_id === userid
                                              ? localData[0]?.attachment_details.map(
                                                  (item, i) => {
                                                    return (
                                                      <>
                                                        {item.attachment_type ===
                                                        "1" ? (
                                                          <td className="d-flex gap-3 justify-content-center">
                                                            <a
                                                              href={
                                                                item.attachment_url
                                                              }
                                                              target="_blank"
                                                              download="#"
                                                            >
                                                              <img
                                                                src="assets/images/live-file.png"
                                                                width="25px"
                                                                alt=""
                                                              />
                                                            </a>{" "}
                                                          </td>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </>
                                        );
                                      }
                                    )
                                  : ""}
                              </tr>
                              <tr>
                                <td>{tab40}</td>
                                {localData[0]?.attachment_permission_details
                                  .length !== 0
                                  ? localData[0]?.attachment_permission_details.map(
                                      (res) => {
                                        return (
                                          <>
                                            {res.attachment_type === "2" &&
                                            res.attachment_permission_type_upload ===
                                              "1" &&
                                            res.user_id === userid ? (
                                              <td className="">
                                                {loader2 ? (
                                                  <div
                                                    className="spinner-border"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div className="upload-btn-wrapper Attachment">
                                                    <button className="btn">
                                                      upload file
                                                    </button>
                                                    <input
                                                      type="file"
                                                      onChange={
                                                        handleFileUpload2
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                            {res.attachment_type === "2" &&
                                            res.attachment_permission_type_view ===
                                              "1" &&
                                            res.user_id === userid
                                              ? localData[0]?.attachment_details.map(
                                                  (item, i) => {
                                                    return (
                                                      <>
                                                        {item.attachment_type ===
                                                        "2" ? (
                                                          <td className="d-flex gap-3 justify-content-center">
                                                            <a
                                                              href={
                                                                item.attachment_url
                                                              }
                                                              target="_blank"
                                                              download="#"
                                                            >
                                                              <img
                                                                src="assets/images/live-file.png"
                                                                width="25px"
                                                                alt=""
                                                              />
                                                            </a>{" "}
                                                          </td>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </>
                                        );
                                      }
                                    )
                                  : ""}
                              </tr>
                              <tr>
                                <td>{tab41}</td>
                                {localData[0]?.attachment_permission_details
                                  .length !== 0
                                  ? localData[0]?.attachment_permission_details.map(
                                      (res) => {
                                        return (
                                          <>
                                            {res.attachment_type === "3" &&
                                            res.attachment_permission_type_upload ===
                                              "1" &&
                                            res.user_id === userid ? (
                                              <td className="">
                                                {loader3 ? (
                                                  <div
                                                    className="spinner-border"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div className="upload-btn-wrapper Attachment">
                                                    <button className="btn">
                                                      upload file
                                                    </button>
                                                    <input
                                                      type="file"
                                                      onChange={
                                                        handleFileUpload3
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                            {res.attachment_type === "3" &&
                                            res.attachment_permission_type_view ===
                                              "1" &&
                                            res.user_id === userid
                                              ? localData[0]?.attachment_details.map(
                                                  (item, i) => {
                                                    return (
                                                      <>
                                                        {item.attachment_type ===
                                                        "3" ? (
                                                          <td className="d-flex gap-3 justify-content-center">
                                                            <a
                                                              href={
                                                                item.attachment_url
                                                              }
                                                              target="_blank"
                                                              download="#"
                                                            >
                                                              <img
                                                                src="assets/images/live-file.png"
                                                                width="25px"
                                                                alt=""
                                                              />
                                                            </a>{" "}
                                                          </td>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </>
                                        );
                                      }
                                    )
                                  : ""}
                              </tr>
                              <tr>
                                <td>{tab42}</td>
                                {localData[0]?.attachment_permission_details
                                  .length !== 0
                                  ? localData[0]?.attachment_permission_details.map(
                                      (res) => {
                                        return (
                                          <>
                                            {res.attachment_type === "4" &&
                                            res.attachment_permission_type_upload ===
                                              "1" &&
                                            res.user_id === userid ? (
                                              <td className="">
                                                {loader4 ? (
                                                  <div
                                                    className="spinner-border"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div className="upload-btn-wrapper Attachment">
                                                    <button className="btn">
                                                      upload file
                                                    </button>
                                                    <input
                                                      type="file"
                                                      onChange={
                                                        handleFileUpload4
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                            {res.attachment_type === "4" &&
                                            res.attachment_permission_type_view ===
                                              "1" &&
                                            res.user_id === userid
                                              ? localData[0]?.attachment_details.map(
                                                  (item, i) => {
                                                    return (
                                                      <>
                                                        {item.attachment_type ===
                                                        "4" ? (
                                                          <td className="d-flex gap-3 justify-content-center">
                                                            <a
                                                              href={
                                                                item.attachment_url
                                                              }
                                                              target="_blank"
                                                              download="#"
                                                            >
                                                              <img
                                                                src="assets/images/live-file.png"
                                                                width="25px"
                                                                alt=""
                                                              />
                                                            </a>{" "}
                                                          </td>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </>
                                        );
                                      }
                                    )
                                  : ""}
                              </tr>
                              <tr>
                                <td>{tab43}</td>
                                {localData[0]?.attachment_permission_details
                                  .length !== 0
                                  ? localData[0]?.attachment_permission_details.map(
                                      (res) => {
                                        return (
                                          <>
                                            {res.attachment_type === "5" &&
                                            res.attachment_permission_type_upload ===
                                              "1" &&
                                            res.user_id === userid ? (
                                              <td className="">
                                                {loader5 ? (
                                                  <div
                                                    className="spinner-border"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div className="upload-btn-wrapper Attachment">
                                                    <button className="btn">
                                                      upload file
                                                    </button>
                                                    <input
                                                      type="file"
                                                      onChange={
                                                        handleFileUpload5
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </td>
                                            ) : (
                                              ""
                                            )}

                                            {res.attachment_type === "5" &&
                                            res.attachment_permission_type_view ===
                                              "1" &&
                                            res.user_id === userid
                                              ? localData[0]?.attachment_details.map(
                                                  (item, i) => {
                                                    return (
                                                      <>
                                                        {item.attachment_type ===
                                                        "5" ? (
                                                          <td className="d-flex gap-3 justify-content-center">
                                                            <a
                                                              href={
                                                                item.attachment_url
                                                              }
                                                              target="_blank"
                                                              download="#"
                                                            >
                                                              <img
                                                                src="assets/images/live-file.png"
                                                                width="25px"
                                                                alt=""
                                                              />
                                                            </a>{" "}
                                                          </td>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </>
                                        );
                                      }
                                    )
                                  : ""}
                              </tr>
                              <tr>
                                <td>{tab44}</td>
                                {localData[0]?.attachment_permission_details
                                  .length !== 0
                                  ? localData[0]?.attachment_permission_details.map(
                                      (res) => {
                                        return (
                                          <>
                                            {res.attachment_type === "7" &&
                                            res.attachment_permission_type_upload ===
                                              "1" &&
                                            res.user_id === userid ? (
                                              <td className="">
                                                {loader6 ? (
                                                  <div
                                                    className="spinner-border"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div className="upload-btn-wrapper Attachment">
                                                    <button className="btn">
                                                      upload file
                                                    </button>
                                                    <input
                                                      type="file"
                                                      onChange={
                                                        handleFileUpload6
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                            {res.attachment_type === "7" &&
                                            res.attachment_permission_type_view ===
                                              "1" &&
                                            res.user_id === userid
                                              ? localData[0]?.attachment_details.map(
                                                  (item, i) => {
                                                    return (
                                                      <>
                                                        {item.attachment_type ===
                                                        "7" ? (
                                                          <td className="d-flex gap-3 justify-content-center">
                                                            <a
                                                              href={
                                                                item.attachment_url
                                                              }
                                                              target="_blank"
                                                              download="#"
                                                            >
                                                              <img
                                                                src="assets/images/live-file.png"
                                                                width="25px"
                                                                alt=""
                                                              />
                                                            </a>{" "}
                                                          </td>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </>
                                        );
                                      }
                                    )
                                  : ""}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
      {showPassengerModal ? (
        <AddPassengers
          show={showPassengerModal}
          handleShow={handleShowPassenger}
          driverList={driverList}
          transportId={transportId}
          eventid={localData[0]?.event_id}
          tab30={tab30}
          tab31={tab31}
          tab32={tab32}
        />
      ) : (
        ""
      )}
      {showPassengerDetailModal ? (
        <TransportDetail
          show={showPassengerDetailModal}
          handleShow={handleShowPassengerDetail}
          transportId={transportId}
        />
      ) : (
        ""
      )}
      {transportDelete ? (
        <DeleteTransport
          show={transportDelete}
          handleShow={handleShowTransportDelete}
          transportId={transportId}
          eventId={localData[0]?.event_id || ""}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default EventDetails;
