import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import ReactAudioPlayer from "react-audio-player";
import Textfile from "../Songs/textfiles";
import Musicfile from "../Songs/musicfiles";
import { useTranslation } from "react-i18next";

const SetlistLivemode = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");
  const [setlistSongs, setSetlistSongs] = useState([]);
  const [showTextfiles, setShowTextfiles] = useState(false);
  const [showMusicfiles, setShowMusicfiles] = useState(false);
  const [songId, setSongId] = useState("");
  const [songName, setSongName] = useState("");
  const lng = cookee.get("googtrans");
  const { tab6, tab7 } = t("setlist");

  useEffect(() => {
    getSetlistDetail();
  }, []);
  const handleTextfiles = (e, id, name) => {
    e.preventDefault();
    setSongId(id);
    setSongName(name);
    handleShowTextfile();
  };
  const handleMusicfiles = (e, id, name) => {
    e.preventDefault();
    setSongId(id);
    setSongName(name);
    handleShowMusicfile();
  };

  const handleShowMusicfile = () => {
    setShowMusicfiles(!showMusicfiles);
  };
  const handleShowTextfile = () => {
    setShowTextfiles(!showTextfiles);
  };
  const getSetlistDetail = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-set-list-details`,
      data: {
        set_list_id: localStorage.getItem("setlistid"),
      },
    }).then((res) => {
      setSetlistSongs(res.data.data2);
    });
  };

  return (
    <article className="col-lg-9">
      <div className="position-relative mt-56">
        {/* <img src="assets/images/banner444.png" className="img-fluid w-100" alt="" /> */}
        <div className="bannerCaption px-4">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h3 className="text-capitalize mb-0 text-white">
                marlenw t.lang band
              </h3>
              <h5 className="text-capitalize mb-0 text-white">
                musician artist
              </h5>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center gap-2 text-white">
                <img src="assets/images/gpsBanner.png" className="" alt="" />
                <span>Mistelgau, free state of bavaria(BY), 95490</span>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3 text-end">
              <p className="mb-0 text-white">15 June at 9:00 PM</p>
            </div>
          </div>
        </div>
      </div>
      <div className="article-body store-bd-gy mt-0">
        <div className="row align-items-center">
          <div className="col-sm-12">
            <div className="pt-3 px-1 bg-white">
              <div className="table-responsive">
                <table className="table storetable setlistTabla">
                  <thead className="">
                    <tr>
                      <th scope="col">name </th>
                      <th scope="col">Bpm</th>
                      <th></th>
                      <th scope="col">
                        Audio{" "}
                        <img
                          src="assets/images/Infowhite.png"
                          alt=""
                          className="mx-1 mb-4"
                          title={
                            lng === "/auto/de" || "/en/de"
                              ? "Hier können Sie die Audiodatei anhören, welche von der Band zu dem Song hinterlegt wurde"
                              : "Here you can listen to the audio file provided by the band for the song"
                          }
                        />
                      </th>
                      <th scope="col">
                        {tab6}{" "}
                        <img
                          src="assets/images/Infowhite.png"
                          alt=""
                          className="mx-1 mb-4"
                          title={
                            lng === "/auto/de" || "/en/de"
                              ? "Hier können Sie die von Ihnen hinterlegte PDF-Datei öffnen. Wenn Sie eine Datei hinterlegen möchten, klicken Sie auf den Rider „Songs“, wählen den jeweiligen Songs aus und tätigen den Upload der Datei"
                              : "Here you can open the PDF file you have saved. If you want to store a file, click on the “Songs” rider, select the respective song and upload the file"
                          }
                        />
                      </th>
                      <th scope="col">
                        {tab7}{" "}
                        <img
                          src="assets/images/Infowhite.png"
                          alt=""
                          className="mx-2 mb-4"
                          title={
                            lng === "/auto/de" || "/en/de"
                              ? "Hier können Sie die von Ihnen hinterlegte PDF-Datei öffnen. Wenn Sie eine Datei hinterlegen möchten, klicken Sie auf den Rider „Songs“, wählen den jeweiligen Songs aus und tätigen den Upload der Datei"
                              : "Here you can open the PDF file you have saved. If you want to store a file, click on the “Songs” rider, select the respective song and upload the file"
                          }
                        />
                      </th>
                      {/* <th scope="col">length of the song</th> */}
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {setlistSongs.map((res, i) => {
                      return (
                        <tr>
                          <td>
                            <h6>{res?.song_name}</h6>
                          </td>
                          <td>
                            <p className="mb-0">{res?.bpm}</p>
                          </td>
                          <td></td>
                          <td>
                            <ReactAudioPlayer src={res.song_url} controls />
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {/* <img src="assets/images/moreblck.png" width="25px" alt="" /> */}
                            <a
                              href={res?.text_sheet_url}
                              target="_blank"
                              download={res.text_sheet_url}
                            >
                              <img
                                src="assets/images/invoice.png"
                                width="25px"
                                alt=""
                              />
                            </a>
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            <a
                              href={res?.music_sheet_url}
                              target="_blank"
                              download={res?.music_sheet_url}
                            >
                              {" "}
                              <img
                                src="assets/images/live-music-sheet.png"
                                width="25px"
                                alt=""
                              />
                            </a>
                            {/* <img src="assets/images/live-music-sheet.png" width="25px" alt="" onClick={(e) => handleMusicfiles(e, res.m_song_id, res.song_name)} /> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showTextfiles ? (
        <Textfile
          show={showTextfiles}
          handleShow={handleShowTextfile}
          id={songId}
          name={songName}
        />
      ) : (
        ""
      )}
      {showMusicfiles ? (
        <Musicfile
          show={showMusicfiles}
          handleShow={handleShowMusicfile}
          id={songId}
          name={songName}
        />
      ) : (
        ""
      )}
    </article>
  );
};

export default SetlistLivemode;
