import React from 'react'
import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Link, useLocation } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';



const SwitchProfile = ({ show, handleShow, info }) => {
    const location = useLocation();
    const { pathname } = location;
    const dispatch = useDispatch()
    const cookie = new Cookies();
    const token = cookie.get('tokenmusician')
    const [allBandleader, setAllBandleader] = useState([])
    const [creator, setCreator] = useState([])
    const domaintype = window.location.origin
    useEffect(() => {
        allBands()
    }, [])
    const allBands = () => {
        axios({
            method: 'get',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-bandleader-permission-band-list`,

        })
            .then(res => {
                setCreator(res.data.data2)
                setAllBandleader(res.data.data)
            })
    }
    const handleBandSwitchSelf = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/switch-to-band-from-musician`,
            data:
            {
                "band_id": creator[0]?.band_id
            }
        })
            .then(res => {
                if (res.status === 200) {
                    // cookie.remove('tokenmusician')
                    // cookie.remove('emailmusician')
                    // cookie.remove('firstnamemusician')
                    // cookie.remove('lastnamemusician')
                    // cookie.remove('profilepicmusician')
                    // cookie.remove('phonenumbermusician')
                    let authToken = res.data.data.auth_token
                    let user = res.data.data2.user_id

                    // window.location = `https://bandleader.cleverbandassist.com/dashboard?token=${authToken}&user=${user}`
                    window.location = domaintype?.includes(".com") ? `https://bandleader.cleverbandassist.com/dashboard?token=${authToken}&user=${user}` : `https://bandleader.cleverbandassist.de/dashboard?token=${authToken}&user=${user}`
                    // window.location = `http://localhost:3000/dashboard?token=${authToken}&user=${user}`

                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    const handleBandSwitch = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/switch-to-band-from-musician`,
            data:
            {
                "band_id": id
            }
        })
            .then(res => {
                if (res.status === 200) {
                    // cookie.remove('tokenmusician')
                    // cookie.remove('emailmusician')
                    // cookie.remove('firstnamemusician')
                    // cookie.remove('lastnamemusician')
                    // cookie.remove('profilepicmusician')
                    // cookie.remove('phonenumbermusician')
                    let authToken = res.data.data.auth_token
                    let user = res.data.data2.user_id

                    window.location = domaintype?.includes(".com") ? `https://bandleader.cleverbandassist.com/dashboard?token=${authToken}&user=${user}` : `https://bandleader.cleverbandassist.de/dashboard?token=${authToken}&user=${user}`
                    // window.location = `http://localhost:3000/dashboard?token=${authToken}&user=${user}`

                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" centered >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <h5 className='text-center mb-3'>Bands</h5>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-4 text-center">
                                <div className='d-flex justify-content-around'>
                                    <h5 className='dropdown-item notranslate' onClick={handleBandSwitchSelf}>{creator[0]?.band_name}</h5>
                                    {creator[0]?.band_name ?
                                        <p className="StatusBtn approvedStatus">Creator</p>
                                        : ""}
                                </div>

                                {allBandleader?.length > 0 ? allBandleader.map((res, i) => {
                                    return <div className='d-flex justify-content-around'>
                                        <h5 className="dropdown-item notranslate" onClick={() => handleBandSwitch(res.band_id)}>{res.band_name} </h5>
                                        {res?.band_name && res?.permission ?
                                            <p className="StatusBtn pendingStatus">{res?.permission || ""}</p> : ""}
                                    </div>
                                }) : <p className="dropdown-item" ></p>}
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default SwitchProfile