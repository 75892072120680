import { yupToFormErrors } from "formik";
import * as Yup from "yup";


export const addNewSongSchema = Yup.object({
    songName: Yup.string().min(2).max(15).required("please enter song name"),
    songTime: Yup.number().required("please enter song time"),
    bpm: Yup.string().required("please enter "),
    gemaNumber: Yup.string().required("please enter gemaNumber"),
    isrc: Yup.string().required("please enter "),
});