import React from 'react'
import { Link } from 'react-router-dom'

const MobileOtp = () => {
    return (
        <section className="">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                        <img src="assets/images/musicianBanner.png" className="img-fluid w-100" alt="" />
                        <img src="assets/images/logo.png" className="musiLogo" alt="" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                        <div className="d-table h-100">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <h3 className="theme-color">LOGIN</h3>
                                        <p>Please enter 6 digits code</p>
                                    </div>
                                    <div className="form-group col-sm-12">
                                        <div className="passcode-wrapper">
                                            <input id="codeBox1" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox2" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox3" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox4" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox5" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox6" type="text" maxLength="1" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        we have sent OTP on your<br /> mobile number <span className="theme-color fw-bold">"9999999999"</span>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-3">
                                        <span className="fw-bold">1:01</span>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                 {/* <button className="btn btn-save w-75">VERIFY</button> */}
                                        <Link to="/signup" className="btn btn-save w-75"><b>VERIFY</b></Link>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        Don't have an account? <Link to="/signup" className="text-dark"><b>Sign Up!</b></Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MobileOtp