import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getBandChats = createAsyncThunk("bandchats", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let bchat =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-messages`,
            data: {
                "task_id": -1,
                "event_id":-1,
                "band_id":obj?.bandid,
            }
        })
            .then(res => res.data.data)
    return bchat
})

const listBandChats = createSlice(({
    name: "all band chats",
    initialState: {
        allBandChats: [],
        loading: false
    },
    extraReducers: {
        [getBandChats.pending]: (state, action) => {
            state.loading = true;
        },
        [getBandChats.fulfilled]: (state, action) => {
            state.allBandChats = action.payload
        },
        [getBandChats.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listBandChats.reducer;