import React from 'react'
import { Link } from 'react-router-dom'


const Carousel = () => {

  return (
    <div >
      <div id="hero" className="">
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="./assets/images/Bannerimage.png" className="d-block w-100" alt="" />
              <div className="carousel-caption d-none d-md-block">
                <h5 className="text-uppercase position-relative"><img src="./assets/images/bannerLogo.png" className="bannrLogo" alt="" /><span className="h5Font"><span>clever</span> <span className="fw-bold">bandassist-</span></span> </h5>
                <p className="fw-bold deiFont notranslate">die band - und konzertmanagement software</p>
                <Link to="/signup" className="signupbtn text-uppercase" href="#">sign up now for free now</Link>
              </div>
            </div>

          </div>

        </div>
      </div>

      <section className="d-block d-md-none mt-3">
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner textSlider">
            <div className="carousel-item active">
              <div className="carousel-caption">
                <h5 className="text-uppercase position-relative"><img src="./assets/images/bannerLogoblack.png" className="bannrLogo" alt="" /><span className="h5Font"><span>clever</span> <span className="fw-bold">bandassist-</span></span> </h5>
                <p className="fw-bold deiFont notranslate">die band-und Konzertmanagement Software</p>
                <a className="signupbtn text-uppercase" href="#">sign up now for free</a>
              </div>
            </div>

          </div>
        </div>
      </section>

      <main id="main">

        <section id="aboutus">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="bg-white shadow aboutbox">

                  <div className="text-center"><h3>Die kostenlose Band- und Konzertmanagement Software.</h3><h3> Clever BandAssist is the all-in-one solution to manage your band and concerts</h3></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="mt5 secBhg">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-6 col-lg-7 ps-0">
                <img src="./assets/images/manageour.png" className="img-fluid" alt="" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-5">
                <h2 className="text-uppercase text-black fw-bold notranslate">events</h2>


                <p>Create appointments and coordinate them with your musicians and staff</p>
                <p>Teilen Sie relevante Informationen zu Terminen mit Ihrem
                  Team wie zb. Adressen, Zeiten, Technik usw.</p>

                <p>Band- und Teammitglieder können Abwesenheitstage
                  eintragen - so haben Sie immer Überblick für Ihr Booking</p>
              </div>
            </div>
          </div>
        </section>

        <section className="mt5">
          <div className="container-fluid">
            <div className="row align-items-center rowReve">
              <div className="col-sm-12 col-md-6 col-lg-6 ps-md-5">
                <h2 className="text-uppercase text-black fw-bold notranslate">chat</h2>
                <h5 className="titleSec">Tauschen Sie sich in den zugewiesenen Event- und Aufgabenchats mit Ihrem Team aus</h5>

                <p>No more clutter in your group chat - assigned chats to your appointments and tasks</p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <img src="./assets/images/eventimg.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="mt5 secBhg">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-6 col-lg-7 ps-0">
                <img src="assets/images/SongLyrics.png" className="img-fluid" alt="" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-5">
                <h2 className="text-uppercase text-black fw-bold notranslate">songs</h2>
                <h5 className="titleSec">Speichern Sie Ihre Aufnahmen, Text- und Notenblätter zu
                  Ihren Songs</h5>
                <p>So haben Sie ihre Dateien organisiert und somit schnellen
                  und unkomplizierten Zugriff</p>
              </div>
            </div>
          </div>
        </section>

        <section className="mt5">
          <div className="container-fluid">
            <div className="row rowReve">
              <div className="col-sm-12 col-md-6 col-lg-6 ps-md-5">
                <h2 className="text-uppercase text-black fw-bold">setlist</h2>
                <h5 className="titleSec notranslate">Erstelle Setlisten per Drag&Drop</h5>

                <p>Your band members can call up the Setlisten and prepare for the concerts</p>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 pe-0">
                <img src="assets/images/img1.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="mt5 secBhg">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-6 col-lg-6 ps-4">
                <img src="assets/images/tasksimg.png" className="img-fluid" alt="" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pe-5">
                <h2 className="text-uppercase text-black fw-bold">tasks</h2>
                <h5 className="titleSec">Erstellen Sie Aufgaben für Ihre Bandmitglieder und behalten den Status im Überblick</h5>

                <p>No more chaos in the implementation of your projects</p>
              </div>
            </div>
          </div>
        </section>

        <section className="mt5">
          <div className="container-fluid">
            <div className="row rowReve">
              <div className="col-sm-12 col-md-6 col-lg-6 ps-md-5">
                <h2 className="text-uppercase text-black fw-bold notranslate">gema</h2>
                <h5 className="titleSec">Übertragen Sie Ihre Setlisten</h5>
                <p>Submit your Setlisten to GEMA in no time</p>
                <p>Keep track of which concerts and works have already been submitted, paid for or complained about - without any effort</p>
                <p>Die benötigten Daten werden automatisiert aus Ihren Events übernommen</p>

              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 pe-0">
                <img src="assets/images/img5.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>


        <section className="mt5">
          <div className="container-fluid">
            <div className="row rowReve">
              <div className="col-sm-12 col-md-6 col-lg-6 pe-0">
                <img src="assets/images/all.png" className="img-fluid" alt="" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 ps-md-5">
                <h2 className="text-uppercase text-black fw-bold notranslate">web application</h2>
                <h5 className="titleSec">Greifen Sie von überall auf Ihr Konto zu</h5>
                <p>Arbeiten Sie von unterwegs mit Ihrem Laptop, Tablet oder Handy und bleiben
                  auf dem Laufenden</p>
              </div>

            </div>
          </div>
        </section>

        <section className="newsletter-bg mt5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h4 className="px-md-4">Tired of managing your band with a variety of apps and programs? </h4>
                  <p>Haben Sie schon zu viel Zeit mit unübersichtlicher Kommunikation und
                    Plattformen verschwendet?</p> 
               <h5 className="text-uppercase mt-4">Dann registriere deinen kostenlosen Account</h5> 
                <Link to="/signup" className="signupbtn bg-white text-theme text-uppercase mt-3 fs-6" href="consultation-now.html">SING UP NOW FOR FREE</Link>

              </div>
            </div>
          </div>
        </section>


      </main>



    </div>
  )
}

export default Carousel