import React, { useState } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import S3FileUpload from 'react-s3';
import { addNewSongSchema } from '../Schemas/addNewSongSchema'

window.Buffer = window.Buffer || require("buffer").Buffer;



const initialValues = {
    songName: "",
    songTime: "",
    bpm: "",
    gemaNumber: "",
    isrc: ""
}
const AddNewSong = () => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    const navigate = useNavigate();
    const [file1, setFile1] = useState("")
    const [file2, setFile2] = useState("")
    const [file3, setFile3] = useState("")
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: addNewSongSchema,
        onSubmit: (values, action) => {
            handleAddSong();
        }
    })
    const handleFile1 = (e) => {
        setFile1(e.target.files[0])
    }
    const handleFile2 = (e) => {
        setFile1(e.target.files[0])
    }
    const handleFile3 = (e) => {
        setFile1(e.target.files[0])
    }
    const handleFileUpload1 = (e) => {
        setLoading1(true)
        let file = e.target.files[0];
        const config = {
            bucketName: 'media-appsinvo',
            region: 'us-east-2',
            accessKeyId: 'AKIA6LSDBEL3U2HOJWLW',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                setFile1(data.location)
                setLoading1(false)
            })
            .catch(err => console.error("ERR", err))
    }
    const handleFileUpload2 = (e) => {
        setLoading2(true)
        let file = e.target.files[0];
        const config = {
            bucketName: 'media-appsinvo',
            region: 'us-east-2',
            accessKeyId: 'AKIA6LSDBEL3U2HOJWLW',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                setFile2(data.location)
                setLoading2(false)
            })
            .catch(err => console.error("ERR", err))
    }
    const handleFileUpload3 = (e) => {
        setLoading3(true)
        let file = e.target.files[0];
        const config = {
            bucketName: 'media-appsinvo',
            region: 'us-east-2',
            accessKeyId: 'AKIA6LSDBEL3U2HOJWLW',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                setFile3(data.location)
                setLoading3(false)
            })
            .catch(err => console.error("ERR", err))
    }

    const handleAddSong = () => {

        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: "http://3.131.5.153:4050/v1/musician-add-song",
            data:
            {
                "user_type": 1,
                "song_name": values.songName,
                "time_length": values.songTime,
                "is_copyright": 0,
                "bpm": values.bpm,
                "gemma_number": values.gemaNumber,
                "isrc_code": values.isrc,
                "song_url": file3,
                "text_sheet_url": file1,
                "music_sheet_url": file2,
               //  "text_sheet_url": file1,

            }
        })
            .then(res => {
                // console.log("res", res.data)
                if (res.data.message === "Song Added Successfully!.") {
                    alert("Song added")
                    navigate("/songs")
                }
            })
    }

    return (
        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <div className="row mt-3 align-items-center">
                <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-4">
                        <h5 className="mb-0">Add new song</h5>
                    </div>
                    <hr />
                    <div className="col-sm-12">
                        <form className="row my-5 py-5 addNAme mx-width">
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <input type="text" name="songName" value={values.songName} onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder="Name Of Song" />
                                {errors.songName && touched.songName ? <p style={{ color: 'red' }}>{errors.songName}</p> : ""}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <input type="text" name="songTime" value={values.songTime} onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder="Time Of Song [00:00]" />
                                {errors.songTime && touched.songTime ? <p style={{ color: 'red' }}>{errors.songTime}</p> : ""}
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <input type="text" name="bpm" value={values.bpm} onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder="Bpm" />
                                {errors.bpm && touched.bpm ? <p style={{ color: 'red' }}>{errors.bpm}</p> : ""}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <input type="text" name="gemaNumber" value={values.gemaNumber} onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder="Gema number" />
                                {errors.gemaNumber && touched.gemaNumber ? <p style={{ color: 'red' }}>{errors.gemaNumber}</p> : ""}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <input type="text" name="isrc" value={values.isrc} onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder="ISRC" />
                                {errors.isrc && touched.isrc ? <p style={{ color: 'red' }}>{errors.isrc}</p> : ""}
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="upload-btn-wrapper Attachment">
                                    <button className="btn">Text Files</button>
                                    <input type="file" onChange={handleFileUpload1} name="myfile" />
                                    <img src="assets/images/live-file.png" alt="" className="inputImgRight" />
                                    {loading1 ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> :  file1 }
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="upload-btn-wrapper Attachment">
                                    <button className="btn">Music Files</button>
                                    <input type="file" name="myfile" onChange={handleFileUpload2} />
                                    <img src="assets/images/attachment.png" alt="" className="inputImgRight" />
                                    {loading2 ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> :  file2 }
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-3 col-lg-3 mb-5 my-4">
                                <input type="checkbox" style={{ width: "50px", height: "20px" }} /><label className=''>Copyrights in band</label>

                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 mb-5 my-4">
                                <input type="checkbox" style={{ width: "50px", height: "20px" }} /><label>Coversong</label>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="upload-btn-wrapper Attachment">
                                    <button className="btn">Audio Files</button>
                                    <input type="file" name="myfile" onChange={handleFileUpload3} />
                                    <img src="assets/images/live-mic.png" alt="" className="inputImgRight" />
                                    {loading3 ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> :  file3 }
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                <div className="d-flex justify-content-center my-3">
                                    <button className="btn add-new" onClick={handleSubmit}>add song</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default AddNewSong