import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPassengers = ({
  show,
  handleShow,
  id,
  driverList,
  transportId,
  eventid,
  tab30,
  tab31,
  tab32,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");
  const [setupName, setSetupName] = useState("");
  const [passengerName, setPassengerName] = useState("");
  const handleChange = (e) => {
    setSetupName(e.target.value);
  };
  const handlePassengerName = (e) => {
    setPassengerName(e.target.value);
  };
  const handleAddPassenger = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/musician-add-passenger-to-transport`,
      data: {
        transport_id: transportId,
        passengers: [
          {
            passenger_name: passengerName,
          },
        ],
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setPassengerName("");
          dispatch(getEventDetail({ eventid: eventid }));
          handleShow();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="md" centered>
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <h4 className="mt-2 text-center">{tab30}</h4>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="d-flex align-items-center lg-justify-content-end mx-3">
                  <input
                    type="text"
                    className="form-control p-0 ml-3"
                    value={passengerName}
                    onChange={handlePassengerName}
                    placeholder={tab31}
                    style={{ height: "40px", borderRadius: "10px" }}
                  />
                </div>

                <div className="align-items-center my-3">
                  <button
                    className="btn add-new"
                    style={{ marginLeft: "160px" }}
                    onClick={handleAddPassenger}
                  >
                    {tab32}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AddPassengers;
