import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'

window.Buffer = window.Buffer || require("buffer").Buffer;




const SignupBandleader = () => {
    const { t } = useTranslation();
    const cookie = new Cookies();
    const navigate = useNavigate()
    const lng = cookie.get('googtrans')
    const email = cookie.get("emailmusician")
    const phone = cookie.get("phonenumbermusician")
    const countrycode = cookie.get("country_codemusician")
    const token = cookie.get('tokenmusician')
    const { tab1 } = t("createband")

    const initialValues = {
        bandName: "",
        email: email,
        phone: phone,
        password: "",
        confirmPassword: "",
    }
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        // validationSchema: bandleaderSignUpSchema,
        onSubmit: () => {
            // console.log("data", values)
            handleSignUp()
        }
    })


    const handleSignUp = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-sign-up-with-musician-details`,
            data:
            {
                "band_name": values.bandName,
                "profile_pic": "",
                "email": email,
                "country_code": countrycode,
                "phone_number": phone,
                "is_active": 1,
                "language": "EN"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    //cookie.set('token', res.data.data.auth_token)
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate("/appointments")
                }

            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })

    }


    return (
        <section className="">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                        <img src="assets/images/image12.png" className="img-fluid w-100" alt="" />
                        <Link to="/"><img src="assets/images/logoWhite.png" className="musiLogo" alt="" /></Link>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                        <div className="d-table h-100">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        <h3 className="theme-color">{tab1} <img src="assets/images/InfoBlack.png" alt="" className="mx-1 mb-5" title={lng === "/auto/de" || "/en/de" ? "Sie haben derzeit die Möglichkeit, eine Band über Ihren Account erstellen zu können." : "You currently have the option to create one band through your account."} /></h3>
                                    </div>
                                    <div className="col-sm-12 col-md-12 mb-4">
                                        <div className="form-group">
                                            <label className="theme-color fw-bold">Band Name</label>
                                            <div className="input-container">
                                                <input type="text" className="form-control p-0" placeholder="Name" name="bandName" value={values.bandName} onChange={handleChange} onBlur={handleBlur} />
                                                <img src="assets/images/userName.png" className="input-img" alt="" />
                                            </div>
                                            {errors.bandName && touched.bandName ? <p style={{ color: 'red' }}>{errors.bandName}</p> : ""}
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        <button className="btn btn-save w-75" onClick={handleSubmit}>{tab1}</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </section>

    )
}

export default SignupBandleader