import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { loginPhoneSchema } from '../Schemas/loginPhoneSchema'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie'
import PhoneInput from 'react-phone-input-2'
import OtpPhoneModal from './otpModalPhone';



const initialValues = {
    mobile: "",
    countryCode: ""
}
const LoginPhone = () => {
    let navigate = useNavigate()
    const cookie = new Cookies();
    const [showPhoneOtp, setShowPhoneOtp] = useState(false)
    const [countryData, setCountryData] = useState([])
    const devicetoken = cookie.get("devicetokenmusician")


    const { values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: loginPhoneSchema,
        onSubmit: (values, action) => {
            //  console.log("data", values)
            handleSendOtpPhone()
        }
    })
    const handleShowPhoneOtp = () => {
        setShowPhoneOtp(!showPhoneOtp)
    }
    const handleSendOtpPhone = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-mobile-number`,
            data:
            {

                "country_code": values.countryCode,
                "phone_number": values.mobile
            }
        })
            .then(res => {
                if (res.data.message === "OTP Sent on Registered Phone Number") {
                    // navigate('/otp-verification')
                    handleShowPhoneOtp()
                }

            })
            .catch((error) => {
                if (error.response.data.message === 'Invalid Phone Number! Retry.') {
                    toast.error("Invalid Phone Number.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    const handleSendOtp = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-mobile-number`,
            data:
            {

                "phone_number": values.phone,
            }
        })
            .then(res => {
                if (res.data.message === "OTP Sent on Registered Phone Number") {
                    localStorage.setItem("phone", values.phone)
                    navigate('/otp-verification')
                    toast.success("OTP sent successfully.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
            .catch((error) => {
                if (error.response.data.message === 'Invalid Phone Number! Retry.') {
                    toast.error("Invalid Phone Number.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    const loginWithNumber = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/sign-in-with-phone-number`,
            data:
            {

                "country_code": values.countryCode,
                "phone_number": values.mobile,
                "device_token": devicetoken ? devicetoken : "AS",
                "device_type": "W"
            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)

                if (res.status === 200) {
                    cookie.set('permission_musician', res.data.data.permission_type)
                    cookie.set('tokenmusician', res.data.data.auth_token)
                    cookie.set('emailmusician', res.data.data.email)
                    cookie.set('firstnamemusician', res.data.data.first_name)
                    cookie.set('lastnamemusician', res.data.data.last_name)
                    cookie.set('profilepicmusician', res.data.data.profile_pic)
                    cookie.set('phonenumbermusician', res.data.data.phone_number)
                    cookie.set('userbothmusician', res.data.data.is_user_both)
                    cookie.set('country_codemusician', res.data.data.country_code)

                    // localStorage.setItem("phone", values.phone)
                    // navigate('/otp-verification')
                    toast.success(res.data.message, {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/appointments')
                }

            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                // if (error.response.data.message === "Account doesn't Exists!") {
                //     toast.error("Account doesn't Exists!", {
                //         theme: "colored",
                //         position: "top-right",
                //         autoClose: 4000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     })
                // }
            })
    }
    return (
        <section className="">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                        <img src="assets/images/musicianBanner.png" className="img-fluid w-100" alt="" />
                        <Link to="/"><img src="assets/images/logo.png" className="musiLogo" alt="" /></Link>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                        <div className="d-table h-100">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        <h3 className="theme-color">LOGIN</h3>
                                        <p>Enter mobile number and login</p>
                                    </div>
                                    <div className="col-sm-12 col-md-12 mb-4">
                                        <div className="form-group">
                                            {/* <label className="">Phone Number</label> */}
                                            <div className="input-container">
                                                <PhoneInput country="DE"
                                                    inputProps={{
                                                        name: "mobile",
                                                    }}
                                                    enableSearch
                                                    value={
                                                        values.countryCode + values.mobile
                                                    }
                                                    onChange={(phone, country, e, formattedValue) => {
                                                        setCountryData({ formattedValue, country });
                                                        if (phone.length > 0) {
                                                            const raw = phone.slice(
                                                                country?.dialCode?.length
                                                            );
                                                            setFieldValue("mobile", raw);
                                                            setFieldValue(
                                                                "countryCode",
                                                                formattedValue?.split(" ")[0]
                                                            );
                                                        } else {
                                                            setFieldValue("mobile", "");

                                                            setFieldValue("countryCode", "");
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder="Contact number"
                                                    containerclassName="form-control signupClass"
                                                />
                                                <img src="assets/images/phone-call.png" className="input-img" alt="" />
                                            </div>
                                            {/* {errors.phone && touched.phone ? <p style={{ color: 'red' }}>{errors.phone}</p> : ""} */}
                                        </div>
                                        {errors.mobile && touched.mobile ? <p style={{ color: 'red' }}>{errors.mobile}</p> : ""}
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        {/* <Link to="/mobile-otp-verification" className="btn btn-save w-75">SEND</Link> */}
                                        <button className='btn btn-save w-75' onClick={handleSubmit}>Log in</button>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-2">
                                        <Link to="/login" className="text-dark">or Login with <b> Email</b></Link>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        Don't have an account? <Link to="/signup" className="text-dark"><b>Sign Up!</b></Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            {showPhoneOtp ? <OtpPhoneModal show={showPhoneOtp} handleShow={handleShowPhoneOtp} mobile={values.mobile} countryCode={values.countryCode} handleSignUp={loginWithNumber} /> : ""}
        </section>
    )
}

export default LoginPhone