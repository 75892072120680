import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getFiles = createAsyncThunk("chats", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let files =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-musician-uploaded-song-files`,
            data: {
                "m_song_id": obj.songid,
                "is_self":1
            }
        })
            .then(res => res.data.data)
    return files
})

const listFiles = createSlice(({
    name: "all files",
    initialState: {
        allFiles: [],
        loading: false
    },
    extraReducers: {
        [getFiles.pending]: (state, action) => {
            state.loading = true;
        },
        [getFiles.fulfilled]: (state, action) => {
            state.allFiles = action.payload
        },
        [getFiles.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listFiles.reducer;