import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InfoModal from "./infoModal";
import { useTranslation } from "react-i18next";

const AppointmentDetail = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const navigate = useNavigate();
  const token = cookee.get("tokenmusician");
  const localData = JSON.parse(localStorage.getItem("eventdata"));
  const [date, setDate] = useState("");
  const [infoShow, setInfoShow] = useState(false);
  const [information, setInformation] = useState("");
  const firstname = cookee.get("firstnamemusician");
  const {
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab12,
    tab13,
    tab14,
    tab15,
    tab16,
    tab17,
    tab18,
  } = t("appointments");

  useEffect(() => {
    setDate(formatDate(localData[0]?.start_date.substr(0, 10)));
  }, []);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2),
      month = datePart[1],
      day = datePart[2];

    return day + "/" + month + "/" + year;
  }
  const handleAccept = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/accept-musician-invite`,
      data: {
        event_id: localData[0].event_id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        navigate("/appointments");
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleReject = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/reject-musician-invite`,
      data: {
        event_id: localData[0].event_id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        navigate("/appointments");
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleInformation = () => {
    setInfoShow(!infoShow);
  };
  const infoDetail = () => {
    setInformation(
      localData[0]?.band_users_details?.map((res) => {
        return res.first_name === firstname ? res.additional_information : "";
      })
    );
    handleInformation();
  };
  return (
    <>
      <article className="col-lg-9 mt-4">
        <div className="article-body store-bd-gy mt-0">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4 mt-4">
              <h4 className="mb-0">Information</h4>
            </div>

            <div className="col-sm-12">
              <form className="row addNAme px-5">
                <div className="my-3">
                  <h5>Events</h5>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="assets/images/information.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={infoDetail}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                  <label className="header-label notranslate">Name</label>
                  <input
                    type="text"
                    value={localData[0]?.event_name || ""}
                    className="form-control"
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <label className="header-label notranslate">Date</label>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={date}
                      className="form-control"
                      style={{ cursor: " context-menu" }}
                    />
                    <img
                      src="assets/images/calendarGrey.png"
                      alt=""
                      className="inputImgRight"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 mb-5">
                  <label className="header-label notranslate">Get In</label>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={`${localData[0]?.start_time?.substr(0, 5)}` || ""}
                      className="form-control"
                      style={{ cursor: " context-menu" }}
                    />
                    {/* <img src="assets/images/phone-call.png" alt="" className="inputImgRight" /> */}
                  </div>
                </div>
                {localData[0]?.event_type !== 1 ? (
                  <div className="col-sm-6 col-md-3 col-lg-3 mb-5">
                    <label className="header-label notranslate">End</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        value={`${localData[0]?.end_time?.substr(0, 5)}` || ""}
                        className="form-control"
                        style={{ cursor: " context-menu" }}
                      />
                      {/* <img src="assets/images/phone-call.png" alt="" className="inputImgRight" /> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {localData[0]?.event_type !== 1 ? (
                  ""
                ) : (
                  <>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                      <label className="header-label notranslate">Load</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={
                            `${localData[0]?.load_start_time?.substr(
                              0,
                              5
                            )} - ${localData[0]?.load_end_time?.substr(
                              0,
                              5
                            )}` || ""
                          }
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                      <label className="header-label notranslate">
                        Sound Check
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={
                            `${localData[0]?.sound_start_time?.substr(
                              0,
                              5
                            )} - ${localData[0]?.sound_end_time?.substr(
                              0,
                              5
                            )}` || ""
                          }
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                      <label className="header-label notranslate">
                        Stagetime
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={
                            `${localData[0]?.stage_start_time?.substr(
                              0,
                              5
                            )} - ${localData[0]?.stage_end_time?.substr(
                              0,
                              5
                            )}` || ""
                          }
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                  <label className="header-label">Information</label>
                  <textarea
                    className="textarea-control"
                    value={localData[0]?.event_other_info || ""}
                    rows="7"
                    readOnly
                    style={{ cursor: " context-menu" }}
                  ></textarea>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="my-3">
                    <h5>Location</h5>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <label className="header-label">Name</label>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={localData[0]?.location_name || ""}
                      className="form-control"
                      style={{ cursor: " context-menu" }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="position-relative"></div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="row">
                    <div className="col-sm-6 col-md-12 col-lg-3 mb-5">
                      <label className="header-label">{tab7}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={localData[0]?.location_street || ""}
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-12 col-lg-3 mb-5">
                      <label className="header-label">{tab8}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control notranslate"
                          value={localData[0]?.event_zip_code || ""}
                          readOnly
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-12 col-lg-3 mb-5">
                      <label className="header-label">{tab9}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={localData[0]?.town || ""}
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
                      <label className="header-label">{tab10}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={localData[0]?.event_country || ""}
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {localData[0]?.event_type !== 1 ? (
                  ""
                ) : (
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                    <label className="header-label">Information</label>

                    <textarea
                      className="textarea-control"
                      value={localData[0]?.location_other_information || ""}
                      rows="4"
                      readOnly
                      style={{ cursor: " context-menu" }}
                    ></textarea>
                  </div>
                )}
                {localData[0]?.event_type !== 1 ? (
                  ""
                ) : (
                  <>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="my-3">
                        <h5>{tab16}</h5>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                      <label className="header-label">Name</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={
                            localData[0]?.info_accommodation[0]
                              ?.accomodation_name || ""
                          }
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                      <div className="position-relative"></div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                      <label className="header-label">Email</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={
                            localData[0]?.info_accommodation[0]
                              ?.accomodation_email || ""
                          }
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                        <img
                          src="assets/images/arroba.png"
                          alt=""
                          className="inputImgRight"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                      <label className="header-label">{tab17}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={
                            localData[0]?.info_accommodation[0]
                              ?.accomodation_phone_number || ""
                          }
                          className="form-control"
                          style={{ cursor: " context-menu" }}
                        />
                        <img
                          src="assets/images/phone-call.png"
                          alt=""
                          className="inputImgRight"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="row">
                        <div className="col-sm-6 col-md-3 col-lg-3 mb-5">
                          <label className="header-label">{tab7}</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              value={
                                localData[0]?.info_accommodation[0]
                                  ?.accomodation_street || ""
                              }
                              className="form-control"
                              style={{ cursor: " context-menu" }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 mb-5">
                          <label className="header-label">{tab8}</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              value={
                                localData[0]?.info_accommodation[0]
                                  ?.accomodation_postal || ""
                              }
                              className="form-control"
                              readOnly
                              style={{ cursor: " context-menu" }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 mb-5">
                          <label className="header-label">{tab18}</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              value={
                                localData[0]?.info_accommodation[0]
                                  ?.accomodation_town || ""
                              }
                              className="form-control"
                              style={{ cursor: " context-menu" }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 mb-5">
                          <label className="header-label">{tab10}</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              value={
                                localData[0]?.info_accommodation[0]
                                  ?.accomodation_country || ""
                              }
                              className="form-control"
                              readOnly
                              style={{ cursor: " context-menu" }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                          <label className="header-label">Information</label>
                          <textarea
                            className="textarea-control"
                            value={
                              localData[0]?.info_accommodation[0]
                                ?.accomodation_other_information || ""
                            }
                            rows="4"
                            readOnly
                            style={{ cursor: " context-menu" }}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="my-3">
                        <h5>{tab11}</h5>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 col-lg-4 mb-5">
                      <label className="header-label">{tab12}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          value={localData[0]?.parking || ""}
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 mb-5">
                      <label className="header-label">{tab13}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control notranslate"
                          value={localData[0]?.guest_list || ""}
                          style={{ cursor: " context-menu" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 mb-5">
                      <label className="header-label">Catering</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control notranslate"
                          value={localData[0]?.catering || ""}
                          style={{ cursor: " context-menu" }}
                        />
                        {/* <img src="assets/images/arroba.png" alt="" className="inputImgRight" /> */}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                      <label className="header-label">Information</label>
                      <textarea
                        className="textarea-control notranslate"
                        value={localData[0]?.sonstige_other_info || ""}
                        rows="7"
                        readOnly
                        style={{ cursor: " context-menu" }}
                      ></textarea>
                    </div>
                  </>
                )}
                {localData[0]?.is_cancelled === 1 ? (
                  ""
                ) : (
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div className="d-flex justify-content-center gap-3 my-3">
                      <button
                        className="btn add-new btnGrey"
                        onClick={handleAccept}
                      >
                        {tab14}
                      </button>
                      <button
                        className="btn add-new btnGrey"
                        onClick={handleReject}
                      >
                        {tab15}
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </article>
      {infoShow ? (
        <InfoModal
          show={infoShow}
          handleShow={handleInformation}
          information={information}
        />
      ) : (
        ""
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AppointmentDetail;
