import React, { useState, useEffect } from 'react'

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import axios from 'axios';
import Cookies from 'universal-cookie'
import moment from 'moment'
require('moment/locale/de.js')
const localizer = momentLocalizer(moment)


const TaskCalendarView = () => {
    const cookee = new Cookies();
    const [newTask, setNewTask] = useState([])
    const token = cookee.get('tokenmusician')

    useEffect(() => {
        getNewTasks()
    }, [])

    const getNewTasks = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-task-list`,
            data:
            {
                "page_no": 1,
                "is_self": 3,
                "status": 1
            }
        })
            .then(res => {
                setNewTask(res.data.data.map((res, i) => {
                    return { id: i, title: res.task_name, start: res.dead_line, end: res.dead_line }
                }))
            })
    }


    return (
        <div className='notranslate'>
            <Calendar
                localizer={localizer}
                events={newTask}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                messages={{
                    next: "nachste",
                    previous: "zuruck",
                    today: "Heute",
                    month: "monat",
                    week: "woche",
                    day: "tag"
                }}
            />
        </div>
    )
}

export default TaskCalendarView