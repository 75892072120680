import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'

const Header = () => {
    const location = useLocation();
    const cookie = new Cookies();
    const { pathname } = location;
    const [show, setShow] = useState(false)

    const handleClick = () => {
        setShow(!show)
    }



    const handleShowClick = () => {
        setShow(false)
    }

    return (
        <header className="header fixed-top header-scrolled ">
            <div className="container d-flex align-items-center">
                <Link to='/' exact className="logo me-auto">
                    <img src="assets/images/logoWhite.png" alt="" className="img-fluid" /></Link>

                <nav id="navbar" className={show ? "navbar navbar-mobile" : "navbar"}>
                    <ul>
                        <li onClick={handleShowClick}><Link to='/' className={pathname.includes("price") || pathname.includes("gema") || pathname.includes("contactus") ? "nav-link scrollto" : "nav-link scrollto active "} href="#features"><span className='notranslate'>Features</span></Link></li>
                        <li onClick={handleShowClick}><Link to='/price' className={pathname.includes("price") ? "nav-link scrollto active" : "nav-link scrollto "} ><span>Prices</span></Link></li>
                        <li className='notranslate' onClick={handleShowClick}><Link to="/gema" className={pathname.includes("gema") ? "nav-link scrollto active" : "nav-link scrollto "}>GEMA</Link></li>
                        <li onClick={handleShowClick}><Link to="/contactus" className={pathname.includes("footer") ? "nav-link scrollto active" : "nav-link scrollto "} href="#footer"><span> Hilfe</span></Link></li>
                        <li onClick={handleShowClick}><Link to="/contactus" className={pathname.includes("contactus") ? "nav-link scrollto active" : "nav-link scrollto "}><span >Contact Us</span></Link></li>

                        <li onClick={handleShowClick}><Link to="/login" className="getstarted scrollto notranslate">LOGIN</Link></li>
                        <li>
                            <img src="assets/images/german-select.png" style={{ width: "50px", height: "40px" }} alt="" className="img-fluid" />
                        </li>

                    </ul>
                    <i className={show ? "bi mobile-nav-toggle bi-x" : "bi bi-list mobile-nav-toggle"} onClick={handleClick}></i>
                </nav>
            </div>
        </header>
    )
}

export default Header