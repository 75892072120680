import React from 'react'
import { useDispatch } from 'react-redux'
import { getBandChatsBandleader } from '../../app_redux/slice/bandsChat'


const ChatListItems = ({band} ) => {
    const dispatch = useDispatch()

    const handleClick = (id) => {
        // band(true)
        dispatch(getBandChatsBandleader())
    }

    return (
    
            <div className="chatList px-2 MsgActive" >
                <div className="d-flex align-items-center gap-2" >
                    <div className="position-relative">
                        {/* <img src="assets/images/profile.png" alt="" className="chatProfile" /> */}
                        <span className="activeUser"></span>
                    </div>
                    <div className="">
                        <h6 className="text-capitalize mb-0 notranslate" onClick={handleClick}>Band Chat</h6>
                    </div>
                </div>
            </div>
    )
}

export default ChatListItems