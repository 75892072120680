import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getMusicianInvites = createAsyncThunk("invites", async () => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let invites =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-membership-invites`,
            data:{
                "page_no":1
            }
        })
            .then(res => res.data.data)
    return invites
})

const listMusicianInvites = createSlice(({
    name: "all invites",
    initialState: {
        allMusicianInvites: [],
        loading: false
    },
    extraReducers: {
        [getMusicianInvites.pending]: (state, action) => {
            state.loading = true;
        },
        [getMusicianInvites.fulfilled]: (state, action) => {
            state.allMusicianInvites = action.payload
        },
        [getMusicianInvites.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listMusicianInvites.reducer;