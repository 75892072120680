import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getCounts = createAsyncThunk("counts", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    let files =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-musician-unread-notification-count`,
            data: {
                "user_type": 1,
            }
        })
            .then(res => res.data.data.count)
    return files
})

const taskCount = createSlice(({
    name: "all counts",
    initialState: {
        counts: "",
        loading: false
    },
    extraReducers: {
        [getCounts.pending]: (state, action) => {
            state.loading = true;
        },
        [getCounts.fulfilled]: (state, action) => {
            state.counts = action.payload
        },
        [getCounts.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default taskCount.reducer;