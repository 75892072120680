import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage,isSupported } from "firebase/messaging";
import Cookies from 'universal-cookie'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const cookie = new Cookies();

var firebaseConfig = {
  apiKey: "AIzaSyCf0uXJyPuRPddtMQzgpubTnNyHNlb5T4M",
    authDomain: "clever-band-assist.firebaseapp.com",
    projectId: "clever-band-assist",
    storageBucket: "clever-band-assist.appspot.com",
    messagingSenderId: "1060535697193",
    appId: "1:1060535697193:web:63684d41032c5242de8138",
    measurementId: "G-7C08CMGDWG"
};


const firebaseApp = initializeApp(firebaseConfig);


// let messaging =null;
// if(firebaseApp?.messaging?.isSupported()){
//     messaging = firebaseApp.messaging();
//    console.log("Messaging",messaging)
//   }
//   else{
//     console.log("Not supported by browser")
//   }
  export const messaging = (async () => {
  try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
          return getMessaging(firebaseApp);
      }
      console.log('Firebase not supported this browser');
      return null;
  } catch (err) {
      console.log(err);
      return null;
  }
  })();
  // export const messaging = getMessaging(firebaseApp);
  export const onMessageListener = async () =>
new Promise((resolve) =>
    (async () => {
        const messagingResolve = await messaging;
        onMessage(messagingResolve, (payload) => {
             toast.success(payload.notification.title, {
              theme: 'colored',
              position: "top-center",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          })
            resolve(payload);
        });
    })()
);
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
// });

export const requestForToken = async (registration) => {
  try {
 
  const messagingResolve = await messaging;
  const currentToken = await getToken(messagingResolve, {
       vapidKey:         "BMPTbvAXCQic9zx693ZD8BO5QL-sNrqpaN2c768f45fcgxJTrJNKiCc8y1RNmLQOLs34D6wDAaxbks-0UD_JtBg",
      serviceWorkerRegistration: registration,
  });

  if (currentToken) {
      //  console.log("Token",currentToken)
      cookie.set('devicetokenmusician', currentToken)
      onMessageListener()

  }
     
  } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
  }
  };
  <ToastContainer position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover />

    // if ("serviceWorker" in navigator) {
    //     navigator.serviceWorker.register(process.env.PUBLIC_URL + "/firebase-messaging-sw.js")
    //     .then(function(registration) {
    //       console.log("Registration successful, scope is:", registration);
    
    //       getToken({messaging, vapidKey: "BM-khnvxAcAj9AjZ71MCRZHjcvZ0cNduKe4Dm5aBVpNYz0-iVf8JqFFiS8hJaV2CW8vjdjg39qJ8KapgWiFll7M",})
    //       .then((currentToken) => {
    //         if (currentToken) {
    //           console.log('current token for client: ', currentToken);
    //         } else {
    //           console.log('No registration token available. Request permission to generate one.');
    //         }
    //       }).catch((err) => {
    //         console.log('An error occurred while retrieving token. ', err);
    //       });
    //     })
    //     .catch(function(err) {
    //       console.log("Service worker registration failed, error:", err);
    //     });
    //     }