import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getChats } from "../../app_redux/slice/chat";
import { getEventChats } from "../../app_redux/slice/chatsEvents";
import { useNavigate, useLocation } from "react-router-dom";
import { getTaskChats } from "../../app_redux/slice/chatTask";
import { getBandChats } from "../../app_redux/slice/bandChat";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import { getEventChatnotification } from "../../app_redux/slice/eventchatnotification";
import { getTaskChatnotification } from "../../app_redux/slice/taskchatnotification";
import { getBandChatnotification } from "../../app_redux/slice/bandchatnotification";
import { getAllnotification } from "../../app_redux/slice/allnotificationcount";
import { useTranslation } from "react-i18next";

import S3FileUpload from "react-s3";
import { chatId } from "../../app_redux/slice/allChat";
window.Buffer = window.Buffer || require("buffer").Buffer;

const Chat = () => {
  const { t } = useTranslation();
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.get("tokenmusician");
  const permissiontype = cookie.get("permission_musician");
  const [chatlistBand, setChatlistBand] = useState([]);
  const [chatlistEvent, setChatlistEvent] = useState([]);
  const [chatlistTask, setChatlistTask] = useState([]);
  const [messages, setMessages] = useState([]);
  const [userinfo, setUserInfo] = useState([]);
  const [messageInfo, setMessageInfo] = useState("");
  const [id, setId] = useState("");
  const [eventId, setEventId] = useState("");
  const [bandId, setBandId] = useState("");
  const [showAll, setShowAll] = useState(true);
  const [showBand, setShowBand] = useState(false);
  const [showBandchat, setShowBandchat] = useState(false);
  const { allEventChats, loading } = useSelector((state) => state.chatevents);
  let { allTaskChats } = useSelector((state) => state.chattask);
  const { allBandChats } = useSelector((state) => state.chatband);
  const { state } = useLocation();
  const [members, setMembers] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [band, setBand] = useState("");
  const [chatName, setChatName] = useState("");
  const [loading1, setLoading1] = useState(false);
  const { eventchatcount } = useSelector((state) => state.eventchatcount);
  const { taskchatcount } = useSelector((state) => state.taskchatcount);
  const { bandchatcount } = useSelector((state) => state.bandchatcount);
  const lng = cookie.get("googtrans");
  const { tab1, tab2 } = t("chat");

  useEffect(() => {
    handleChatListEvent();
    handleChatListTask();
    handleChatListBand();
    dispatch(getEventChatnotification());
    dispatch(getTaskChatnotification());
    dispatch(getBandChatnotification());
    allBands();
  }, []);

  // const taskInterval = setInterval(function () { dispatch(getTaskChats({ taskid: taskId })) }, 3000)

  const allBands = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-bandleader-permission-band-list`,
    }).then((res) => {
      setMembers(res.data.data2);
      // setAllBandleader(res.data.data)
      setBand(res.data.data2[0].band_id);
    });
  };
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }

  const handleBandToken = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/switch-to-band-from-musician`,
      data: {
        band_id: band,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          cookie.set("bandleader_token", res.data.data.auth_token);
          navigate("/chat-list");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const updateeventchatnotification = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-musician-notification-status`,
      data: {
        notification_id: "",
        user_type: 1,
        is_read: 1,
        notification_type: 9,
      },
    }).then((res) => {
      if (res.data.message === "Notification Status Updated Successfully.") {
        dispatch(getEventChatnotification());
        dispatch(getAllnotification());
      }
    });
  };
  const handleFile = (e) => {
    setLoading1(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log("RES", data)
        // setImage(data.location)
        handleSendMessageBand(data.location);
        setLoading1(false);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleFile1 = (e) => {
    setLoading1(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log("RES", data)
        // setImage(data.location)
        handleSendTaskMessage(data.location);
        setLoading1(false);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleFile2 = (e) => {
    setLoading1(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log("RES", data)
        // setImage(data.location)
        handleSendMessage(data.location);
        setLoading1(false);
      })
      .catch((err) => console.error("ERR", err));
  };

  const handleMessageInfo = (e) => {
    setMessageInfo(e.target.value);
  };
  const handleShowall = () => {
    setShowAll(true);
    setShowBand(false);
    setShowBandchat(false);
    setChatName("");
    updateeventchatnotification();
  };
  const handleShowBand = () => {
    setShowAll(false);
    setShowBand(true);
    setShowBandchat(false);
    setChatName("");
    updatetaskchatnotification();
  };
  const handleShowAdmin = () => {
    setShowBandchat(true);
    setShowBand(false);
    setShowAll(false);
    setChatName("");
    updatebandnotification();
  };

  const handleChatListEvent = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-event-chatlist`,
      data: {
        page_no: 1,
        search_text: "",
      },
    }).then((res) => {
      // console.log("res", res.data)
      setChatlistEvent(res.data.data);
    });
  };
  const handleChatListTask = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-task-chat-list`,
      data: {
        page_no: 1,
        search_text: "",
      },
    }).then((res) => {
      // console.log("res", res.data)
      setChatlistTask(res.data.data);
    });
  };
  const handleChatListBand = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-band-membership-list`,
      data: {
        is_all: 0,
      },
    }).then((res) => {
      setChatlistBand(res.data.data);
    });
  };

  const handleEventChat = (id, name) => {
    cookie.set("chatid", id);
    setEventId(id);
    setChatName(name);
    // setInterval(function () { dispatch(getEventChats({ eventid: id })) }, 2000)
    dispatch(getEventChats({ eventid: id }));
    // dispatch(chatId(id))
  };

  const handleTaskChat = (id, name) => {
    console.log("Call id");
    setTaskId(id);
    setChatName(name);
    dispatch(getTaskChats({ taskid: id }));
    // const real = setInterval(function () { dispatch(getTaskChats({ taskid: id })) }, 3000)
    // if (id !== taskId) {
    //     clearInterval(real)
    // }
  };
  const handleBandChat = (id, name) => {
    setBandId(id);
    setChatName(name);
    dispatch(getBandChats({ bandid: id }));
    // setInterval(function () { dispatch(getBandChats({ bandid: id })) }, 3000)
  };
  const handleSend = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: "http://3.131.5.153:4050/v1/send-message",
      data: {
        band_id: id,
        user_type: 1,
        message: messageInfo,
        message_type: "T",
        media_url: "",
      },
    }).then((res) => {
      if (res.data.message === "Message Sent Successfully!.") {
        setMessageInfo("");
        toast.success("Message Sent", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };
  const onKeyPressEvent = (e) => {
    if (e.which === 13) {
      handleSendMessage();
    }
  };
  const valid = () => {
    if (messageInfo.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleSendMessage = (image) => {
    if (valid()) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/send-message`,
        data: {
          task_id: -1,
          band_id: -1,
          event_id: eventId,
          user_type: 1,
          message: messageInfo,
          message_type: "T",
          media_url: "",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setMessageInfo("");
            dispatch(getEventChats({ eventid: eventId }));
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    if (image) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/send-message`,
        data: {
          task_id: -1,
          band_id: -1,
          event_id: eventId,
          user_type: 1,
          message: image ? "" : messageInfo,
          message_type: image ? "I" : "T",
          media_url: image ? image : "",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setMessageInfo("");
            dispatch(getEventChats({ eventid: eventId }));
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  const handleSendMessageBand = (image) => {
    if (validadmin()) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/send-message`,
        data: {
          task_id: -1,
          band_id: bandId,
          event_id: -1,
          user_type: 1,
          message: messageInfo,
          message_type: "T",
          media_url: "",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setMessageInfo("");
            dispatch(getBandChats({ bandid: bandId }));
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    if (image) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/send-message`,
        data: {
          task_id: -1,
          band_id: bandId,
          event_id: -1,
          user_type: 1,
          message: image ? "" : messageInfo,
          message_type: image ? "I" : "T",
          media_url: image ? image : "",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setMessageInfo("");
            dispatch(getBandChats({ bandid: bandId }));
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  const onKeyPressTask = (e) => {
    if (e.which === 13) {
      handleSendTaskMessage();
    }
  };
  const validtask = () => {
    if (messageInfo.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleSendTaskMessage = (image) => {
    if (validtask()) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/send-message`,
        data: {
          event_id: -1,
          band_id: -1,
          task_id: taskId,
          user_type: 1,
          message: "",
          message_type: "T",
          media_url: "",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setMessageInfo("");
            dispatch(getTaskChats({ taskid: taskId }));
            // setInterval(function () { dispatch(getTaskChats({ taskid: taskId })) }, 3000)
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    if (image) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/send-message`,
        data: {
          event_id: -1,
          band_id: -1,
          task_id: taskId,
          user_type: 1,
          message: image ? "" : messageInfo,
          message_type: image ? "I" : "T",
          media_url: image ? image : "",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setMessageInfo("");
            dispatch(getTaskChats({ taskid: taskId }));
            // setInterval(function () { dispatch(getTaskChats({ taskid: taskId })) }, 3000)
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  const onKeyPressAdmin = (e) => {
    if (e.which === 13) {
      handleSendMessageBand();
    }
  };
  const validadmin = () => {
    if (messageInfo.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  const updatebandnotification = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-musician-notification-status`,
      data: {
        notification_id: "",
        user_type: 1,
        is_read: 1,
        notification_type: 16,
      },
    }).then((res) => {
      if (res.data.message === "Notification Status Updated Successfully.") {
        dispatch(getBandChatnotification());
      }
    });
  };
  const updatetaskchatnotification = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-musician-notification-status`,
      data: {
        notification_id: "",
        user_type: 1,
        is_read: 1,
        notification_type: 8,
      },
    }).then((res) => {
      if (res.data.message === "Notification Status Updated Successfully.") {
        dispatch(getTaskChatnotification());
      }
    });
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="bg-white">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 ledtBorder pe-0">
                <div className="">
                  <ul
                    className="nav nav-pills group-nav bandUserTab chatBtnTab mt-0"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item me-3 notranslate">
                      <img
                        src="assets/images/InfoBlack.png"
                        alt=""
                        className="mx-1 mb-2"
                        title={
                          lng === "/auto/de" || "/en/de"
                            ? "Hier finden Sie alle bereits eröffneten Event-Chats, zu welchen Sie eingeladen wurden. Sie können einen Eventchat eröffnen, indem Sie in dem jeweiligen Event den Chat-Button klicken und einen Text senden. Dieser Chat taucht nun in der Auflistung auf."
                            : "Here you will find all the event chats that have already been opened and to which you have been invited. You can open an event chat by clicking the chat button in the respective event and sending a text. This chat now appears in the list."
                        }
                      />
                      <button
                        className={showAll ? "nav-link active" : "nav-link"}
                        onClick={handleShowall}
                      >
                        event{" "}
                        <NotificationBadge
                          count={eventchatcount}
                          effect={Effect.SCALE}
                        />
                      </button>
                    </li>
                    <li className="nav-item me-3">
                      <img
                        src="assets/images/InfoBlack.png"
                        alt=""
                        className="mx-1 mb-2"
                        title={
                          lng === "/auto/de" || "/en/de"
                            ? "Hier finden Sie alle bereits eröffneten Task-Chats, welchen Ihnen von Bands zugeordnet wurden. Sie können einen Task-Chat eröffnen, indem Sie in dem jeweiligen Task den Chat-Button klicken und einen Text senden. Dieser Chat taucht nun in der Auflistung auf."
                            : "Here you will find all task chats that have already been opened and that have been assigned to you by bands. You can open a task chat by clicking the chat button in the respective task and sending a text. This chat now appears in the list."
                        }
                      />
                      <button
                        className={showBand ? "nav-link active" : "nav-link"}
                        onClick={handleShowBand}
                      >
                        task{" "}
                        <NotificationBadge
                          count={taskchatcount}
                          effect={Effect.SCALE}
                        />
                      </button>
                    </li>
                    {permissiontype !== "3" ? (
                      <li className="nav-item me-3">
                        <img
                          src="assets/images/InfoBlack.png"
                          alt=""
                          className="mx-1 mb-2"
                          title={
                            lng === "/auto/de" || "/en/de"
                              ? "Hier finden Sie alle Gruppenchats von Band, bei welchen Sie Mitglied sind. Sollten Sie die Freigabe „Mitarbeiter“ von einer Band erhalten haben, sind Sie nicht berechtigt an dem Gruppenchat dieser Band teilzunehmen und wird Ihnen nicht angezeigt."
                              : "Here you will find all the Band group chats of which you are a member. If you have received the Employee approval from a band, you are not authorized to participate in the group chat of this band and will not be displayed to you."
                          }
                        />
                        <button
                          className={
                            showBandchat ? "nav-link active" : "nav-link"
                          }
                          onClick={handleShowAdmin}
                        >
                          band{" "}
                          <NotificationBadge
                            count={bandchatcount}
                            effect={Effect.SCALE}
                          />
                        </button>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>

                <hr />

                {showAll
                  ? chatlistEvent.map((res, i) => {
                      const input = res.chat_name;
                      return (
                        <div
                          className="chatList px-2"
                          onClick={() =>
                            handleEventChat(res.event_id, res.chat_name)
                          }
                        >
                          <div className="d-flex align-items-center gap-2">
                            <div className="position-relative">
                              <img
                                src={
                                  res?.profile_pic
                                    ? res.profile_pic
                                    : "https://media-appsinvo.s3.amazonaws.com/image2.png"
                                }
                                alt=""
                                className="chatProfile headerImgProfile"
                              />
                              {/* <span className="activeUser"></span> */}
                            </div>
                            <div className="">
                              <h6 className="text-capitalize mb-0 notranslate">
                                {res.chat_name.substr(12, 18)}
                              </h6>
                              <p className="ellip-2 sortMsg notranslate">
                                {res.band_name}
                              </p>

                              <p className="ellip-2 sortMsg notranslate">
                                {res.last_message}
                              </p>
                            </div>
                          </div>
                          {/* <div className="text-center">
                                            <small className="white-space">{res?.last_message_send_receive_at?.substr(11, 12) || ""}</small>
                                        </div> */}
                        </div>
                      );
                    })
                  : ""}

                {showBand
                  ? chatlistTask.map((res, i) => {
                      const input = res.task_chat_name;
                      return (
                        <div
                          className="chatList px-2"
                          onClick={() =>
                            handleTaskChat(res.task_id, res.task_chat_name)
                          }
                        >
                          <div className="d-flex align-items-center gap-2">
                            <div className="position-relative">
                              <img
                                src={
                                  res?.profile_pic
                                    ? res.profile_pic
                                    : "https://media-appsinvo.s3.amazonaws.com/redbgLogo.png"
                                }
                                alt=""
                                className="chatProfile headerImgProfile"
                              />
                              {/* <span className="activeUser"></span> */}
                            </div>
                            <div className="">
                              <h6 className="text-capitalize mb-0 notranslate">
                                {res.task_chat_name?.substr(12, 18)}
                              </h6>
                              <p className="ellip-2 sortMsg notranslate">
                                {res.band_name}
                              </p>

                              <p className="ellip-2 sortMsg">
                                {res.last_message}
                              </p>
                            </div>
                          </div>
                          {/* <div className="text-center">
                                            <small className="white-space">{res?.last_message_send_receive_at?.substr(11, 12) || ""}</small>
                                        </div> */}
                        </div>
                      );
                    })
                  : ""}
                {showBandchat
                  ? chatlistBand.map((res, i) => {
                      return (
                        <div
                          className="chatList px-2"
                          onClick={() =>
                            handleBandChat(res.band_id, res.band_name)
                          }
                        >
                          <div className="d-flex align-items-center gap-2">
                            <div className="position-relative">
                              <img
                                src={
                                  res?.profile_pic
                                    ? res.profile_pic
                                    : "https://media-appsinvo.s3.amazonaws.com/redbgLogo.png"
                                }
                                alt=""
                                className="chatProfile headerImgProfile"
                              />
                              {/* <span className="activeUser"></span> */}
                            </div>
                            <div className="">
                              <h6 className="text-capitalize mb-0 notranslate">
                                {res.band_name}
                              </h6>
                              {/* <p className="ellip-2 sortMsg">{res.band_name}</p>

                                                <p className="ellip-2 sortMsg">{res.last_message}</p> */}
                            </div>
                          </div>
                          {/* <div className="text-center">
                                            <small className="white-space">{res?.last_message_send_receive_at?.substr(11, 12) || ""}</small>
                                        </div> */}
                        </div>
                      );
                    })
                  : ""}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 ps-0 position-relative">
                {members?.length > 0 ? (
                  <div
                    className="d-flex justify-content-end"
                    style={{ cursor: "pointer" }}
                  >
                    <h5 onClick={handleBandToken}>
                      {" "}
                      <img
                        src="assets/images/transfer1245.png"
                        className="mx-2"
                        alt=""
                        style={{ height: "30px" }}
                      />
                      {tab2}{" "}
                      <img
                        src="assets/images/InfoBlack.png"
                        alt=""
                        className="mx-1 mb-5"
                        title={
                          lng === "/auto/de" || "/en/de"
                            ? "Wenn Sie eine Band über Ihren Benutzeraccount erstellt haben, können Sie hier ganz einfach in den Chat-Account der Band wechseln, ohne das Profil wechseln zu müssen."
                            : "If you have created a band using your user account, you can easily switch to the band's chat account here without having to switch profiles."
                        }
                      />
                    </h5>{" "}
                  </div>
                ) : (
                  ""
                )}
                <h5 className="text-center theme-color px-2 pt3 mb-0">
                  {tab1}
                </h5>

                <h6 className="text-center notranslate">{chatName}</h6>
                {/* <img src="assets/images/delete.png" className="chatDelete" alt="" /> */}
                <hr />
                <div className="messageChat ps-3">
                  {showAll
                    ? allEventChats.map((res, i) => {
                        return (
                          <div className="notranslate">
                            {/* {res.user_type === 2 ? <><img src={userinfo[0]?.profile_pic ? userinfo[0].profile_pic : "https://media-appsinvo.s3.amazonaws.com/image2.png"} alt="" className="chatProfile headerImgProfile chatImg" /> <b>{userinfo[0].band_name}</b></> : ""} */}
                            {res.user_type === 2 ? (
                              <div className="chatText chatTextWidthWhite">
                                <b
                                  style={{ textTransform: "uppercase" }}
                                  className="notranslate"
                                >
                                  {res.band_name}{" "}
                                </b>
                                <p className="notranslate mt-2">
                                  {res.message}
                                </p>
                                {res.media_url ? (
                                  <a href={res.media_url} target="_blank">
                                    {" "}
                                    <img
                                      className="mb-0 d-flex align-items-center gap-3 justify-content-end"
                                      src={res.media_url}
                                      alt=""
                                      style={{ width: "300px" }}
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                                <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">
                                  {res?.created_at
                                    ? new Date(res?.created_at).toLocaleString()
                                    : "-"}{" "}
                                  <img
                                    src="assets/images/blackDots.png"
                                    alt=""
                                    className=""
                                  />
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {res.user_type === 1 ? (
                              <>
                                <div
                                  className={
                                    res.message_type === "T"
                                      ? "chatText chatTextWidthRed"
                                      : "chatText chatTextWidthImage"
                                  }
                                >
                                  <b style={{ textTransform: "uppercase" }}>
                                    {res.first_name} {res.last_name}
                                  </b>
                                  <p className="mt-2 notranslate">
                                    {res.message}
                                  </p>
                                  {res.media_url ? (
                                    <img
                                      className="mb-0 d-flex align-items-center gap-3 justify-content-end"
                                      src={res.media_url}
                                      alt=""
                                      style={{ width: "300px" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">
                                    {res?.created_at
                                      ? new Date(
                                          res?.created_at
                                        ).toLocaleString()
                                      : "-"}{" "}
                                    <img
                                      src="assets/images/whiteDots.png"
                                      alt=""
                                      className=""
                                    />
                                  </p>
                                </div>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })
                    : ""}

                  {showBand
                    ? allTaskChats.map((res, i) => {
                        return (
                          <div className="notranslate">
                            {/* {res.user_type === 2 ? <><img src={userinfo[0]?.profile_pic ? userinfo[0].profile_pic : "https://media-appsinvo.s3.amazonaws.com/image2.png"} alt="" className="chatProfile headerImgProfile chatImg" /> <b>{userinfo[0].band_name}</b></> : ""} */}
                            {res.user_type === 2 ? (
                              <div className="chatText chatTextWidthWhite">
                                <b
                                  style={{ textTransform: "uppercase" }}
                                  className="notranslate"
                                >
                                  {res.band_name}{" "}
                                </b>
                                {res.media_url ? (
                                  <a href={res.media_url} target="_blank">
                                    {" "}
                                    <img
                                      className="mb-0 d-flex align-items-center gap-3 justify-content-end"
                                      src={res.media_url}
                                      alt=""
                                      style={{ width: "300px" }}
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                                <p className="notranslate">{res.message}</p>

                                <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">
                                  {res?.created_at
                                    ? new Date(res?.created_at).toLocaleString()
                                    : "-"}
                                  <img
                                    src="assets/images/blackDots.png"
                                    alt=""
                                    className=""
                                  />
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {res.user_type === 1 ? (
                              <div
                                className={
                                  res.message_type === "T"
                                    ? "chatText chatTextWidthRed"
                                    : "chatText chatTextWidthImage"
                                }
                              >
                                <b style={{ textTransform: "uppercase" }}>
                                  {res.first_name} {res.last_name}
                                </b>
                                <p className="mt-2 notranslate">
                                  {res.message}
                                </p>
                                {res.media_url ? (
                                  <a href={res.media_url} targte="_blank">
                                    {" "}
                                    <img
                                      className="mb-0 d-flex align-items-center gap-3 justify-content-end"
                                      src={res.media_url}
                                      alt=""
                                      style={{ width: "300px" }}
                                    />{" "}
                                  </a>
                                ) : (
                                  ""
                                )}
                                <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">
                                  {res?.created_at
                                    ? new Date(res?.created_at).toLocaleString()
                                    : "-"}
                                  <img
                                    src="assets/images/whiteDots.png"
                                    alt=""
                                    className=""
                                  />
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })
                    : ""}
                  {showBandchat
                    ? allBandChats.map((res, i) => {
                        return (
                          <div className="notranslate">
                            {/* {res.user_type === 2 ? <><img src={userinfo[0]?.profile_pic ? userinfo[0].profile_pic : "https://media-appsinvo.s3.amazonaws.com/image2.png"} alt="" className="chatProfile headerImgProfile chatImg" /> <b>{userinfo[0].band_name}</b></> : ""} */}
                            {res.user_type === 2 ? (
                              <div className="chatText chatTextWidthWhite">
                                <b
                                  style={{ textTransform: "uppercase" }}
                                  className="notranslate"
                                >
                                  {res.band_name}{" "}
                                </b>
                                {res.media_url ? (
                                  <a href={res.media_url} target="_blank">
                                    {" "}
                                    <img
                                      className="mb-0 d-flex align-items-center gap-3 justify-content-end"
                                      src={res.media_url}
                                      alt=""
                                      style={{ width: "300px" }}
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                                <p>{res.message}</p>
                                <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">
                                  {res?.created_at
                                    ? new Date(res?.created_at).toLocaleString()
                                    : "-"}
                                  <img
                                    src="assets/images/blackDots.png"
                                    alt=""
                                    className=""
                                  />
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {res.user_type === 1 ? (
                              <div
                                className={
                                  res.message_type === "T"
                                    ? "chatText chatTextWidthRed"
                                    : "chatText chatTextWidthImage"
                                }
                              >
                                <b style={{ textTransform: "uppercase" }}>
                                  {res.first_name} {res.last_name}
                                </b>
                                <p className="mt-2">{res.message}</p>
                                {res.media_url ? (
                                  <a href={res.media_url} target="_blank">
                                    {" "}
                                    <img
                                      className="mb-0 d-flex align-items-center gap-3 justify-content-end"
                                      src={res.media_url}
                                      alt=""
                                      style={{ width: "300px" }}
                                    />{" "}
                                  </a>
                                ) : (
                                  ""
                                )}
                                <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">
                                  {res?.created_at
                                    ? new Date(res?.created_at).toLocaleString()
                                    : "-"}
                                  <img
                                    src="assets/images/whiteDots.png"
                                    alt=""
                                    className=""
                                  />
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })
                    : ""}

                  {loading1 ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}

                  {showAll ? (
                    <div className="ChatSendInput">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          onKeyPress={onKeyPressEvent}
                          value={messageInfo}
                          onChange={handleMessageInfo}
                          placeholder={
                            lng === "/auto/de" || "/en/de"
                              ? "Schreibe eine Nachrich"
                              : "Write a message"
                          }
                        />
                        <div className="InputIconRight d-flex">
                          <div className="upload-btn-wrapper Attachment">
                            <img
                              src="assets/images/gallery.png"
                              width="25px"
                              alt=""
                            />
                            <input type="file" onChange={handleFile2} />
                          </div>
                          <img
                            src="assets/images/send-message.png"
                            alt=""
                            className="iconImages mx-2"
                            onClick={handleSendMessage}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {showBand ? (
                    <div className="ChatSendInput">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          onKeyPress={onKeyPressTask}
                          value={messageInfo}
                          onChange={handleMessageInfo}
                          placeholder={
                            lng === "/auto/de" || "/en/de"
                              ? "Schreibe eine Nachrich"
                              : "Write a message"
                          }
                        />
                        <div className="InputIconRight d-flex">
                          <div className="upload-btn-wrapper Attachment">
                            <img
                              src="assets/images/gallery.png"
                              width="25px"
                              alt=""
                            />
                            <input type="file" onChange={handleFile1} />
                          </div>
                          <img
                            src="assets/images/send-message.png"
                            alt=""
                            className="iconImages mx-2"
                            onClick={handleSendTaskMessage}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {showBandchat ? (
                    <div className="ChatSendInput">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          value={messageInfo}
                          onChange={handleMessageInfo}
                          onKeyPress={onKeyPressAdmin}
                          placeholder={
                            lng === "/auto/de" || "/en/de"
                              ? "Schreibe eine Nachrich"
                              : "Write a message"
                          }
                        />
                        <div className="d-flex InputIconRight">
                          {/* <input type="file" onChange={handleFile} /> */}
                          <div className="upload-btn-wrapper Attachment">
                            <img
                              src="assets/images/gallery.png"
                              width="25px"
                              alt=""
                            />
                            <input type="file" onChange={handleFile} />
                          </div>
                          <img
                            src="assets/images/send-message.png"
                            alt=""
                            className="iconImages mx-2"
                            onClick={handleSendMessageBand}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Chat;
