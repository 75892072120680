import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'


const AboutUs = () => {
    const cookie = new Cookies();
    const lng = cookie.get("googtrans")
    const [data, setData] = useState([])
    const [data2, setData2] = useState([])

    useEffect(() => {
        getInfo()
    }, [])

    const getInfo = () => {
        axios({
            method: 'get',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",

            },
            url: `${process.env.REACT_APP_URL}/get-cms-details?type=aboutUs`,

        })
            .then(res => {
                setData(res.data.data.german_text)
                setData2(res.data.data.english_text)
            })
    }

    return (

        <>
            <img src="assets/images/cmsimg.png" className="img-fluid w-100" alt="" />
            <div className="container position-relative">
                <h3 className="titleAbso" style={{ textTransform: "capitalize" }}>Impressum</h3>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mb-5">
                        {lng === "/auto/de" || "/en/de" ? < div className="mb-0" dangerouslySetInnerHTML={{ __html: data }} /> : < div className="mb-0" dangerouslySetInnerHTML={{ __html: data2 }} />}
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutUs