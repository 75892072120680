import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate, Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import { useSelector, useDispatch } from 'react-redux';
import { getEventChatnotification } from '../../app_redux/slice/eventchatnotification';
import { getAllnotification } from '../../app_redux/slice/allnotificationcount';
import { getTaskChatnotification } from '../../app_redux/slice/taskchatnotification';
import DeleteAccount from './deleteAccount';
import { getMusicianDetail } from '../../app_redux/slice/musicianInfo';
import { useTranslation } from 'react-i18next'
import { getCounts } from '../../app_redux/slice/notificationCount';


const Header = ({ switchtoken }) => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation()
    const cookie = new Cookies();
    const dispatch = useDispatch()
    const token = cookie.get('tokenmusician')
    const [deleteShow, setDeleteShow] = useState(false)
    const [notification, setNotification] = useState([])
    const { allcount } = useSelector((state) => state.allnotificationcount)
    const lng = cookie.get('googtrans')
    const queryParams = new URLSearchParams(window.location.search)

    const { musicianDetail } = useSelector((state) => state.musicianinfo)
    const tokenquery = queryParams.get("token")
    const { tab1, tab2, tab3, tab4, tab5, tab6 } = t("setting")
    const { counts } = useSelector((state) => state.allCounts)


    useEffect(() => {
        getNotification()
        dispatch(getAllnotification())
        dispatch(getCounts())
    }, [])

    const handleChangeLanguage = () => {
        console.log("langu", i18n.language)
        i18n.changeLanguage(i18n.language === "en" ? "de" : "en")
    }
    useEffect(() => {
        if (tokenquery) {
            cookie.set('tokenmusician', tokenquery)
            dispatch(getMusicianDetail())
        }
        else {
            dispatch(getMusicianDetail())
        }
    }, [tokenquery])

    useEffect(() => {
        if (musicianDetail) {
            cookie.set('phonenumbermusician', musicianDetail.phone_number)

        }
    }, [musicianDetail])


    const handleDelete = () => {
        setDeleteShow(!deleteShow)
    }
    const handleLogout = () => {
        cookie.remove('tokenmusician')
        cookie.remove('emailmusician')
        cookie.remove('firstnamemusician')
        cookie.remove('lastnamemusician')
        cookie.remove('profilepicmusician')
        cookie.remove('phonenumbermusician')
        cookie.remove('devicetokenmusician')
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/log-out-musician`,
            data:
            {
                "device_token": "AS"
            }
        })
            .then(res => {
                // console.log("res", res.data)
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: "colored",
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    cookie.remove('tokenmusician')
                    cookie.remove('emailmusician')
                    cookie.remove('firstnamemusician')
                    cookie.remove('lastnamemusician')
                    cookie.remove('profilepicmusician')
                    cookie.remove('phonenumbermusician')
                    navigate("/login")
                }
            })
    }


    const getNotification = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-notification-list`,
            data:
            {
                "page_no": 1,
                "user_type": 1
            }
        })
            .then(res => {
                setNotification(res.data.data)
                dispatch(getEventChatnotification())
                dispatch(getTaskChatnotification())
                dispatch(getAllnotification())
            })
    }
    const handleNotificationRedirect = (type, id) => {
        const data = Object.values(id)
        if (type === 1) {
            handleEventClick(data[0])
        }
        if (type === 7) {
            handleClick(data[0])
        }
        if (type === 9) {
            handleChat(data[0])
        }
        if (type === 8) {
            handleTaskChat(data[0])
        }
    }
    const handleTaskChat = (id) => {
        navigate("/chat-task", { state: { task_id: id } })
    }
    const handleChat = (id) => {
        navigate("/chat-event", { state: { eventid: id } })

    }
    const handleClick = (id) => {
        localStorage.setItem("taskid", id)
        navigate('/task-overview')
    }
    const handleEventClick = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-musician-event-details`,
            data:
            {
                "event_id": id
            }
        })
            .then(res => {
                //console.log("RESPONSE", res.data)
                if (res.data.message === "Event Details!.") {
                    localStorage.setItem("eventdata", JSON.stringify(res.data.data))
                    navigate("/appointment-detail")
                }
            })
    }


    return (
        <>
            <div className="bg-black p-3 d-flex justify-content-between align-items-center fixed-top">
                <div className="d-flex gap-2 align-items-center text-white">

                    {/* {profilepic ? <img src={switchtoken ? "" : profilepic} className="headerImgProfile" alt="" /> : ""} */}
                    {musicianDetail?.profile_pic ? <img src={musicianDetail?.profile_pic} className="headerImgProfile" alt="" /> : ""}
                    <span>
                        <h6 className="text-capitalize mb-0 notranslate">{musicianDetail?.first_name} {musicianDetail?.last_name || "NA"}</h6>
                        <p className="mb-0 notranslate">{musicianDetail?.email || ""}</p>
                    </span>
                </div>
                <span className="navbar-brand bg-none d-lg-block">
                    <Link to="/appointments">  <img src="assets/images/logoWhite.png" className="img-fluid" alt="img" /> </Link>
                </span>
                <div className="d-flex gap-2 align-items-center">

                    <div className="position-relative filterDrop">
                        <div>
                            <NotificationBadge count={allcount} effect={Effect.SCALE} />
                            <img src="assets/images/notifi.png" className="headerImg cursor-pointer" alt="" />
                        </div>

                        <div className="filterDropSelect">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h3>Latest Notification</h3>

                                </div>
                            </div>
                            <div className="mt-3 notificationsall">
                                {notification?.length !== 0 ? notification.map((res, i) => {
                                    return <div className="notiList" style={{ cursor: "pointer" }}>
                                        <p onClick={() => handleNotificationRedirect(res.notification_type, res.notification_data)}>{res.title}  {res?.created_at
                                            ? new Date(
                                                res?.created_at
                                            ).toLocaleString()
                                            : "-"}</p>
                                    </div>
                                }) : ""}

                            </div>
                            {notification?.length > 6 ?
                                <div>
                                    <Link to="/all-notification"> <p className='text-center mt-3'>View all</p></Link>
                                </div> : ""}

                        </div>
                    </div>
                    <button className="btn btn-blue float-end menu-toggle d-lg-none" data-bs-target="#sidebar" data-bs-toggle="collapse"> <i className="fa fa-bars"></i></button>

                    <div className="position-relative filterDrop">
                        <img src="assets/images/settings.png" className="headerImg" alt="" />
                        <div className="filterDropSelect settingDropSelect">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h3>Settings</h3>
                                </div>
                            </div>
                            <div className="notiList mt-2">
                                <Link to="/appointments" className='notranslate' style={{ color: "gray" }}>Home</Link>
                            </div>
                            <div className="notiList mt-2">
                                <Link to="/profile" style={{ color: "gray" }}>{tab1}</Link>
                            </div>
                            <div className="notiList mt-2">
                                <Link to='/term&condition' style={{ color: "gray" }}>{tab2}</Link>
                            </div>
                            <div className="notiList mt-2">
                                <Link to='/privacyPolicy' style={{ color: "gray" }}>{tab3}</Link>
                            </div>

                            <div className="notiList mt-2">
                                <Link to="/aboutUs" style={{ color: "gray" }}>{tab4}</Link>
                            </div>


                            {/* <div className="notiList mt-2">
                                <Link to="" style={{ color: "gray" }}>Change Language</Link>
                            </div> */}
                            <div className="notiList mt-2">
                                <Link to="/change-password" style={{ color: "gray" }}>{tab5}</Link>
                            </div>
                            <div className="notiList mt-3" onClick={handleDelete}>
                                <span style={{ color: "gray" }}>{tab6}</span>
                                {/* <button className="btn btn-save w-75" onClick={handleLogout} >Delete Account</button> */}
                            </div>
                            <div className="mt-3">
                                {/* <Link to="" style={{color:"gray"}}>Logout & Exit"</Link> */}
                                <button className="btn btn-save w-75" onClick={handleLogout} >Logout</button>
                            </div>

                        </div>
                    </div>
                    <img src={i18n.language === "en" ? "assets/images/english-select.png" : "assets/images/german-select.png"} alt="" className="img-fluid" onClick={handleChangeLanguage} />
                </div>

            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            {deleteShow ? <DeleteAccount show={deleteShow} handleShow={handleDelete} /> : ""}
        </>
    )
}

export default Header