import * as Yup from "yup";

export const contactUsSchema = Yup.object().shape({
    firstname: Yup.string().min(2).max(15).required("Please enter first name."),
    lastname: Yup.string().min(2).max(15).required("Please enter surname."),
    projectname: Yup.string().min(2).max(45).required("Please enter."),
    email: Yup.string().email().required("Please enter your email."),
    news: Yup.string().min(2).max(255).required("Required."),


});