import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import S3FileUpload from "react-s3";
import Select from "react-select";
import countryList from "react-select-country-list";
import { getMusicianInvites } from "../../app_redux/slice/musicianInvites";
import { useDispatch, useSelector } from "react-redux";
import { getMembershipList } from "../../app_redux/slice/membershipList";
import ConfirmAbsentModal from "./confirmAbsent";
// import PhoneInput from 'react-phone-number-input'
import { useFormik } from "formik";
import { markabsentSchema } from "../Schemas/markabsentschema";
// import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InfoModal from "./infoModal";
import PushNotificationModal from "./pushNotificationModal";
import { getMusicianDetail } from "../../app_redux/slice/musicianInfo";
import { useTranslation } from "react-i18next";
import DeleteMembershipModal from "./deleteMembership";
import ButtonLoader from "../Loader/loader";
import DataLoader from "../Loader/dataLoader";

window.Buffer = window.Buffer || require("buffer").Buffer;

const initialValues = {
  countryCode: "",
  mobile: "",
};
const Profile = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const token = cookee.get("tokenmusician");
  const [profileData, setProfileData] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [dob, setDob] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [freeseat, setFreeseat] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [image, setImage] = useState("");
  const [gemaMembership, setGemaMembership] = useState("");
  const [ipiNumber, setIpiNumber] = useState("");
  const [diet, setDiet] = useState("");
  const [housenumber, setHouseNumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [town, setTown] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const { allMembership } = useSelector((state) => state.membership);
  const [loading, setLoading] = useState(false);
  const [showAbsentModal, setShowAbsentModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const [value, setValue] = useState();
  const [info, setInfo] = useState("");
  const [data2, setData2] = useState("");
  const [loader, setLoader] = useState("");
  const [pushNotification, setPushNotification] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [notification, setNotification] = useState("");
  const [emailNotify, setEmailNotify] = useState("");
  const [deleteMembership, setDeleteMembership] = useState(false);
  const [staffId, setStaffId] = useState("");
  const [bandId, setBandId] = useState("");
  const [sms, setSms] = useState("");
  const lng = cookee.get("googtrans");
  const {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6,
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab12,
    tab13,
    tab14,
    tab15,
    tab16,
    tab17,
    tab18,
  } = t("profile");

  var german = require("localized-countries")(
    require("localized-countries/data/de")
  );
  const options = german.array();

  const { values, handleBlur, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: markabsentSchema,
    onSubmit: () => {
      showAbsent();
    },
  });

  useEffect(() => {
    getMusicianInfo();
    handleCreator();
    dispatch(getMusicianInvites());
    dispatch(getMembershipList());
  }, []);

  const handleDelete = (s, b) => {
    setStaffId(s);
    setBandId(b);
    setDeleteMembership(!deleteMembership);
  };

  const handlePushNotification = () => {
    setPushNotification(!pushNotification);
  };
  const handleCreator = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-band-membership-list`,
      data: {
        is_all: 1,
      },
    }).then((res) => {
      setData2(res.data.data2);
    });
  };
  const handleShowAbsent = () => {
    setShowAbsentModal(!showAbsentModal);
  };

  const handleShowInfo = () => {
    setShowInfoModal(!showInfoModal);
  };

  const showAbsent = () => {
    handleShowAbsent();
  };

  const handleFile = (e) => {
    setLoader(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        //  console.log("RES", data)
        setImage(data.location);
        setLoader(false);
      })
      .catch((err) => console.error("ERR", err));
  };
  const changeHandler = (value) => {
    // console.log("country", value)
    setCountry(value.label);
  };
  const getMusicianInfo = (flag) => {
    setLoader(true);
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-profile`,
    }).then((res) => {
      setLoader(false);
      cookee.set("permission_musician", res.data.data.permission_type);
      setProfileData(res.data.data);
      setFirstName(res.data.data.first_name);
      setLastName(res.data.data.last_name);
      setEmail(res.data.data.email);
      setPhone(res.data.data.phone_number);
      setValue(res.data.data.phone_number?.toString());
      setCity(res.data.data.city);
      setCountry(res.data.data.country);
      setDob(res.data.data.dob?.substr(0, 10));
      setFreeseat(res.data.data.free_seats);
      setVehicle(res.data.data.vehicle);
      setProfilePic(res.data.data.profile_pic);
      setGemaMembership(res.data.data.gema_membership_number);
      setIpiNumber(res.data.data.gema_ipi_number);
      setDiet(res.data.data.nutrition);
      setStreet(res.data.data.street);
      setTown(res.data.data.town);
      setZipcode(res.data.data.zip_code);
      setHouseNumber(res.data.data.house_number);
      setCountryCode(res.data.data.country_code);
      setFieldValue("countryCode", res.data.data.country_code);
      setFieldValue("mobile", res.data.data.phone_number);
      setNotification(res.data.data.is_notification);
      setSms(res.data.data.is_text_message);
      setEmailNotify(res.data.data.is_email);
      if (flag === "flag") {
        window.location.reload();
      }
    });
  };

  const handleChanges = (e, setState) => {
    setState(e.target.value);
  };
  const handleDiet = (e) => {
    setDiet(e.target.value);
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-musician-profile`,
      data: {
        profile_pic: image ? image : profilePic,
        first_name: firstName,
        last_name: lastName,
        email: email,
        country_code: values.countryCode,
        phone_number: values.mobile,
        city: town,
        country: country,
        street: street,
        town: town,
        house_number: housenumber,
        birth_town: "Noida",
        birth_country: "India",
        zip_code: zipcode,
        dob: dob,
        nutrition: diet,
        gema_ipi_number: ipiNumber,
        gema_membership_number: gemaMembership,
        language: "EN",
      },
    })
      .then((res) => {
        // console.log("RESPONSE", res.data)
        if (res.status === 200) {
          dispatch(getMusicianDetail());
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      });
  };

  return (
    <>
      {loader ? (
        <DataLoader />
      ) : (
        <article className="col-lg-9">
          <div className="position-relative mt-56">
            <img
              src="assets/images/profileBanner.png"
              className="img-fluid w-100"
              alt=""
            />
          </div>
          <div className="article-body store-bd-gy mt-0">
            <div className="ProfilBox">
              <h4 className="text-white mb-3">{tab1}</h4>
              <div className="bg-white p-4 rounded">
                <form className="row mt-5 addNAme mx-width">
                  <div className="col-sm-8 col-md-8 col-lg-8 mb-5">
                    {loader ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div className="profilePhoto position-static">
                        <div className="d-flex gap-2 align-items-center">
                          {profilePic ? (
                            <label for="image">
                              <input
                                type="file"
                                name="image"
                                id="image"
                                onChange={handleFile}
                                style={{ display: "none" }}
                              />
                              <img
                                src={image ? image : profilePic}
                                className="profileIMg"
                                alt=""
                              />
                            </label>
                          ) : (
                            <label for="image">
                              <input
                                type="file"
                                name="image"
                                id="image"
                                onChange={handleFile}
                                style={{ display: "none" }}
                              />
                              <img
                                src={
                                  image
                                    ? image
                                    : "assets/images/Profile_pic.png"
                                }
                                className="profileIMg"
                                alt=""
                              />
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-4 col-md-4 col-md-4 mb-5 mt-4">
                    <p className="btn add-new" onClick={handlePushNotification}>
                      {tab7}{" "}
                      <img
                        src="assets/images/Infowhite.png"
                        alt=""
                        className="mx-1 mb-5"
                        title={
                          lng === "/auto/de" || "/en/de"
                            ? "Hier haben Sie die Möglichkeit, die Benachrichtigungen zu verwalten."
                            : "Here you have the possibility to manage the notifications."
                        }
                      />{" "}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      name="firstname"
                      value={firstName}
                      onChange={(e) => handleChanges(e, setFirstName)}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      value={lastName}
                      onChange={(e) => handleChanges(e, setLastName)}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      value={street}
                      onChange={(e) => handleChanges(e, setStreet)}
                      placeholder={tab2}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        name="housenumber"
                        value={housenumber}
                        onChange={(e) => handleChanges(e, setHouseNumber)}
                        placeholder={tab3}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        name="zipcode"
                        value={zipcode}
                        onChange={(e) => handleChanges(e, setZipcode)}
                        placeholder={tab4}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={town}
                        onChange={(e) => handleChanges(e, setTown)}
                        placeholder={tab5}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 mb-5">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        value={country}
                        placeholder={tab6}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 mb-5">
                    <div className="position-relative">
                      <Select
                        className="form-control p-0"
                        options={options}
                        placeholder="Country"
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div className="position-relative">
                      <PhoneInput
                        country="DE"
                        inputProps={{
                          name: "mobile",
                        }}
                        enableSearch
                        value={values.countryCode + values.mobile}
                        onChange={(phone, country, e, formattedValue) => {
                          setCountryData({ formattedValue, country });
                          if (phone.length > 0) {
                            const raw = phone.slice(country?.dialCode?.length);
                            setFieldValue("mobile", raw);
                            setFieldValue(
                              "countryCode",
                              formattedValue?.split(" ")[0]
                            );
                          } else {
                            setFieldValue("mobile", "");

                            setFieldValue("countryCode", "");
                          }
                        }}
                        onBlur={handleBlur}
                        placeholder="Contact number"
                        containerclassName="form-control signupClass"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={(e) => handleChanges(e, setEmail)}
                        placeholder="Email"
                      />
                      <img
                        src="assets/images/arroba.png"
                        alt=""
                        className="inputImgRight"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div className="position-relative">
                      <input
                        type="date"
                        className="form-control"
                        name="dob"
                        value={dob}
                        onChange={(e) => handleChanges(e, setDob)}
                        placeholder="Date of Birth"
                      />
                      {/* <img src="assets/images/calendarGrey.png" alt="" className="inputImgRight" /> */}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        name="gemaMembership"
                        value={gemaMembership}
                        onChange={(e) => handleChanges(e, setGemaMembership)}
                        placeholder={tab10}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div class="nutritionRadio">
                      <label>{tab8} :</label>
                      <p className="mb-0">
                        <input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          value="vegetarian"
                          onChange={handleDiet}
                          checked={diet === "vegetarian"}
                        />
                        <label for="test1">{tab9}</label>
                      </p>
                      <p className="mb-0">
                        <input
                          type="radio"
                          id="test2"
                          name="radio-group"
                          value="vegan"
                          onChange={handleDiet}
                          checked={diet === "vegan"}
                        />
                        <label for="test2">Vegan</label>
                      </p>
                      <p className="mb-0">
                        <input
                          type="radio"
                          id="test3"
                          name="radio-group"
                          value="fish/meat"
                          onChange={handleDiet}
                          checked={diet === "fish/meat"}
                        />
                        <label for="test3">{tab17}</label>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        name="ipiNumber"
                        value={ipiNumber}
                        onChange={(e) => handleChanges(e, setIpiNumber)}
                        placeholder="Gema Ipi Number"
                      />
                      <img
                        src="assets/images/armchair.png"
                        alt=""
                        className="inputImgRight"
                      />
                    </div>
                  </div>

                  <div className="pt-3 px-1 bg-white mb-4">
                    <h4>
                      Bands{" "}
                      <img
                        src="assets/images/InfoBlack.png"
                        alt=""
                        className="mx-1 mb-5"
                        title={
                          lng === "/auto/de" || "/en/de"
                            ? "Hier finden Sie die Auflistung aller Bands, in welcher Sie Mitglied sind oder gegründet haben."
                            : "Here you will find a list of all bands in which you are a member or have founded."
                        }
                      />
                    </h4>
                    <div className="table-responsive mt-2">
                      <table className="table storetable ">
                        <thead className="">
                          <tr>
                            <th scope="col">Band name</th>
                            <th scope="col">{tab11}</th>
                            <th scope="col">Status</th>
                            <th scope="col">
                              {tab12}{" "}
                              <img
                                src="assets/images/InfoBlack.png"
                                alt=""
                                className="mx-1 mb-5"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Hier können Sie eine getätigte Mitgliedsanfrage wieder löschen"
                                    : "Here you can delete a member request that has been made"
                                }
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data2 && data2 !== undefined ? (
                            <tr>
                              <td>
                                <h5 className="notranslate">
                                  {data2.band_name}
                                </h5>
                              </td>
                              <td className="text-center">
                                <h6 style={{ color: "green" }}>
                                  {data2.band_name ? "Creator" : ""}
                                </h6>
                              </td>
                              <td></td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {allMembership.length !== 0
                            ? allMembership.map((res, i) => {
                                return (
                                  <tr>
                                    <td className="notranslate">
                                      {" "}
                                      <h5>{res.band_name}</h5>
                                    </td>
                                    <td className="notranslate">
                                      {" "}
                                      <span className="StatusBtn permissionStatus">
                                        {" "}
                                        {res.permission || "NA"}{" "}
                                      </span>
                                    </td>
                                    <td>
                                      {res?.is_approved_band === 0 ? (
                                        <span className="StatusBtn pendingStatus">
                                          Pending
                                        </span>
                                      ) : (
                                        <span className="StatusBtn approvedStatus">
                                          Accepted{" "}
                                        </span>
                                      )}
                                    </td>
                                    <td style={{ cursor: "pointer" }}>
                                      <img
                                        src="assets/images/delete.png"
                                        width="25px"
                                        alt=""
                                        onClick={(e) =>
                                          handleDelete(
                                            res.staff_id,
                                            res.band_id
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div className="pt-3 px-1 bg-white mb-5">
                                    <h4>Band Invitations</h4>
                                    <div className="table-responsive mt-2">
                                        <table className="table storetable ">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col"><h6>Band name</h6></th>
                                                    <th scope="col"><h6>Permission</h6></th>
                                                    <th scope="col" className="text-center"><h6>Information</h6></th>
                                                    <th scope="col" className="text-center"></th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {allMusicianInvites?.length !== 0 ? allMusicianInvites.map((res, i) => {
                                                    return <tr>
                                                        <td> <h6 className='notranslate'>{res.band_name}</h6> </td>
                                                        <td><h6 className='notranslate'>{res.permission}</h6></td>
                                                        <td className="text-center" onClick={(e) => showInfo(e, res.description)}><img src="assets/images/information.png" width="25px" alt="" /></td>
                                                        <td><button className='btn  add-new  ' style={{ background: 'gray', border: "none", width: "100px" }} onClick={(e) => handleAccept(e, res.staff_id, res.band_id)} >accept</button>
                                                            <button className='btn add-new ' onClick={(e) => handleReject(e, res.staff_id, res.band_id)} style={{ width: "100px" }}>reject</button>
                                                        </td>
                                                    </tr>
                                                }) : <p className='mt-3'>No Invite Found !!</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}

                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div className="d-flex justify-content-center my-3">
                      {loading ? (
                        <ButtonLoader view="btn add-new" />
                      ) : (
                        <button
                          className="btn add-new"
                          onClick={handleUpdateProfile}
                        >
                          {tab13}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </article>
      )}
      {showAbsentModal ? (
        <ConfirmAbsentModal
          show={showAbsentModal}
          handleShow={handleShowAbsent}
          date={values.startdate}
          date2={values.enddate}
        />
      ) : (
        ""
      )}
      {showInfoModal ? (
        <InfoModal
          show={showInfoModal}
          handleShow={handleShowInfo}
          info={info}
        />
      ) : (
        ""
      )}
      {pushNotification ? (
        <PushNotificationModal
          show={pushNotification}
          handleShow={handlePushNotification}
          getMusicianInfo={getMusicianInfo}
          notification={notification}
          sms={sms}
          emailNotify={emailNotify}
          tab14={tab14}
          tab15={tab15}
          tab16={tab16}
          tab18={tab18}
        />
      ) : (
        ""
      )}

      {deleteMembership ? (
        <DeleteMembershipModal
          show={deleteMembership}
          handleShow={handleDelete}
          staffId={staffId}
          bandId={bandId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Profile;
