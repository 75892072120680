import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom';
import { resetPasswordSchema } from '../Schemas/resetPasswordSchema';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'

const initialValues = {
    newpassword: "",
    confirmpassword: ""
}
const ResetPassword = () => {
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    let navigate = useNavigate()
    const email = localStorage.getItem("email")
    // console.log("email", email)
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, action) => {
            //  console.log("data", values)
            handleResetPassword()
        }
    })
    const handleResetPassword = () => {
        //  e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/reset-password`,
            data:
            {

                "phone_number": "",
                "email": email,
                "new_password": values.newpassword
            }
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate("/login")

                }

            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const handleShow = () => {
        setShow(!show)
    }
    const handleShow1 = () => {
        setShow1(!show1)
    }
    return (
        <section className="bg-image">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                        <img src="assets/images/musicianBanner.png" className="img-fluid w-100" alt="" />
                        <img src="assets/images/logo.png" className="musiLogo" alt="" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                        <div className="d-table h-100">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        <h3 className="theme-color">Reset Password</h3>
                                        {/* <p>In order to retrieve your password, please enter registered email id.</p> */}
                                    </div>

                                    <div className="col-sm-12 col-md-12 mb-5">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type={show ? "text" : "password"} id="password-field" className="form-control p-0" placeholder="New Password" name="newpassword" value={values.newpassword} onChange={handleChange} onBlur={handleBlur} />
                                                <span toggle="#password-field" className="pass-view toggle-password input-img" onClick={handleShow}></span>
                                            </div>
                                            {errors.newpassword && touched.newpassword ? <p style={{ color: 'red' }}>{errors.newpassword}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 mb-5">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type={show1 ? "text" : "password"} id="password-field" className="form-control p-0" placeholder="Confirm Password" name="confirmpassword" value={values.confirmpassword} onChange={handleChange} onBlur={handleBlur} />
                                                <span toggle="#password-field" className="pass-view toggle-password input-img" onClick={handleShow1}></span>
                                            </div>
                                            {errors.confirmpassword && touched.confirmpassword ? <p style={{ color: 'red' }}>{errors.confirmpassword}</p> : ""}
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                        <button className="btn btn-save w-75" onClick={handleSubmit}>RESET</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </section>
    )
}

export default ResetPassword