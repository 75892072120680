import { configureStore } from "@reduxjs/toolkit";
import chatReducer from './slice/chat'
import chatEventReducer from './slice/chatsEvents'
import musicianInviteReducer from "./slice/musicianInvites";
import membershipReducer from "./slice/membershipList";
import taskDetailReducer from "./slice/taskDetail";
import chatTaskReducer from "./slice/chatTask";
import chatAdminReducer from "./slice/chatAdmin";
import fileReducer from "./slice/songFiles";
import chatBandReducer from "./slice/bandChat";
import absentReducer from "./slice/absentData"
import taskCountReducer from "./slice/tasknotification"
import eventCountReducer from "./slice/eventnotification"
import eventChatCountReducer from "./slice/eventchatnotification"
import taskChatCountReducer from "./slice/taskchatnotification"
import allNotificationReducer from "./slice/allnotificationcount"
import passengersReducer from './slice/passengers'
import allSongsReducer from './slice/allSongs'
import bandSongsReducer from './slice/bandSong'
import allSetlistReducer from "./slice/allSetlist";
import allBandSetlistReducer from './slice/bandSetlist';
import notificationsReducer from './slice/notification'
import eventDetailReducer from "./slice/eventDetail";
import bandChatCountReducer from "./slice/bandchatnotification";
import allChatReducer from "./slice/allChat";
import allSelfTaskReducer from "./slice/selfTask"
import bandChatReducer from './slice/bandsChat'
import eventChatReducer from './slice/eventChat'
import taskChatReducer from './slice/taskChat'
import eventChatlistReducer from "./slice/eventChatlist";
import taskChatlistReducer from "./slice/taskChatlist";
import musicianInfoReducer from "./slice/musicianInfo";
import countsReducer from "./slice/notificationCount";


export const store = configureStore({
    reducer: {
        chat: chatReducer,
        chatevents: chatEventReducer,
        inviteband: musicianInviteReducer,
        membership: membershipReducer,
        taskdetail: taskDetailReducer,
        chattask: chatTaskReducer,
        chatadmin: chatAdminReducer,
        file: fileReducer,
        chatband: chatBandReducer,
        absent: absentReducer,
        taskcount: taskCountReducer,
        eventcount: eventCountReducer,
        eventchatcount: eventChatCountReducer,
        taskchatcount: taskChatCountReducer,
        bandchatcount: bandChatCountReducer,
        allnotificationcount: allNotificationReducer,
        passenger: passengersReducer,
        allsongs: allSongsReducer,
        bandsongs: bandSongsReducer,
        allsetlist: allSetlistReducer,
        bandsetlist: allBandSetlistReducer,
        notification: notificationsReducer,
        eventdetail: eventDetailReducer,
        allchatcount: allChatReducer,
        allselftask: allSelfTaskReducer,
        bandchat: bandChatReducer,
        eventchat: eventChatReducer,
        taskchat: taskChatReducer,
        eventchatlist: eventChatlistReducer,
        taskchatlist: taskChatlistReducer,
        musicianinfo: musicianInfoReducer,
        allCounts: countsReducer,
    }
})