import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import OtpDeleteAccount from './otpdeleteAccount'
import { useNavigate } from 'react-router-dom'


const DeleteAccount = ({ show, handleShow, id, pageNo }) => {
    const dispatch = useDispatch()
    const navigate=useNavigate()
    const cookee = new Cookies();
    const token = cookee.get('tokenmusician')
    const email = cookee.get('emailmusician')
    const [otpShow,setOtpShow] =useState(false)


    const handleDelete = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/delete-musician`,
        })
            .then(res => {
                //console.log("res", res.data)
                if (res.status === 200) {
                    cookee.remove('tokenmusician')
                    cookee.remove('emailmusician')
                    cookee.remove('firstnamemusician')
                    cookee.remove('lastnamemusician')
                    cookee.remove('profilepicmusician')
                    cookee.remove('phonenumbermusician')
                    navigate("/")
                    toast.success(res.data.message, {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    handleShow()

                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    const showOtp=()=>{
        setOtpShow(!otpShow)
    }

    const handleEmailOtp = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
            data:
            {
                "email": email,
            }
        })
            .then(res => {

                if (res.status === 200) {
                   showOtp()
                }

            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <div className="d-flex align-items-center lg-justify-content-end mx-5">
                                    <h6>Are you sure, you want to delete your account?</h6>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <div className=" text-center my-3 mt-5">
                                    <button className="btn add-new-cancel" onClick={handleShow}>cancel</button>
                                    <button className="btn add-new-del" onClick={handleEmailOtp} >delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
                
                {otpShow?<OtpDeleteAccount show={otpShow} handleShow={showOtp} handleDelete={handleDelete}/>:""}
        </>
    )
}

export default DeleteAccount