import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
    return (
        <section className="">
            <div className="container-fluid">
                <div className='text-center mt-5'>
                    <img src="assets/images/error.png" className="img-fluid" alt="" />
                    <h4 className='mt-4 mb-3'>Something bad happened</h4>
                    <p>The invitation link has expired. </p>
                    <p>Please request a new link from an administrator.</p>
                    <Link to="/">Homepage</Link>
                </div>
            </div>
        </section>
    )
}

export default Error