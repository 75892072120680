import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { signUpSchema } from "../Schemas/signUpSchema";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import S3FileUpload from "react-s3";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Cookies from "universal-cookie";
import countryList from "react-select-country-list";
import OtpEmailModal from "./otpModal";
import OtpPhoneModal from "./otpModalPhone";
import ButtonLoader from "../Loader/loader";
import { useTranslation } from "react-i18next";

window.Buffer = window.Buffer || require("buffer").Buffer;

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  dob: "",
  password: "",
  confirmPassword: "",
  street: "",
  housenumber: "",
  zipcode: "",
  town: "",
  country: "",
  phone: "",
  tandc: false,
  mobile: "",
  countryCode: "+49",
};

const Signup = (props) => {
  const cookie = new Cookies();
  const { i18n, t } = useTranslation();
  const { tab1, tab2, tab3, tab4, tab5, tab6, tab7, tab8, tab9, tab10 } =
    t("signup");
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [image, setImage] = useState("");
  const [country, setCountry] = useState("Germany");
  const [showEmailOtp, setShowEmailOtp] = useState(false);
  const [showPhoneOtp, setShowPhoneOtp] = useState(false);
  const devicetoken = cookie.get("devicetokenmusician");
  const [countryData, setCountryData] = useState([]);
  const [load, setLoad] = useState(false);
  const lng = cookie.get("googtrans");
  const band = location?.state?.band;
  const path = location?.state?.path;
  const bandId = location?.state?.bandId;

  useEffect(() => {
    if (bandId) {
      handleLinkGenerate();
    }
  }, []);

  const handleChangeLanguage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "de" : "en");
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    onSubmit: () => {
      // console.log("data", values)
      handleEmailOtp();
    },
  });

  const handleShowEmailOtp = () => {
    setShowEmailOtp(!showEmailOtp);
  };
  const showOtp = () => {
    handleShowEmailOtp();
  };
  const handleShowPhoneOtp = () => {
    setShowPhoneOtp(!showPhoneOtp);
  };
  const handleShow = () => {
    setShow(!show);
  };
  const handleShow1 = () => {
    setShow1(!show1);
  };

  const handleLinkGenerate = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        link: bandId,
      },
      url: `${process.env.REACT_APP_URL}/verify-referral-link`,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          // setBand(res.data.data.band_id)
        }
      })
      .catch((error) => {
        if (error) {
          navigate("/error");
        }
      });
  };

  const handleFile = (e) => {
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setImage(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };

  const handleSignUp = () => {
    setLoad(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_URL}/sign-up`,
      data: {
        first_name: values.firstName,
        last_name: values.lastName,
        profile_pic: image,
        email: values.email,
        phone_number: values.mobile,
        // "dob": values.dob,
        password: values.password,
        user_type: 1,
        is_active: 1,
        country_code: values.countryCode,
        language: "EN",
        // "street": values.street,
        // "house_number": values.housenumber,
        // "zip_code": values.zipcode,
        // "town": values.town,
        country: country,
        device_token: devicetoken ? devicetoken : "AS",
        device_type: "W",
        is_accepted_terms_and_conditions: values.tandc === true ? 1 : 0,
        band_id: band ? band : "",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoad(false);
          cookie.set("tokenmusician", res.data.data.auth_token);
          cookie.set("emailmusician", res.data.data.email);
          cookie.set("firstnamemusician", res.data.data.first_name);
          cookie.set("lastnamemusician", res.data.data.last_name);
          cookie.set("phonenumbermusician", res.data.data.phone_number);
          cookie.set("profilepicmusician", res.data.data.profile_pic);
          cookie.set("country_codemusician", res.data.data.country_code);
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/appointments");
        }
      })
      .catch((error) => {
        setLoad(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // if (error.response.data.message === "Email Already Registered") {
        //     toast.error("Email Already Registered !!", {
        //         position: "top-right",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     })
        // }
        // if (error.response.data.message === 'Phone Number Already Registered') {
        //     toast.error('Phone Number Already Registered', {
        //         position: "top-right",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     })
        // }
        // if (error.response.data.message === 'User Already Registered') {
        //     toast.error('User Already Registered', {
        //         position: "top-right",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     })
        // }
        // if (error.response.data.message ===
        //     "We are currently able to offer the App to German Users. We ask for your understading!.") {
        //     toast.error(
        //         "We are currently able to offer the App to German Users. We ask for your understanding!.", {
        //         theme: 'colored',
        //         position: "top-right",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     })
        // }
      });
  };
  const handleEmailOtp = () => {
    setLoad(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
      data: {
        email: values.email,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showOtp();
          setLoad(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // if (error.response.data.message === 'Invalid Phone Number! Retry.') {
        //     toast.error("Invalid Phone Number.", {
        //         position: "top-right",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     })
        // }
      });
  };

  return (
    <section className="">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
            <img
              src="assets/images/musicianBanner.png"
              className="img-fluid w-100"
              alt=""
            />
            <Link to="/">
              <img src="assets/images/logo.png" className="musiLogo" alt="" />
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
            <div className="d-flex justify-content-end mt-2">
              <img
                src={
                  i18n.language === "en"
                    ? "assets/images/englishMobile.png"
                    : "assets/images/german.png"
                }
                style={{ width: "50px", height: "40px" }}
                alt=""
                className="img-fluid"
                onClick={handleChangeLanguage}
              />
            </div>
            <div className="d-table h-100">
              <form className="form-style">
                <div className="row">
                  <div className="col-sm-12 col-md-12 text-center mb-2">
                    <h3 className="theme-color">{tab9}</h3>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control p-0"
                          placeholder={tab1}
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <img
                          src="assets/images/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.firstName && touched.firstName ? (
                        <p style={{ color: "red" }}>{errors.firstName}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control p-0"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={tab2}
                        />
                        <img
                          src="assets/images/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.lastName && touched.lastName ? (
                        <p style={{ color: "red" }}>{errors.lastName}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control p-0"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Email"
                        />
                        <img
                          src="assets/images/arroba.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.email && touched.email ? (
                        <p style={{ color: "red" }}>{errors.email}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      {/* <label className="">Phone Number</label> */}
                      <div className="input-container notranslate">
                        <PhoneInput
                          country="DE"
                          inputProps={{
                            name: "mobile",
                          }}
                          enableSearch
                          value={values.countryCode + values.mobile}
                          onChange={(phone, country, e, formattedValue) => {
                            setCountryData({ formattedValue, country });
                            if (phone.length > 0) {
                              const raw = phone.slice(
                                country?.dialCode?.length
                              );
                              setFieldValue("mobile", raw);
                              setFieldValue(
                                "countryCode",
                                formattedValue?.split(" ")[0]
                              );
                            } else {
                              setFieldValue("mobile", "");

                              setFieldValue("countryCode", "");
                            }
                          }}
                          onBlur={handleBlur}
                          placeholder="Contact number"
                          containerclassName="form-control signupClass"
                        />
                        <img
                          src="assets/images/phone-call.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.mobile && touched.mobile ? (
                        <p style={{ color: "red" }}>{errors.mobile}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div className="col-sm-12 col-md-12 mb-4">
                                        <div className="form-group">
                                            <label className="mb-1">{lng === "/auto/de" ? "Geburtsdatum" : "Enter Your D.O.B"}</label>

                                            <div className="input-container">
                                                <input type="date" className="form-control p-0" placeholder="Date of Birth" name="dob" onChange={handleChange} value={values.dob} onBlur={handleBlur} />
                                               
                                            </div>
                                            {errors.dob && touched.dob ? <p style={{ color: 'red' }}>{errors.dob}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type="text" className="form-control p-0" placeholder="Street" name="street" value={values.street} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                            {errors.street && touched.street ? <p style={{ color: 'red' }}>{errors.street}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type="text" className="form-control p-0" placeholder="House Number" name="housenumber" value={values.housenumber} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                            {errors.housenumber && touched.housenumber ? <p style={{ color: 'red' }}>{errors.housenumber}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type="text" className="form-control p-0" placeholder={lng === "/auto/de" ? "Postleitzahl" : "Zip Code"} name="zipcode" value={values.zipcode} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                            {errors.zipcode && touched.zipcode ? <p style={{ color: 'red' }}>{errors.zipcode}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type="text" className="form-control p-0" placeholder="Town" name="town" value={values.town} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                            {errors.town && touched.town ? <p style={{ color: 'red' }}>{errors.town}</p> : ""}
                                        </div>
                                    </div> */}
                  {/* <div className="col-sm-12 col-md-12 mb-4">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type="text" className="form-control p-0" placeholder="Country" name="country" value={country} onBlur={handleBlur} />
                                              
                                            </div>
                                            {errors.country && touched.country ? <p style={{ color: 'red' }}>{errors.country}</p> : ""}
                                        </div>
                                    </div> */}
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type={show ? "text" : "password"}
                          id="password-field"
                          className="form-control p-0"
                          placeholder={tab3}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          toggle="#password-field"
                          className="pass-view toggle-password input-img"
                          onClick={handleShow}
                        ></span>
                      </div>
                      {errors.password && touched.password ? (
                        <p style={{ color: "red" }}>{errors.password}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type={show1 ? "text" : "password"}
                          id="password-field"
                          className="form-control p-0"
                          placeholder={tab4}
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          toggle="#password-field"
                          className="pass-view toggle-password input-img"
                          onClick={handleShow1}
                        ></span>
                      </div>
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <p style={{ color: "red" }}>{errors.confirmPassword}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <label className="mb-3">{tab5}</label>

                      <div className="input-container">
                        <input
                          type="file"
                          className="form-control p-0"
                          onChange={handleFile}
                        />
                        <img
                          src="assets/images/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="check-group">
                      <input
                        type="checkbox"
                        className=""
                        name="tandc"
                        id="accept"
                        onChange={handleChange}
                      />{" "}
                      <label htmlFor="accept">
                        {tab6}{" "}
                        <a
                          href="/termAndConditions"
                          className="text-dark"
                          target="_blank"
                        >
                          {tab7}
                        </a>{" "}
                        <a
                          href="/privacy&policy"
                          className="text-dark"
                          target="_blank"
                        >
                          {tab8}
                        </a>
                      </label>
                    </div>
                    {errors.tandc && touched.tandc ? (
                      <p style={{ color: "red", marginTop: "10px" }}>
                        {errors.tandc}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 text-center mb-5">
                    {load ? (
                      <ButtonLoader view="btn btn-save w-75" />
                    ) : (
                      <button
                        className="btn btn-save w-75"
                        onClick={handleSubmit}
                      >
                        {tab9}
                      </button>
                    )}
                  </div>

                  <div className="col-sm-12 col-md-12 text-center mb-4">
                    {tab10}{" "}
                    {bandId ? (
                      <Link to={`${path}?url=${bandId}`} className="text-dark">
                        <b>Login!</b>
                      </Link>
                    ) : (
                      <Link to="/login" className="text-dark">
                        <b>Login!</b>
                      </Link>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showEmailOtp ? (
        <OtpEmailModal
          show={showEmailOtp}
          handleShow={handleShowEmailOtp}
          email={values.email}
          setShowPhoneOtp={setShowPhoneOtp}
          mobile={values.mobile}
          countryCode={values.countryCode}
          load={load}
          setLoad={setLoad}
        />
      ) : (
        ""
      )}
      {showPhoneOtp ? (
        <OtpPhoneModal
          show={showPhoneOtp}
          handleShow={handleShowPhoneOtp}
          mobile={values.mobile}
          countryCode={values.countryCode}
          handleSignUp={handleSignUp}
          load={load}
          setLoad={setLoad}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default Signup;
