import React, { useState, useEffect } from 'react'
import Login from './Components/Forms/login'
import { Routes, Route } from 'react-router-dom'
import Signup from './Components/Forms/signup'
import ScrollToTop from './scrollToTop'
import ForgotPassword from './Components/Forms/forgotPassword'
import OtpVerification from './Components/Forms/otpVerification'
import LoginPhone from './Components/Forms/loginPhone'
import MobileOtp from './Components/Forms/mobileOtp'
import ForgotOtpVerification from './Components/Forms/forgotOtpVerification'
import ResetPassword from './Components/Forms/resetPassword'
import MainLayout from './Landing page/Layouts/mainLayout'
import Carousel from './Landing page/Components/Midsection/carousel'
import Price from './Landing page/Components/Price/price'
import Gema from './Landing page/Components/Gema/gema'
import ContactUs from './Landing page/Components/ContactUs/contactUs'
import Main from './Layouts/main'
import Appointments from './Components/Event appointments/appointments'
import Setlist from './Components/Setlist/setlist'
import Songs from './Components/Songs/songs'
import Tasklist from './Components/Tasklist/tasklist'
import TaskListInprogress from './Components/Tasklist/taskListInprogress'
import TaskListCompleted from './Components/Tasklist/taskListCompleted'
import AddNewTask from './Components/Tasklist/addNewTask'
import Chat from './Components/Chat/chat'
import Profile from './Components/Profile/profile'
import GemaMusician from './Components/Gema/gema'
import AppointmentDetail from './Components/Event appointments/appointmentDetail'
import CalendarView from './Components/Calendar/calendar'
import LoginBandleader from './Components/Forms/Bandleader/loginBandleader'
import SignupBandleader from './Components/Forms/Bandleader/signupBandleader'
import { useLocation } from 'react-router-dom'
import ChangePassword from './Components/Forms/changePassword'
import AddNewSong from './Components/Songs/addNewSong'
import SetlistLivemode from './Components/Setlist/setlistLivemode'
import Singlepage from './Layouts/singlepage'
import TermAndCondition from './Components/termsAndCondition/termAndCondition'
import Cookies from 'universal-cookie'
import TaskOverview from './Components/Tasklist/taskDetail'
import AboutUs from './Components/Aboutus/aboutUs'
import ChatEvent from './Components/Chat/chatEvent'
import ChatTask from './Components/Chat/chatTask'
import SetlistPrint from './Components/Setlist/setlistPrint'
import LoginOtpVerification from './Components/Forms/loginOtp'
import './firebase'
// import { requestForToken } from './firebase'
import EventDetails from './Components/Event appointments/eventDetail'
import AllNotification from './Components/Notification/notification'
import PrivacyPolicy from './Components/termsAndCondition/privacyPolicy'
import { ToastContainer } from 'react-toastify';
import TaskChatList from './Components/BandChat/taskChatlist'
import EventChatList from './Components/BandChat/eventChats'
import ChatList from './Components/BandChat/chatList'
import Error from './Components/ErrorPage/error'
import TermConditionPage from './Landing page/Components/CMS/termsAndConditions'
import DataProtectionPage from './Landing page/Components/CMS/dataProtection'
import ImprintPage from './Landing page/Components/CMS/imprint'


const App = () => {
  const cookie = new Cookies();
  const location = useLocation();
  const { pathname } = location;
  const queryParams = new URLSearchParams(window.location.search)

  const token = queryParams.get("token")
  const bandId = queryParams.get("url")


  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<MainLayout />}>
          <Route index element={<Carousel />} />
        </Route>
        <Route path='/price' element={<MainLayout />}>
          <Route index element={<Price />} />
        </Route>
        <Route path='/gema' element={<MainLayout />}>
          <Route index element={<Gema />} />
        </Route>
        <Route path='/contactus' element={<MainLayout />}>
          <Route index element={<ContactUs />} />
        </Route>
        <Route path='/termAndConditions' element={<MainLayout />}>
          <Route index element={<TermConditionPage />} />
        </Route>
        <Route path='/privacy&policy' element={<MainLayout />}>
          <Route index element={<DataProtectionPage />} />
        </Route>
        <Route path='/about' element={<MainLayout />}>
          <Route index element={<ImprintPage />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/error" element={<Error />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp-verification" element={<OtpVerification />} />
        <Route path="/login-phone" element={<LoginPhone />} />
        <Route path="/mobile-otp-verification" element={<MobileOtp />} />
        <Route path='/otp-forgot-verify' element={<ForgotOtpVerification />} />
        <Route path="/verify-otp" element={<LoginOtpVerification />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/calendar" element={<CalendarView />} />
        <Route path="/login-bandleader" element={<LoginBandleader />} />
        <Route path="/signup-bandleader" element={<SignupBandleader />} />
        <Route path='/appointments' element={<Main />}>
          <Route index element={<Appointments />} />
          <Route path={`?token=${token}`} element={<Appointments />} />
        </Route>
        <Route path='/event-detail' element={<Main />}>
          <Route index element={<EventDetails />} />
        </Route>
        <Route path='/setlist' element={<Main />}>
          <Route index element={<Setlist />} />
        </Route>
        <Route path='/setlist-livemode' element={<Main />}>
          <Route index element={<SetlistLivemode />} />
        </Route>
        <Route path='/setlist-print' element={<Main />}>
          <Route index element={<SetlistPrint />} />
        </Route>
        <Route path='/songs' element={<Main />}>
          <Route index element={<Songs />} />
        </Route>
        <Route path='/add-new-song' element={<Main />}>
          <Route index element={<AddNewSong />} />
        </Route>
        <Route path='/all-notification' element={<Main />}>
          <Route index element={<AllNotification />} />
        </Route>
        <Route path='/tasklist' element={<Main />}>
          <Route index element={<Tasklist />} />
        </Route>
        <Route path='/task-overview' element={<Main />}>
          <Route index element={<TaskOverview />} />
        </Route>
        <Route path='/task-list-inprogress' element={<Main />}>
          <Route index element={<TaskListInprogress />} />
        </Route>
        <Route path='/task-list-completed' element={<Main />}>
          <Route index element={<TaskListCompleted />} />
        </Route>
        <Route path='/add-new-task' element={<Main />}>
          <Route index element={<AddNewTask />} />
        </Route>
        <Route path='/chat' element={<Main />}>
          <Route index element={<Chat />} />
        </Route>
        <Route path='/chat-event' element={<Main />}>
          <Route index element={<ChatEvent />} />
        </Route>
        <Route path='/chat-task' element={<Main />}>
          <Route index element={<ChatTask />} />
        </Route>
        <Route path="/chat-list" element={<Main />}>
          <Route index element={<ChatList />} />
        </Route>
        <Route path="/chatlistEvent" element={<Main />}>
          <Route index element={<EventChatList />} />
        </Route>
        <Route path="/chatlistTask" element={<Main />}>
          <Route index element={<TaskChatList />} />
        </Route>
        {/* <Route path="/chat-event" element={<Main />}>
          <Route index element={<ChatEvent />} />
        </Route>
        <Route path="/chat-task" element={<Main />}>
          <Route index element={<ChatTask />} />
        </Route> */}
        <Route path='/profile' element={<Main />}>
          <Route index element={<Profile />} />
        </Route>
        <Route path='/gema-musician' element={<Main />}>
          <Route index element={<GemaMusician />} />
        </Route>
        <Route path='/appointment-detail' element={<Main />}>
          <Route index element={<AppointmentDetail />} />
        </Route>
        <Route path='/term&condition' element={<Singlepage />}>
          <Route index element={<TermAndCondition />} />
        </Route>
        <Route path='/privacyPolicy' element={<Singlepage />}>
          <Route index element={<PrivacyPolicy />} />
        </Route>
        <Route path='/aboutUs' element={<Singlepage />}>
          <Route index element={<AboutUs />} />
        </Route>
      </Routes>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default App