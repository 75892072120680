import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllSetlist } from "../../app_redux/slice/allSetlist";
import { useSelector, useDispatch } from "react-redux";
import { getBandSetlist } from "../../app_redux/slice/bandSetlist";
import { useTranslation } from "react-i18next";
import DataLoader from "../Loader/dataLoader";

const Setlist = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const token = cookee.get("tokenmusician");
  const [showBand, setShowBand] = useState(false);
  const [setlistBand, setSetlistBand] = useState([]);
  const [setlistShow, setSetlistShow] = useState(true);
  const [setlistBandShow, setSetlistBandShow] = useState(false);
  const [allBandleader, setAllBandleader] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageNo1, setPageNo1] = useState(1);
  const { allSetlist, loading } = useSelector((state) => state.allsetlist);
  const { allBandSetlist } = useSelector((state) => state.bandsetlist);
  const [bandId, setBandid] = useState("");
  const lng = cookee.get("googtrans");
  const { tab1, tab2, tab3, tab4, tab5, tab8, tab9, tab10, tab11 } =
    t("setlist");

  useEffect(() => {
    // getSetlist()
    // getSetListBand()
    getAllBandleader();
    dispatch(getAllSetlist({ pageno: pageNo }));
  }, []);

  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getAllSetlist({ pageno: pageNo + 1 }));
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getAllSetlist({ pageno: pageNo - 1 }));
  };
  const handleNextBand = (e) => {
    e.preventDefault();
    setPageNo1(pageNo1 + 1);
    dispatch(getBandSetlist({ pageno: pageNo1 + 1, bandid: bandId }));
  };
  const handlePreviousBand = (e) => {
    e.preventDefault();
    setPageNo1(pageNo1 - 1);
    dispatch(getBandSetlist({ pageno: pageNo1 - 1, bandid: bandId }));
  };
  const handleSetlist = () => {
    setSetlistShow(true);
    setSetlistBandShow(false);
  };
  const handleSetlistBand = () => {
    setShowBand(true);
    setSetlistBandShow(true);
    setSetlistShow(false);
  };
  const handleLiveMode = (id) => {
    localStorage.setItem("setlistid", id);
    navigate("/setlist-livemode");
  };
  const handlePrintMode = (id) => {
    localStorage.setItem("setlistid", id);
    navigate("/setlist-print");
  };

  const getAllBandleader = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-band-membership-list`,
      data: {
        is_all: 0,
      },
    }).then((res) => {
      // console.log("res", res.data)
      setAllBandleader(res.data.data);
    });
  };
  const handleBandId = (id) => {
    setShowBand(true);
    setSetlistBandShow(true);
    setSetlistShow(false);
    dispatch(getBandSetlist({ pageno: pageNo, bandid: id }));
    setBandid(id);
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        {loading ? (
          <DataLoader />
        ) : (
          <div className="row align-items-center">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
              <h4 className="mb-0">Band</h4>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
              <ul
                className="nav nav-pills group-nav ms-auto bandUserTab chatBtnTab mt-0"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" onClick={handleSetlist}>
                  <button className="btn nav-link">{tab4}</button>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <button
                      className="btn nav-link dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Band
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {allBandleader?.map((res, i) => {
                        return (
                          <p
                            className="dropdown-item notranslate"
                            onClick={() => handleBandId(res?.band_id)}
                          >
                            {res?.band_name} {res?.last_name}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-sm-12">
              <div className="pt-3 px-1 bg-white">
                <div className="table-responsive">
                  <table className="table storetable setlistTabla">
                    <thead className="">
                      <tr>
                        <th scope="col">setlist</th>
                        <th scope="col">{tab3}</th>
                        <th scope="col">{tab1}</th>
                        <th scope="col">live mode</th>
                        <th scope="col" className="text-center">
                          {tab2}
                        </th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {setlistShow
                        ? allSetlist?.map((res, i) => {
                            return (
                              <tr>
                                <td className="notranslate">
                                  <h6>
                                    {res?.set_list_name
                                      ? res?.set_list_name
                                      : "Testing"}
                                  </h6>
                                </td>
                                <td>
                                  <p className="mb-0 notranslate">
                                    {res?.songs_count} songs
                                  </p>
                                </td>
                                {/* <td>5 songs</td> */}
                                <td>
                                  {res.total_length
                                    ?.toString()
                                    ?.substr(0, 2) === "00"
                                    ? res.total_length?.toString()?.substr(3, 6)
                                    : res.total_length}
                                </td>
                                <td className="theme-color">
                                  <b
                                    onClick={() => {
                                      handleLiveMode(res?.set_list_id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    live mode
                                  </b>
                                </td>
                                <td>
                                  <div className="d-flex gap-3 justify-content-center">
                                    <img
                                      src="assets/images/printing.png"
                                      width="25px"
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handlePrintMode(res?.set_list_id);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : ""}

                      {setlistBandShow
                        ? allBandSetlist?.map((res, i) => {
                            return (
                              <tr>
                                <td style={{ cursor: "none" }}>
                                  <h6>
                                    {res?.set_list_name
                                      ? res.set_list_name
                                      : "Testing"}
                                  </h6>
                                </td>
                                <td>
                                  <p className="mb-0">
                                    {res.songs_count} songs
                                  </p>
                                </td>
                                <td>
                                  {res?.total_length
                                    ? res.total_length?.toString()?.substr(0, 8)
                                    : "00:00"}
                                </td>
                                <td className="theme-color">
                                  <b
                                    onClick={() => {
                                      handleLiveMode(res?.set_list_id);
                                    }}
                                  >
                                    live mode
                                  </b>
                                </td>
                                <td
                                  onClick={() => {
                                    handlePrintMode(res?.set_list_id);
                                  }}
                                >
                                  <div className="d-flex gap-3 justify-content-center">
                                    <img
                                      src="assets/images/printing.png"
                                      width="25px"
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : ""}

                      {setlistBand?.length === 0 && allSetlist?.length === 0 ? (
                        <p className="mt-4">{tab5}</p>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {setlistShow ? (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={
                          pageNo === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handlePrevious}
                        >
                          Previous
                        </a>
                      </li>
                      <li class="page-item active">
                        <a className="page-link" href="#">
                          {pageNo} <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li
                        class={
                          allSetlist?.length <= 9
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a className="page-link" href="#" onClick={handleNext}>
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ) : (
              ""
            )}
            {setlistBandShow ? (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={
                          pageNo1 === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handlePreviousBand}
                        >
                          Previous
                        </a>
                      </li>
                      <li class="page-item active">
                        <a className="page-link" href="#">
                          {pageNo1} <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li
                        class={
                          allSetlist?.length <= 9
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handleNextBand}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </article>
  );
};

export default Setlist;
