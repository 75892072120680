import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookee = new Cookies();

export const getBandChatsBandleader = createAsyncThunk("bandchats", async () => {
    let token = cookee.get('bandleader_token');
    // console.log("RED", obj?.page)
    let bandchats = await axios({
        method: 'get',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/get-band-leader-messages`,
    })
        .then(res => res.data.data)
    return bandchats
})

const listBandChats = createSlice(({
    name: "all chats",
    initialState: {
        allBandChats: [],
        loading: false
    },
    extraReducers: {
        [getBandChatsBandleader.pending]: (state, action) => {
            state.loading = true;
        },
        [getBandChatsBandleader.fulfilled]: (state, action) => {
            state.allBandChats = action.payload
        },
        [getBandChatsBandleader.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default listBandChats.reducer;